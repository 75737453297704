import { Box, Text, Textarea } from '@chakra-ui/react';
import React from 'react';
import Proptypes from 'prop-types';
import theme from '../../../constants/Theme';

function CQTextArea(props) {
  return (
    <>
      <Box>
        <Text style={{ fontWeight: 'bold' }} height={18} mb="3">
          Message :
        </Text>
        <Textarea
          height={props.height}
          width={props.width}
          placeholder={props.placeholder}
          onChange={props.onChange}
        />
        {props.setAlert && (
          <Text mt={1} fontSize={'sm'} color={'red'}>
            is Required*
          </Text>
        )}
        {props.text && (
          <Text
            className="m-5"
            fontSize={'sm'}
            style={{
              marginTop: '17px',
              marginBottom: '17px',
              fontFamily: theme.fonts.body,
            }}
          >
            <b>{props.keyText}</b>
            {props.text}
          </Text>
        )}
      </Box>
    </>
  );
}

CQTextArea.proptype = {
  text: Proptypes.string,
  keyText: Proptypes.string,
  placeholder: Proptypes.string,
  height: Proptypes.number,
  width: Proptypes.number,
  textArea: Proptypes.object,
  setAlert: Proptypes.bool,
  onChange: Proptypes.func,
};

export default CQTextArea;
