/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Circle,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  FormCQSelect,
  FormTextInput,
  useCQMediaQuery,
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AppColors from '../../../constants/AppColors';
import {
  AddIcon,
  AddressIcon,
  HealthSystemIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SelectFormModal from './SelectFormModal';
import FormMultiSelectModal from '../../../components/form/FormMultiSelect';
import { AccountContext } from '../../../providers/AccountsProvider';
import { separateIdNames } from '../../../utils';
import SpecialtySelect from '../components/SpecialtySelect';
import accountsRepository from '../../../repositories/AccountsRepository';
import { STATES, headingValidationRegex, onlyAlphabetsRegex } from '../../../constants/constants';
import StateDropDown from '../../../components/StateDropDown';
import SearchDropDown from '../components/SearchDropDown';

const createHealthSystemSchema = yup.object({
  name: yup
    .string()
    .required('Name is a required field')
    .matches(headingValidationRegex, 'Name must be valid'),
  prefix: yup
    .string()
    .required('Prefix is a required field')
    .matches(onlyAlphabetsRegex, 'Prefix must be valid'),
  state: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required('State is a required field')
    .nullable(),
  account_contact: yup.array().of(
    yup.object().shape({
      first_name: yup
        .string()
        .required('First Name is a required field')
        .matches(onlyAlphabetsRegex, 'First Name must be valid'),
      last_name: yup
        .string()
        .required('Last Name is a required field')
        .matches(onlyAlphabetsRegex, 'Last Name must be valid'),
      email: yup
        .string()
        .email('Email must be a valid')
        .required('Email is a required field'),
    })
  ),
});

const GRID_SPACING = 5;

function createNewContact() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    is_primary: false,
  };
}

const AddHealthSystemAccountForm = ({ isOpen, onClose }) => {
  // context
  const accountsContext = useContext(AccountContext);

  const [isPhysicianGroup, setPhysicianGroup] = React.useState(false);
  const [selectedPrimaryAccountCheckBoxIndex, spacbiState] = useState([]);
  const [dataValue, setDataValue] = useState({});

  const blockScrollOnMount = useCQMediaQuery({
    sm: false,
  });

  const form = useForm({
    resolver: yupResolver(createHealthSystemSchema),
    defaultValues: {
      account_contact: [createNewContact()],
    },
    mode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = form;

  const contactField = useFieldArray({
    control,
    name: 'account_contact',
  });

  useEffect(() => {
    getValues('account_contact')?.forEach((dt, index) => {
      if (selectedPrimaryAccountCheckBoxIndex.includes(index)) {
        setValue(`account_contact.${index}.is_primary`, true);
      } else {
        setValue(`account_contact.${index}.is_primary`, false);
      }
    });
  }, [selectedPrimaryAccountCheckBoxIndex]);

  useEffect(() => {
    loadOptions();
    accountsContext.getHealthSystemNames();
    accountsContext.getUsersMailId();
  }, []);

  useEffect(() => {
    if (dataValue && Object.keys(dataValue).length) {
      if (
        accountsContext.specialtyOptions.findIndex(
          d => d.id === dataValue.id && d.label === dataValue.label
        ) === -1
      ) {
        accountsContext.setSpecialtyOptions(prev => [...prev, dataValue]);
      }
    }
  }, [dataValue]);

  /** --- DATA FETCHING --- */
  function loadOptions() {
    accountsContext.fetchEHROptions();
    accountsContext.fetchInsuranceOptions();
    accountsContext.fetchSpecialtyOptions();
  }

  /** --- DATA HANDLING --- */
  async function submitAddAccount(_formData) {
    const formData = { ..._formData, address: _formData.state.value };
    const insurance = separateIdNames(formData?.insurance, 'new_ins_name');
    const ehr = separateIdNames(formData?.ehr, 'new_ehr_name');
    const specialties = separateIdNames(formData?.specialties, 'new_spec_name');
    const physicians_group = isPhysicianGroup;

    const isNameAndPrefixNotExist = accountsContext.hsNamesAndPrefix?.find(
      hs => {
        if (hs?.name === formData.name) {
          setError('name', {
            type: 'manual',
            message: `${formData.name} already exists`,
          });
          return hs?.name;
        } else if (hs?.prefix === formData.prefix) {
          setError('prefix', {
            type: 'manual',
            message: `${formData.prefix} already exists`,
          });
          return hs?.prefix;
        }
      }
    );
    if (!isNameAndPrefixNotExist) {
      const response = await accountsContext.createHealthSystem({
        ...formData,
        physicians_group,
        insurance,
        ehr,
        specialties,
      });
      if (response) {
        onClose();
        reset({
          account_contact: [createNewContact()],
        });
        await accountsContext.getAccountsList.loadAPI();
      }
    }
  }

  /** --- DATA RENDERING --- */
  function renderAddIcon(_props) {
    return (
      <HStack mr={12} mb={3} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <AddIcon style={{ width: 18, height: 18 }} />
      </HStack>
    );
  }

  const handleCheckBox = (event, index) => {
    let checkedIndexes = [...selectedPrimaryAccountCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    spacbiState(checkedIndexes);
  };

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={GRID_SPACING}>
          <FormTextInput
            name={`account_contact.${index}.first_name`}
            control={control}
            style={{ height: '50px' }}
            placeholder={'First Name'}
            size="md"
          />

          <FormTextInput
            name={`account_contact.${index}.last_name`}
            control={control}
            style={{ height: '50px' }}
            placeholder={'Last Name'}
            size="md"
          />

          <FormTextInput
            name={`account_contact.${index}.email`}
            control={control}
            style={{ height: '50px' }}
            placeholder={'Email'}
            size="md"
          />
          {index === 0 && <br />}
          {index.toString() === '0' ? null : (
            <Text
              onClick={() => contactField.remove(index)}
              cursor={'pointer'}
              color={AppColors.secondary}
              fontSize="sm"
              pt={2}
            >
              Remove
            </Text>
          )}
        </SimpleGrid>

        <Checkbox
          mb={3}
          onChange={e => handleCheckBox(e, index)}
          value={index.toString()}
        >
          Primary Account
        </Checkbox>
      </React.Fragment>
    );
  }

  function renderContacts() {
    return (
      <SimpleGrid
        // columns={3}
        spacing={GRID_SPACING}
      >
        {contactField.fields.map(renderAddContactForm)}
      </SimpleGrid>
    );
  }
  const promiseOptions = async inputValue => {
    return accountsRepository.getSpecialtyOptions({ search: inputValue });
  };

  const renderSpecialtySelect = () => {
    return (
      <SearchDropDown
        dataValue={dataValue}
        setDataValue={setDataValue}
        promiseOptions={promiseOptions}
      />
    );
  };

  function renderSpecialtiesForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        addNewText={'Other Specialty Name'}
        subTitle={'Select Account Specialties'}
        isFetching={accountsContext.isFetchingSpecialtyOption}
        isCreating={accountsContext.isCreatingSpecialtyOption}
        onCreateNewOption={accountsContext.createSpecialtyOption}
        renderSpecialtySelect={renderSpecialtySelect}
        dataValue={dataValue}
      />
    );
  }

  function renderInsuranceFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        addNewText={'Add Other Insurance'}
        subTitle={'Select Insurance'}
        isFetching={accountsContext.isFetchingInsurance}
        isCreating={accountsContext.isCreatingInsurance}
        onCreateNewOption={accountsContext.createInsuranceOption}
      />
    );
  }

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }
  const onModalClose = () => {
    onClose();
    reset({
      name: '',
      prefix: '',
      account_contact: [createNewContact()],
    });
  };

  return (
    <React.Fragment>
      <Modal
        isCentered
        size={'4xl'}
        isOpen={isOpen}
        onClose={onModalClose}
        blockScrollOnMount={blockScrollOnMount ?? true}
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        {!isPhysicianGroup ? (
          <ModalContent mx={4} paddingY={7} px={5}>
            <ModalHeader
              fontFamily={FONT_FAMILY.baiJamurjee}
              textAlign={'center'}
            >
              Add Health System
            </ModalHeader>
            <Box
              style={{
                zIndex: '1',
              }}
              position={'absolute'}
              top={-10}
              right={-3}
            >
              <Circle>
                <ModalCloseButton
                  size={'sm'}
                  style={{ color: AppColors.white }}
                  backgroundColor={'#8894A6'}
                  p={3}
                  onClick={onModalClose}
                  borderRadius={50}
                />
              </Circle>
            </Box>
            <ModalBody pb={6}>
              <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 2 }}
                spacing={GRID_SPACING}
                mb={GRID_SPACING}
              >
                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Health System Name
                  </FormLabel>
                  <FormTextInput
                    name={'name'}
                    style={{ height: '50px' }}
                    control={control}
                    placeholder={
                      isPhysicianGroup
                        ? "Physician's Group Name"
                        : 'Health System Name'
                    }
                    leftIcon={<HealthSystemIcon />}
                    size="md"
                  />

                  <HStack mt={2} alignContent={'center'}>
                    <Text fontSize={'sm'}>Is this a Physician's Group?</Text>
                    <Checkbox
                      name="isPhysicianGroup"
                      onChange={e => {
                        setPhysicianGroup(e.target.checked);
                      }}
                      size={'md'}
                    />
                  </HStack>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Prefix
                  </FormLabel>
                  <FormTextInput
                    name="prefix"
                    style={{ height: '50px' }}
                    control={control}
                    placeholder={'Ex. MMG'}
                    size="md"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    State
                  </FormLabel>

                  {/* state: */}
                  <StateDropDown control={control} errors={errors} />
                </FormControl>
                {/* {!isPhysicianGroup && <br />} */}
                {isPhysicianGroup && (
                  <FormControl>
                    <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                      Specialties
                    </FormLabel>
                    <FormMultiSelectModal
                      control={control}
                      name={'specialties'}
                      placeholder={'Ex. Cardiology'}
                      options={accountsContext.specialtyOptions}
                      renderFormModal={renderSpecialtiesForModal}
                    />
                  </FormControl>
                )}

                {/* <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>Specialties</FormLabel>
                  <FormTextInput
                    name="specialties"
                    control={control}
                    id="health_system_specialties"
                    rightIcon={renderAddIcon()}
                    placeholder={'Ex. Cardiology'}
                    size="md"
                  />
                </FormControl> */}

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Insurance
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'insurance'}
                    placeholder={'None'}
                    options={accountsContext.insuranceOptions}
                    renderFormModal={renderInsuranceFormModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    EHR
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'ehr'}
                    placeholder={'None'}
                    options={accountsContext.ehrOptions}
                    renderFormModal={renderEHRFormModal}
                  />
                </FormControl>
              </SimpleGrid>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                  Account Contact
                </FormLabel>
                <Box>
                  <Box
                    maxHeight={190}
                    overflow="scroll"
                    overflowX={'hidden'}
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 4px lightGrey',
                        width: '4px',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'grey',
                        borderRadius: '4px',
                      },
                    }}
                  >
                    {renderContacts()}
                  </Box>
                  <Box>
                    {renderAddIcon({
                      onClick: () => {
                        contactField.append(createNewContact());
                      },
                    })}
                  </Box>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter justifyContent={'center'}>
              <Button
                mr={3}
                borderRadius={'full'}
                px={12}
                borderWidth={2}
                height={'50px'}
                borderColor={AppColors.secondary}
                onClick={onModalClose}
                variant={'outline'}
                outline={`2px`}
                _hover={{
                  transition: 'all .1s ease',
                  bgColor: AppColors.secondary,
                  color: AppColors.white,
                }}
              >
                <Text fontWeight={'bold'} fontSize={'sm'}>
                  Cancel
                </Text>
              </Button>
              <Button
                bgColor={AppColors.primary}
                borderRadius={'full'}
                px={12}
                height={'50px'}
                isLoading={accountsContext.isCreatingHealthSystem}
                spinnerPlacement={'end'}
                outline={`2px`}
                _hover={{
                  transition: 'all .1s ease',
                  bgColor: AppColors.white,
                  color: AppColors.primary,
                  outline: `2px solid ${AppColors.primary}`,
                }}
                loadingText={'Creating'}
                onClick={handleSubmit(submitAddAccount)}
                color={AppColors.white}
              >
                <Text fontSize={'sm'}>Add</Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent mx={4} px={8}>
            <ModalHeader
              fontFamily={FONT_FAMILY.baiJamurjee}
              textAlign={'center'}
            >
              Add Physician's Group
            </ModalHeader>
            <Box
              style={{
                zIndex: '1',
              }}
              position={'absolute'}
              top={-10}
              right={-3}
            >
              <Circle>
                <ModalCloseButton
                  size={'sm'}
                  style={{ color: AppColors.white }}
                  backgroundColor={'#8894A6'}
                  p={3}
                  onClick={onModalClose}
                  borderRadius={50}
                />
              </Circle>
            </Box>
            <ModalBody pb={6}>
              <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 2 }}
                spacing={GRID_SPACING}
                mb={GRID_SPACING}
              >
                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Physician's Group Name
                  </FormLabel>
                  <FormTextInput
                    name={'name'}
                    control={control}
                    placeholder={"Physician's Group Name"}
                    leftIcon={<HealthSystemIcon />}
                    size="md"
                  />

                  <HStack mt={2} alignContent={'center'}>
                    <Text fontSize={'sm'}>Is this a Physician's Group?</Text>
                    <Checkbox
                      name="isPhysicianGroup"
                      onChange={e => {
                        setPhysicianGroup(e.target.checked);
                      }}
                      size={'md'}
                    />
                  </HStack>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Prefix
                  </FormLabel>
                  <FormTextInput
                    name="prefix"
                    control={control}
                    placeholder={'Ex. MMG'}
                    size="md"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    State
                  </FormLabel>
                  {/* state: */}
                  <StateDropDown control={control} errors={errors} />
                  {/* <FormTextInput
                    name="address"
                    control={control}
                    placeholder={'State ( If Applicable )'}
                    leftIcon={<AddressIcon style={{ width: 16, height: 16 }} />}
                    size="md"
                  /> */}
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Specialties
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'specialties'}
                    placeholder={'Ex. Cardiology'}
                    options={accountsContext.specialtyOptions}
                    renderFormModal={renderSpecialtiesForModal}
                  />
                </FormControl>

                {/* <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>Specialties</FormLabel>
                  <FormTextInput
                    name="specialties"
                    control={control}
                    id="health_system_specialties"
                    rightIcon={renderAddIcon()}
                    placeholder={'Ex. Cardiology'}
                    size="md"
                  />
                </FormControl> */}

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Insurance
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'insurance'}
                    placeholder={'None'}
                    options={accountsContext.insuranceOptions}
                    renderFormModal={renderInsuranceFormModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    EHR
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'ehr'}
                    placeholder={'None'}
                    options={accountsContext.ehrOptions}
                    renderFormModal={renderEHRFormModal}
                  />
                </FormControl>
              </SimpleGrid>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                  Account Contact
                </FormLabel>
                <Box>
                  <Box
                    maxHeight={190}
                    overflow="scroll"
                    overflowX={'hidden'}
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 4px lightGrey',
                        width: '4px',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'grey',
                        borderRadius: '4px',
                      },
                    }}
                  >
                    {renderContacts()}
                  </Box>
                  <Box>
                    {renderAddIcon({
                      onClick: () => {
                        contactField.append(createNewContact());
                      },
                    })}
                  </Box>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter justifyContent={'center'}>
              <Button
                mr={3}
                borderRadius={'full'}
                px={12}
                height={'50px'}
                borderWidth={2}
                borderColor={AppColors.secondary}
                onClick={onModalClose}
                variant={'outline'}
              >
                <Text fontWeight={'normal'} fontSize={'sm'}>
                  Cancel
                </Text>
              </Button>
              <Button
                bgColor={AppColors.primary}
                borderRadius={'full'}
                px={12}
                fontSize={'16px'}
                isLoading={accountsContext.isCreatingHealthSystem}
                spinnerPlacement={'end'}
                height={'50px'}
                loadingText={'Creating'}
                onClick={handleSubmit(submitAddAccount)}
              >
                <Text color={'white'} fontSize={'sm'}>
                  Add
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default AddHealthSystemAccountForm;
