import AppColors from "../constants/AppColors"
import { FONT_FAMILY } from "../constants/Theme"
import { Text, Tooltip } from "@chakra-ui/react"

const Ellipses = ({ str, count }) => {
    if (str.length < count) {
        return <Text fontFamily={FONT_FAMILY.ptSans} fontSize='sm' color={AppColors.primary} fontWeight='normal'>
            {str}
        </Text>

    }
    return <>
        <Tooltip hasArrow
            label={str}
            bg={AppColors.primary}
        >
            <Text fontFamily={FONT_FAMILY.ptSans} fontSize='sm' color={AppColors.primary} fontWeight='normal'>
                {str.substring(0, count)}...
            </Text>
        </Tooltip>
    </>
}
export default Ellipses