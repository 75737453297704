import { HStack, Text } from "@chakra-ui/react";
import { DownIcon, SortIcon, UpIcon } from "../../constants/IconData";
import { FONT_FAMILY } from "../../constants/Theme";

const HeaderComponent = ({ header, accessor, sorting, order, containerProps, headerProps }) => {
    
    return (
        <HStack display={'flex'} alignItems='center' justifyContent={'center'} gap={0} onClick={() => {
            sorting(accessor)
        }} style={{ cursor: 'pointer' }} {...containerProps}>
            <Text
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: FONT_FAMILY.ptSans,
                    marginRight: 1
                }}
                {...headerProps}
            >
                {header}
            </Text>
            {accessor ? (order === accessor ? <UpIcon style={{
                width: 10,
                height: 10,
                marginLeft: 1
            }} /> : order === `-${accessor}` ? <DownIcon style={{
                width: 10,
                height: 10,
                marginLeft: 1

            }} /> : <SortIcon
                style={{
                    width: 15,
                    height: 15,
                    marginLeft: 1

                }}
            />) : null}
        </HStack>
    );
}
export { HeaderComponent }