/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Heading,
  HStack,
  Spacer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import AuditProvider, { AuditContext } from '../../providers/AuditProvider';
import { withProvider } from '../../hoc/withProvider';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/Routes';
import { FONT_FAMILY } from '../../constants/Theme';
import { SearchIcon } from '@chakra-ui/icons';
import { ToastLoading } from '../../components/Loader';
import AllAuditTable from './components/tables/AllAuditTable';
import NewUploadTable from './components/tables/NewUploadTable';
import ArchivedTable from './components/tables/ArchivedTable';
import RebuttalUploadTable from './components/tables/RebuttalUploadTable';
import { TextInput, useTabStorage } from '@laxmimanogna/code-quick-components';
import AuditedTable from './components/tables/AuditedTable';
import { CustomTab } from '../../components/CustomTab';

const AssignedAuditTabs = [
  {
    title: 'ALL',
    header: 'Audit Uploads',
    component: AllAuditTable,
  },
  {
    title: 'NEW UPLOADS',
    header: 'New Uploads',
    component: NewUploadTable,
  },
  {
    title: 'ARCHIVED',
    header: 'Archived',
    component: ArchivedTable,
  },
  {
    title: 'REBUTTALS',
    header: 'Rebuttals',
    component: RebuttalUploadTable,
  },
  {
    title: 'AUDITED',
    header: 'Audited',
    component: AuditedTable,
  },
];

const AuditUploadContainer = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const tabStorage = useTabStorage();

  const [selectedTab, setSelectedTab] = useState(tabStorage.getTab() || 0);
  const [onSearchText, setSearchText] = useState('');

  const auditContext = useContext(AuditContext);
  const { loadSpecialtyAuditors } = auditContext;

  useEffect(() => {tabStorage.setTab(selectedTab)}, [selectedTab]);

  useEffect(() => {
    auditContext.getAllUsers();

    loadSpecialtyAuditors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoaderToast(auditContext.isUpdating);
  }, [auditContext.isUpdating]);

  useEffect(() => {
    showLoaderToast(auditContext.isAssigningChartToSelf);
  }, [auditContext.isAssigningChartToSelf]);

  function showLoaderToast(isLoading) {
    if (isLoading) {
      toast({
        render: ToastLoading,
        duration: null,
        id: 'loading-toast',
        position: 'top-right',
      });
    } else {
      toast.close('loading-toast');
    }
  }

  const onSearchAction = () => {
    auditContext.handleApplyFilter({
      ...auditContext.filterParams,
      searchText: onSearchText,
    });


  };
  const onFilterChange = e => {
    if (e) {
      auditContext.getAuditUpload.setParams({ page: 1 });
      auditContext.getAuditArchived.setParams({ page: 1 });
      auditContext.getAuditNewUploads.setParams({ page: 1 });
      auditContext.getAuditRebuttals.setParams({ page: 1 });
      auditContext.getAudited.setParams({ page: 1 })
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  return (
    <CQContainer>
      <HStack mb={5}>
        <Heading as="h5" fontSize="24px">
          {AssignedAuditTabs[selectedTab].header}
        </Heading>
        <Spacer />
        <Button
          mr={1}
          color={AppColors.secondary}
          // style={{ paddingInline: '20px', outlineColor: AppColors.secondary }}
          _hover={{bg:AppColors.secondary,color:AppColors.white}}
          width={'203px'}
          height={'50px'}
          border={'1px solid'}
          borderColor={AppColors.secondary}
          borderRadius="3xl"
          bgColor={'transparent'}
          fontSize={'16px'}
          onClick={() => {
            navigate(ROUTES.OFFLINE_UPLOAD_CHART);
          }}
        >
          Offline Chart Upload
        </Button>
      </HStack>
      <Box bg="white" p={4} w="100%" style={{ borderRadius: '12px' }}>
        <Tabs
          fontFamily={FONT_FAMILY.baiJamurjee}
          onChange={setSelectedTab}
          index={selectedTab}
        >
          <TabList borderBottom={'1px solid'}>
            <HStack w={'100%'} justifyContent={'space-between'}>
              <HStack spacing={4} px={4}>
                {AssignedAuditTabs.map((aa, index) => {
                  return (
                    <CustomTab
                      key={`assigned-audit-tab-${index}`}
                      pb={4}
                      color={AppColors.secondary}
                      fontFamily={FONT_FAMILY.baiJamurjee}
                    >
                      {aa.title}
                    </CustomTab>
                  );
                })}
              </HStack>
              <Box>
                <TextInput
                height={'50px'}
                mb={'10px'}
                verticalAlign={'center'}
                  borderRadius={'md'}
                  value={onSearchText}
                  onChange={e => onFilterChange(e)}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      onSearchAction();
                    }
                  }}
                  // mb="2"
                  rightIconProps={{ zIndex: 0 }}
                  placeholder="Search"
                  rightIcon={<SearchIcon style={{marginTop:'15px'}} onClick={onSearchAction} />}
                />
              </Box>
            </HStack>
          </TabList>

          <TabPanels>
            {AssignedAuditTabs.map((aa, index) => {
              return (
                <TabPanel p={0} key={`assigned-audit-panel-${index}`}>
                  <aa.component />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </CQContainer>
  );
};

export default withProvider(AuditProvider, AuditUploadContainer);
