import { Text } from '@chakra-ui/react';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import React from 'react';
import UrgentConfirmationPopup from '../../../../components/modal/urgent_confiramtion_popup/UrgentConfirmationPopup';

function UrgentConfirmation({ data }) {
  const [isModalOpen, imoState] = useBoolean(false);
  return (
    <>
      <Text
        onClick={() => {
          imoState.on();
        }}
        color={'black'}
        p="5px"
      >
        {data?.urgent ? 'Remove as Urgent' : 'Mark as Urgent'}
      </Text>
      <UrgentConfirmationPopup
        isOpen={isModalOpen}
        onClose={imoState.off}
        isUrgent={!data?.urgent}
        rowId={data?.id}
        uploadId={data?.upload_id}
      />
    </>
  );
}

export default UrgentConfirmation;
