import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Flex,
  Text,
  Center,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';
import PropTypes from 'prop-types';
import theme from '../../../constants/Theme';
import AppColors from '../../../constants/AppColors';
import { AuditContext } from '../../../providers/AuditProvider';

function DeleteConfirmationPopUp(props) {
  const auditContext = useContext(AuditContext);
  const [isNotSmallScreen] = useMediaQuery('(min-width: 410px)');

  const deleteUploads = async () => {
    await auditContext.deleteUploadedFile(props.rowId);
    props.onClose();
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        size={'lg'}
        onClose={props.onClose}
        isCentered
      >
        <ModalOverlay
          bg={AppColors.modalOverlayColor}
          style={{ opacity: 0.8 }}
        />
        <ModalContent p={15} alignItems={'center'}>
          <ModalHeader
            fontSize={'xl'}
            color={AppColors.secondary}
          >
            <Center textAlign={'center'} mb={2} fontWeight="800" fontSize='xl' fontFamily={theme.fonts.heading} lineHeight='26px' letterSpacing={0}>
              Are you sure you want to delete
            </Center>
            <Center fontWeight="800" fontFamily={theme.fonts.heading} fontSize='xl' lineHeight='26px' letterSpacing={0}>
              this chart upload?
            </Center>
          </ModalHeader>
          <ModalBody my={2}>
            <Flex
              alignItems={'center'}
              direction={isNotSmallScreen ? 'row' : 'column'}
            >
              <Button
                variant="outline"
                borderRadius={'3xl'}
                fontWeight={'normal'}
                style={{border:`2px solid ${AppColors.secondary}`}}
                height={'50px'}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.secondary,
                  color: AppColors.white,
                }}
                paddingInline={'35px'}
                backgroundColor={AppColors.white}
                marginBottom={isNotSmallScreen ? '0px' : '10px'}
                onClick={props.onClose}
                fontSize={'sm'}
              >
                No, Keep Upload
              </Button>
              <Button
                variant="solid"
                ml={isNotSmallScreen ? 10 : 0}
                borderRadius={'3xl'}
                outline={`2px solid ${AppColors.red}`}
                height={'50px'}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.white,
                  color: AppColors.red,
                  outline: `2px solid ${AppColors.red}`,
                }}
                color={AppColors.white}
                paddingInline={'35px'}
                backgroundColor={AppColors.orange}
                onClick={async () => {
                  await deleteUploads();
                  await props.loadList();
                }}
                isLoading={auditContext.isDeleteUpload}
                loadingText="Deleting"
                spinnerPlacement="end"
                fontSize={'sm'}
                fontWeight={'normal'}
              >
                Yes, Delete
              </Button>
            </Flex>
          </ModalBody>
          <Text
            className="m-5"
            fontSize={'xs'}
            style={{
              marginTop: '17px',
              marginBottom: '17px',
              fontFamily: theme.fonts.body,
            }}
          >
            <Center fontSize={'xs'} color={AppColors.black}>
              Note: action cannot be undone
            </Center>
          </Text>
        </ModalContent>
      </Modal>
    </>
  );
}

DeleteConfirmationPopUp.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  submitButton: PropTypes.object,
  closebutton: PropTypes.object,
};

export default DeleteConfirmationPopUp;
