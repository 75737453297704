import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  HStack,
  Box,
  Flex,
  Button,
  Center,
  Divider,
  useBoolean,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './datePicker.css';
import { format, subDays } from 'date-fns';
import { FONT_FAMILY } from '../../../constants/Theme';

function DatePickerPopup(props) {
  const { currentDate, endDate, previousStartDate, previousEndDate } =
    props.dateRanges;

  const _currentDate = currentDate || new Date(currentDate);
  const _endDate = endDate || new Date();
  const _previousStartDate = previousStartDate || new Date();
  const _previousEndDate = previousEndDate || new Date();

  // currentDate.setMonth(currentDate.getMonth() - 1);
  const [isDateSelected, idsState] = useBoolean(false);
  const [fromDate, setFromDate] = useState([
    {
      startDate: _currentDate,
      endDate: _endDate,
      key: 'selection',
    },
  ]);

  const [toDate, setToDate] = useState([
    {
      startDate: _previousStartDate,
      endDate: _previousEndDate,
      key: 'selection',
    },
  ]);

  const averageDates = async (
    fromStartDate,
    fromEndDate,
    toStartDate,
    toEndDate
  ) => {
    props.onSubmit({
      start_date: fromStartDate,
      end_date: fromEndDate,
      previous_start_date: toStartDate,
      previous_end_date: toEndDate,
    });
  };

  const onToDateChange = item => {
    idsState.on();
    setToDate([item.selection]);
  };
  const onFromDateChange = item => {
    idsState.on();
    setFromDate([item.selection]);
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        size={'5xl'}
        onClose={props.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent backgroundColor={'transparent'}>
          <HStack>
            <Box
              borderRadius={'md'}
              backgroundColor={AppColors.white}
              marginRight={2}
              width={'100%'}
              height={'450px'}
            >
              <Flex justifyContent={'space-around'}>
                <DateRange
                  className="datepicker"
                  onChange={item => onFromDateChange(item)}
                  moveRangeOnFirstSelection={false}
                  ranges={fromDate}
                  maxDate={new Date()}
                  rangeColors={[AppColors.primary]}
                  showDateDisplay={false}
                  dateDisplayFormat={'MM/dd/yyyy'}
                  endDatePlaceholder={'MM/dd/yyyy'}
                  color={AppColors.primary}
                />
                <Center height="300px">
                  <Divider orientation="vertical" />
                </Center>
                <DateRange
                  className="datepicker1"
                  onChange={item => onToDateChange(item)}
                  moveRangeOnFirstSelection={false}
                  ranges={toDate}
                  rangeColors={[AppColors.secondary]}
                  showDateDisplay={false}
                  maxDate={new Date()}
                  dateDisplayFormat={'MM/dd/yyyy'}
                  endDatePlaceholder={'MM/dd/yyyy'}
                  color={AppColors.secondary}
                />
              </Flex>
            </Box>
            <Box
              borderRadius={'md'}
              backgroundColor={AppColors.white}
              width={'400px'}
              height={'450px'}
              p={5}
            >
              <Text mb={2} fontWeight={'semibold'}>
                Date range
              </Text>
              <HStack mb={10}>
                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans} fontWeight={'semibold'}>
                    From
                  </Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {/* {isDateSelected
                      ? format(fromDate[0].startDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'} */}

                    {format(fromDate[0].startDate, 'MM/dd/yyyy')}
                  </Text>
                </Box>

                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans} fontWeight={'semibold'}>
                    To
                  </Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {/* {isDateSelected
                      ? format(fromDate[0].endDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'} */}
                    {format(fromDate[0].endDate, 'MM/dd/yyyy')}
                  </Text>
                </Box>
              </HStack>
              <Text mb={2} fontWeight={'semibold'}>
                Compare with
              </Text>
              <HStack>
                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans} fontWeight={'semibold'}>
                    From
                  </Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {/* {isDateSelected
                      ? format(toDate[0].startDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'} */}
                    {format(toDate[0].startDate, 'MM/dd/yyyy')}
                  </Text>
                </Box>

                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans} fontWeight={'semibold'}>
                    To
                  </Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {/* {isDateSelected
                      ? format(toDate[0].endDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'} */}
                    {format(toDate[0].endDate, 'MM/dd/yyyy')}
                  </Text>
                </Box>
              </HStack>

              <HStack marginTop={'130px'} width={'100%'}>
                <Button
                  variant="outline"
                  backgroundColor={'#fff'}
                  width={'100%'}
                  onClick={props.onClose}
                  outline={`2px`}
                  _hover={{
                    transition: 'all .1s ease',
                    bgColor: AppColors.white,
                    color: AppColors.secondary,
                    outline: `2px solid ${AppColors.secondary}`,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  width={'100%'}
                  color={'#fff'}
                  backgroundColor={AppColors.primary}
                  onClick={() => {
                    averageDates(
                      format(fromDate[0].startDate, 'yyyy-MM-dd'),
                      format(fromDate[0].endDate, 'yyyy-MM-dd'),
                      format(toDate[0].startDate, 'yyyy-MM-dd'),
                      format(toDate[0].endDate, 'yyyy-MM-dd')
                    );
                  }}
                  isLoading={props.isLoading}
                  spinnerPlacement="end"
                  loadingText="Applying"
                  outline={`2px`}
                  _hover={{
                    transition: 'all .3s ease',
                    bgColor: AppColors.white,
                    color: AppColors.primary,
                    outline: `2px solid ${AppColors.primary}`,
                  }}
                >
                  Apply
                </Button>
              </HStack>
            </Box>
          </HStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DatePickerPopup;
