export function toTitleCase(str) {
  if (str) {
    if (str.charAt(0) === ' ') {
      return str.trim();
    }
    const transformedStr = str
      .split(' ')
      .map(w => w[0]?.toUpperCase() + w.substring(1)?.toLowerCase())
      .join(' ');

    return transformedStr;
  }
  return '';
}
export function toCheckTitleCase(str) {
  if (str) {
    if (str.charAt(0) === ' ') {
      return str.trim();
    }
    const transformedStr = str
      .split(' ')
      .map(w => w[0].toUpperCase() + w.substring(1))
      .join(' ');

    return transformedStr;
  }
  return '';
}

export function replaceRoute(route, params, delimiter = ':') {
  const keys = Object.keys(params);
  for (let key of keys) {
    route = route.replace(delimiter + key, params[key]);
  }
  return route;
}
