import { useBoolean } from '@chakra-ui/react';
import React from 'react';
import FileViewer from '../../../components/pdf_viewer/FileViewer';
import { EyeAvatarIcon } from '../../../constants/IconData';

const FileViewAction = ({ auditUpload }) => {
  const [isFileOpen, ifoState] = useBoolean(false);

  return (
    <React.Fragment>
      <EyeAvatarIcon
        style={{ cursor: 'pointer', width: '40px', height: '40px' }}
        onClick={ifoState.on}
        transition={'all .25s ease-in-out'}
      />
      <FileViewer
        isOpen={isFileOpen}
        currentFile={auditUpload.file_obj}
        onClose={ifoState.off}
      />
    </React.Fragment>
  );
};

export default FileViewAction;
