/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ButtonGroup,
  Circle,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { TextInput } from '@laxmimanogna/code-quick-components';
import { useContext, useEffect, useState } from 'react';
import AppColors from '../../../../constants/AppColors';
import {
  EditIcon,
  LinkIcon,
} from '../../../../constants/IconData';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import SelectFormModal from '../../forms/SelectFormModal';
import { AccountContext } from '../../../../providers/AccountsProvider';
import { useForm } from 'react-hook-form';
import FormMultiSelectModal from '../../../../components/form/FormMultiSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { sendMail } from '../../../../utils/send_mail.util';
import { EditAccountContact } from '../../components/EditAccountContactModal';
import { separateIdNames } from '../../../../utils';

import accountsRepository from '../../../../repositories/AccountsRepository';
import SpecialtySelect from '../../components/SpecialtySelect';

const ProfileSection = ({ profileData, hospitalId }) => {
  const [editingKey, setEditingKey] = useState(null);

  const [departmentData, setDepartmentData] = useState({});
  const [updatedDepartmentData, setUpdatedDepartmentData] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState({});

  const accountsContext = useContext(AccountContext);
  const { departmentDropdown } = accountsContext;
  const schema = yup.object({
    insurance: yup.array(),
    ehr: yup.array(),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      insurance: [],
      ehr: [],
    },
  });
  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    if (Object.keys(profileData).length) {
      // const data = departmentDropdown.find(d => d.name.toLowerCase() === profileData.name.toLowerCase())
      const ehr = profileData.ehr.length ? profileData.ehr.map(e => e.id) : [];
      const insurance = profileData.insurance.length
        ? profileData.insurance.map(e => e.id)
        : [];
      setDepartmentData({
        ...profileData,
        ehr,
        insurance,
        account_contact: profileData.account_contacts,
      });
      setUpdatedDepartmentData({
        ...profileData,
        ehr,
        insurance,
        account_contact: profileData.account_contacts,
      });
      reset({
        ehr,
        insurance,
      });
    }
  }, [profileData]);

  useEffect(() => {
    if (selectedDepartment && Object.keys(selectedDepartment).length) {
      setUpdatedDepartmentData(prev => ({
        ...prev,
        name: selectedDepartment.label,
        department_id: selectedDepartment.id ? selectedDepartment.id : null,
      }));
    }
  }, [selectedDepartment])

  const update = async (key, formData) => {
    const departmentBody = {
      name: departmentData.name,
      address: departmentData.address ? departmentData.address : '',
      account_contact: departmentData.account_contact,
      ehr: separateIdNames(departmentData.ehr, 'new_ehr_name', profileData.ehr),
      insurance: separateIdNames(
        departmentData.insurance,
        'new_ins_name',
        profileData.insurance
      ),
      hospital: hospitalId,
      [key]: updatedDepartmentData[key],
    };
    if (key === 'name' && updatedDepartmentData.department_id > 0) {
      departmentBody.department_id = updatedDepartmentData.department_id
      delete departmentBody.name
    } else {
      departmentBody.name = updatedDepartmentData.name
      delete departmentBody.department_id
    }

    if (key === 'ehr') {
      departmentBody[key] = separateIdNames(
        formData[key],
        'new_ehr_name',
        profileData.ehr
      );
    } else if (key === 'insurance') {
      departmentBody[key] = separateIdNames(
        formData[key],
        'new_ins_name',
        profileData.insurance
      );
    } else if (key === 'account_contact') {
      departmentBody[key] = formData[key];
    }
    await accountsContext.updateDepartment(departmentData.id, departmentBody);
  };
  //functions
  const onUpdateData = key => {
    update(key);
    setDepartmentData(prev => ({ ...prev, [key]: updatedDepartmentData[key] }));
    setEditingKey(null);
  };

  const onUndoUpdateData = key => {
    setUpdatedDepartmentData(prev => ({ ...prev, [key]: departmentData[key] }));
    setEditingKey(null);
  };

  const onMultiTextSubmit = (formData, key) => {
    update(key, formData);
    setDepartmentData(prev => ({ ...prev, [key]: formData[key] }));
    setEditingKey(null);
  };

  const onEdit = key => {
    if (editingKey === key) {
      setEditingKey(null);
    } else {
      setEditingKey(key);
    }
  };

  //render functions
  const renderLeftData = (key, form) => {
    return (
      <ButtonGroup justifyContent="end" size="sm" spacing={2} mt={2}>
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CheckIcon color={'black'} boxSize={3} />}
          onClick={() => {
            if (form) {
              handleSubmit(onMultiTextSubmit)(key);
              setEditingKey(null);
            } else {
              onUpdateData(key);
            }
          }}
        />
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CloseIcon boxSize={3} color={'black'} />}
          onClick={() => {
            onUndoUpdateData(key);
          }}
        />
      </ButtonGroup>
    );
  };

  const renderTextData = (key, inputType) => {
    return editingKey === key ? (
      <HStack>
        <TextInput
          mt={2}
          name={key}
          id={{ key }}
          type={inputType}
          value={updatedDepartmentData[key]}
          onChange={e => {
            setUpdatedDepartmentData(prev => ({
              ...prev,
              [key]: e.target.value,
            }));
          }}
        />
        {renderLeftData(key)}
      </HStack>
    ) : (
      <Text mt={2} color={AppColors.black} fontSize={'md'}>
        {departmentData[key]}
      </Text>
    );
  };


  const promiseOptions = async (inputValue) => {
    return accountsRepository.getSpecialtyOptions({ search: inputValue, hospital_id: hospitalId })
  }
  const renderSelectData = key => {
    return editingKey === key ? (
      <HStack>
        <Box width="xs" mt={2}>
          {/* <CreatableSelect
            name="name"
            id="name"
            control={control}
            onChange={newValue => {
              setSelectedDepartment(newValue);
              setUpdatedDepartmentData(prev => ({
                ...prev,
                [key]: newValue.label,
                department_id: newValue.id ? newValue.value : null,
              }));
            }}
            isSearchable
            placeholder="Department Name"
            options={departmentDropdown}
            value={selectedDepartment}
            styles={{
              noOptionsMessage: (provided, state) => ({
                ...provided,
                backgroundColor: 'white',
              }),
              menu: props => ({
                ...props,
                zIndex: 10,
              }),
            }}
            formatCreateLabel={formatCreateLabel}
            components={{
              IndicatorSeparator: null,
              Control: ({ children, ...props }) => {
                return (
                  <components.Control {...props}>
                    <span style={{ marginLeft: 10 }}>
                      <DepartmentIcon style={{ width: 15, height: 15 }} />
                    </span>
                    {children}
                  </components.Control>
                );
              },
            }}
            onCreateOption={handleCreate}
          /> */}
          <SpecialtySelect
            promiseOptions={promiseOptions}
            setDataValue={setSelectedDepartment}
            dataValue={selectedDepartment}
            options={departmentDropdown}
          />
        </Box>
        {renderLeftData('name')}
      </HStack>
    ) : (
      <Text mt={2} color={AppColors.black} fontSize={'md'}>
        {departmentData[key]}
      </Text>
    );
  };

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  function renderInsuranceForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Insurance'}
        addNewText={'Other Insurance Name'}
        subTitle={'Select Insurance'}
        isFetching={accountsContext.isFetchingInsurance}
        isCreating={accountsContext.isCreatingInsurance}
        onCreateNewOption={accountsContext.createInsuranceOption}
      />
    );
  }

  const renderMultiForm = key => {
    const title = {
      ehr: { option: 'ehrOptions', form: renderEHRFormModal },
      insurance: { option: 'insuranceOptions', form: renderInsuranceForModal },
    };
    const options = accountsContext[title[key]['option']];
    return editingKey === key ? (
      <HStack mt={2}>
        <Box width={'xs'}>
          <FormMultiSelectModal
            control={control}
            name={key}
            placeholder={'None'}
            options={options}
            renderFormModal={title[key]['form']}
          />
        </Box>
        {renderLeftData(key, true)}
      </HStack>
    ) : (
      <Text mt={2} color={AppColors.black} fontSize={'md'}>
        {departmentData[key]?.length
          ? departmentData[key].map((id, index) => {
            return `${options.find(d => d.value === id)?.label}${index < departmentData[key].length - 1 ? ', ' : ''
              } `;
          })
          : 'N/A'}
      </Text>
    );
  };

  const renderContactForm = () => {
    return departmentData['account_contact']?.length ? (
      departmentData['account_contact'].map(account => {
        return (
          <Box>
            <HStack>
              {account.is_primary ? (
                <Circle
                  borderColor={AppColors.gray}
                  borderWidth={1}
                  boxSize={5}
                >
                  <CheckIcon color={AppColors.primary} boxSize={3} />
                </Circle>
              ) : (
                <Circle boxSize={5} />
              )}
              <Text color={AppColors.black}>
                {account.first_name} {account.last_name} |
              </Text>
              <Text color={AppColors.primary}>{account.email}</Text>
              <LinkIcon
                onClick={() => {
                  sendMail(account.email);
                }}
                style={{ height: 15, width: 15, cursor: 'pointer' }}
              />
            </HStack>
          </Box>
        );
      })
    ) : (
      <Text>N/A</Text>
    );
  };

  return (
    <Box bg={'white'} borderRadius={'lg'} px={7} py={5}>
      <HStack justifyContent={'space-between'} mt={5}>
        <Box width={'100%'}>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Department Name
          </Text>
          {/* {renderTextData('name', 'text')} */}
          {renderSelectData('name')}
        </Box>
        {editingKey !== 'name' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('name')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box width={'100%'}>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Hospital State
          </Text>
          {renderTextData('address', 'text')}
        </Box>
        {/* department address should not editable */}
        {/* {editingKey !== 'address' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('address')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null} */}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Hospital Insurance
          </Text>
          {renderMultiForm('insurance')}
        </Box>
        {editingKey !== 'insurance' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('insurance')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Hospital EHR
          </Text>
          {renderMultiForm('ehr')}
        </Box>
        {editingKey !== 'ehr' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('ehr')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} alignItems="baseline" mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Account Contact
          </Text>
          {renderContactForm('account_contact')}
        </Box>
        <Circle
          size="40px"
          bg={AppColors.gray}
          onClick={() => onEdit('account_contact')}
          cursor={'pointer'}
        >
          <EditIcon />
        </Circle>
      </HStack>
      <EditAccountContact
        isOpen={editingKey === 'account_contact'}
        onClose={() => setEditingKey(null)}
        onContactChange={formData => {
          onMultiTextSubmit(formData, 'account_contact');
        //   update('account_contact', formData);
        }}
        accountData={departmentData ? departmentData['account_contact'] : []}
      />
    </Box>
  );
};
export default ProfileSection;
