/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  GridItem,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Center,
  Grid,
} from '@chakra-ui/react';
import {
  FormTextInput,
  CQSelect,
  FormCQSelect,
} from '@laxmimanogna/code-quick-components';
import { useBoolean } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import ROUTES from '../../../constants/Routes';
import { UserManagementContext } from '../../../providers/UserManagementProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import SpecialtiesModal from './SpecialtiesModal';
import { AddIcon } from '@chakra-ui/icons';
import { CREATE_MODE, EDIT_MODE, ROLES } from '../../../constants/constants';

const UserManagementForm = props => {
  const navigate = useNavigate();

  const { mode, userId } = props;

  const { teamId } = useParams();
  const userManagementContext = useContext(UserManagementContext);
  const { specialties, isLoading, getUser, teams } = userManagementContext;
  const { getAllSpecialties, getAllUsers } = userManagementContext;
  const [isOpen, ioState] = useBoolean(false);
  const [userObject, setUserObject] = useState({});

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    userManagementContext.getTeams();
    getAllSpecialties();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (userId) {
      //call get user by id and set the form values
      getUser(userId).then(response => {
        if (Object.keys(response).length) {
          if (ROLES.length) {
            ROLES.forEach(role => {
              if (role.value === response.role) {
                response.role = { value: role.value, label: role.label };
              }
            });
          }
          response.teams = response.teams.map(data => {
            const options = {
              label: data.name,
              value: data.id,
            };
            return options;
          });
        }
        setUserObject(response);
        reset(response);
      });
    }
  }, [userId]);

  const userCreateSchema = yup.object({
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    email: yup.string().email().required('Email is required'),
    specialties: yup.array().of(yup.object()).required().min(1),
    role: yup.object().shape({
      label: yup.string().required(),
      id: yup.string(),
    }),
    teams: yup.array(),
  });

  const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    specialties: [],
    role: {},
    teams: [],
  };

  useEffect(() => {
    if (teamId) {
      reset({
        first_name: '',
        last_name: '',
        email: '',
        specialties: [],
        role: {},
        teams: teams.filter(v => {
          return v.value === +teamId;
        }),
      });
      setUserObject({
        role: {},
        teams: teams.filter(v => {
          return v.value === +teamId;
        }),
      });
    }
  }, [teams.length]);

  const form = useForm({
    resolver: yupResolver(userCreateSchema),
    defaultValues,
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = form;
  const previousTeamData = { ...location.state };
  const previousData = {
    state: {
      ...previousTeamData,
    },
  };
  const setErrors = errorObject => {
    const errorKeys = Object.keys(errorObject);
    errorKeys.forEach(key => {
      setError(key, { type: 'custom', message: errorObject[key] });
    });
  };
  const handleSaveData = async formData => {
    //if create call create else call edit
    formData.role = formData.role.value;
    formData.teams = formData.teams.map(t => t.value);
    formData.specialties = {
      exist_ids: formData.specialties.map(t => t.id),
      new_spec_name: [],
    };
    let response = {};
    if (mode === CREATE_MODE) {
      response = await userManagementContext.createUser(formData);
    } else {
      response = await userManagementContext.updateUser(userId, formData);
    }
    if (response?.errors) {
      setErrors(response.errors);
    }
    // if (location.state.addNewMember) {
    //   navigate(ROUTES.ADD_TEAM, previousData);
    // } else {
    //   navigate(ROUTES.TEAM, previousData);
    // }

    navigate(ROUTES.TEAM);
  };

  const deleteUser = () => {
    userManagementContext.deleteUser(userId);
  };

  return (
    <>
      <CQContainer ml={0} mr={0}>
        <form onSubmit={handleSubmit(handleSaveData)}>
          <HStack m={4} spacing="12">
            <Box w="xs">
              <Text
                fontSize={14}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                mb={2}
              >
                First Name
              </Text>
              <GridItem h={5}>
                <FormTextInput
                  name="first_name"
                  height={'50px'}
                  control={control}
                  id="first_name"
                />
              </GridItem>
            </Box>
            <Box w="xs">
              <Text
                fontSize={14}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                mb={2}
              >
                Last Name
              </Text>
              <GridItem h={5}>
                <FormTextInput
                  name="last_name"
                  height={'50px'}
                  control={control}
                  id="last_name"
                />
              </GridItem>
            </Box>
          </HStack>

          <HStack m={4} spacing="12" mt={'50px'}>
            <Box w="xs">
              <Text
                fontSize={14}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                mb={2}
              >
                Email
              </Text>
              <GridItem h={4}>
                <FormTextInput
                  name="email"
                  height={'50px'}
                  control={control}
                  id="email"
                  size="md"
                />
              </GridItem>
            </Box>
            <Box w="xs" h="s">
              <GridItem h={10}>
                <Text
                  fontSize={14}
                  fontFamily={FONT_FAMILY.ptSans}
                  fontWeight={'bold'}
                  mb={2}
                >
                  Role
                </Text>
                <FormCQSelect
                  control={control}
                  options={ROLES}
                  styles={{
                    control: styles => ({
                      ...styles,
                      height: '50px',
                      borderRadius: '10px',
                      border: `1px solid rgba(0,0,0,0.1)`,
                    }),
                  }}
                  id="role"
                  name="role"
                  placeholder="Select"
                  variant="primary"
                  value={userObject.role}
                  isSearchable={false}
                  onChange={o => {
                    setUserObject({ ...userObject, role: o });
                    setValue('role', o);
                  }}
                />
                {errors['role'] && errors['role']['label'] && (
                  <Text color={'red'}>Role is required .</Text>
                )}
              </GridItem>
            </Box>
          </HStack>
          <HStack m={4} spacing="12" mt={'50px'}>
            <Box w="xs">
              <Text
                fontSize={14}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                mb={2}
              >
                Assign to Team(optional)
              </Text>
              <GridItem>
                <CQSelect
                  options={teams}
                  id="teams"
                  isMulti
                  isClearable={false}
                  styles={{
                    control: styles => ({
                      ...styles,
                      borderRadius: '10px',
                      border: `1px solid rgba(0,0,0,0.1)`,
                    }),
                  }}
                  placeholder="Select"
                  variant="primary"
                  isSearchable={false}
                  // styles={styles}
                  value={userObject.teams}
                  onChange={o => {
                    setUserObject({ ...userObject, teams: o });
                    setValue('teams', o);
                  }}
                />
              </GridItem>
            </Box>
            <Box w="sm">
              <Text
                fontSize={'sm'}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight={'bold'}
                mb={3}
              >
                Specialties
              </Text>
              {getValues('specialties').length === 0 && specialties?.length ? (
                <Text
                  fontSize={'sm'}
                  color={AppColors.secondary}
                  fontWeight="bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    ioState.on();
                  }}
                >
                  <AddIcon w={2} h={2} mr={1} />
                  Add Specialties
                </Text>
              ) : (
                <Text fontFamily={FONT_FAMILY.ptSans} fontSize={'sm'}>
                  {getValues('specialties')
                    .map(p => {
                      const obj = specialties.find(s => s.id === p.id);
                      return obj?.id && Object.keys(obj).length ? obj.name : '';
                    })
                    .toString()}{' '}
                  |
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: AppColors.secondary,
                      marginLeft: 3,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      ioState.on();
                    }}
                  >
                    Change
                  </span>
                </Text>
              )}
              {errors['specialties'] && errors['specialties'].message ? (
                <Text color={'red'}>Select atleast one specialties</Text>
              ) : null}
            </Box>
          </HStack>

          <Box mt={12} ml={5}>
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem colSpan={4}>
                <HStack>
                  <Button
                    mr={3}
                    variant="outline"
                    color={AppColors.secondary}
                    style={{
                      paddingInline: '35px',
                      border: `2px solid ${AppColors.secondary}`,
                    }}
                    height={'50px'}
                    outline={`2px`}
                    _hover={{
                      transition: 'all .3s ease',
                      bgColor: AppColors.secondary,
                      color: AppColors.white,
                    }}
                    fontSize="sm"
                    borderRadius="3xl"
                    size="sm"
                    onClick={() => {
                      if (userId) {
                        navigate(ROUTES.TEAM);
                      } else {
                        // navigate(ROUTES.ADD_TEAM, previousData);
                        navigate(-1);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="3xl"
                    color="white"
                    fontSize="sm"
                    bg={AppColors.primary}
                    style={{ paddingInline: '35px' }}
                    type="submit"
                    loadingText={mode === CREATE_MODE ? 'Creating' : 'Updating'}
                    spinnerPlacement="end"
                    height={'50px'}
                    outline={`2px`}
                    _hover={{
                      transition: 'all .3s ease',
                      bgColor: AppColors.white,
                      color: AppColors.primary,
                      outline: `2px solid ${AppColors.primary}`,
                    }}
                    isLoading={isLoading}
                  >
                    {mode === CREATE_MODE ? 'Create User' : 'Save Changes'}
                  </Button>
                </HStack>
              </GridItem>
              <GridItem colStart={8} colEnd={10} pt="2">
                <HStack spacing={1}>
                  {mode === EDIT_MODE && (
                    <div
                      style={{
                        color: AppColors.orange,
                        fontFamily: FONT_FAMILY.ptSans,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIsOpenModal(true);
                      }}
                    >
                      Delete User
                    </div>
                  )}
                </HStack>
              </GridItem>
            </Grid>
          </Box>
        </form>
        <SpecialtiesModal
          specialties={specialties}
          isOpen={isOpen}
          ioState={ioState}
          selected={getValues('specialties')}
          setSelected={data => {
            setValue('specialties', data);
            setError('specialties', {});
          }}
        />
      </CQContainer>
      <Modal
        isOpen={isOpenModal}
        size={'md'}
        onClose={setIsOpenModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={10} style={{ alignItems: 'center' }}>
          <ModalHeader>
            <Text
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="bold"
              fontSize={'xl'}
              color={AppColors.secondary}
            >
              Are you sure you want to delete this user?
            </Text>
          </ModalHeader>
          <ModalBody mb={2}>
            <HStack>
              <Button
                mr={1}
                variant="outline"
                color={'black'}
                height={'50px'}
                style={{
                  paddingInline: '25px',
                  outlineColor: AppColors.secondary,
                }}
                fontSize="sm"
                borderRadius="3xl"
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                No, Keep User
              </Button>
              <Button
                borderRadius="3xl"
                color="white"
                fontSize="sm"
                height={'50px'}
                bg={AppColors.red}
                style={{ paddingInline: '35px' }}
                onClick={() => {
                  deleteUser();
                }}
              >
                Yes, Delete
              </Button>
            </HStack>
          </ModalBody>
          <Center mt={3}>
            <Text fontSize="xs">Note: this action can not be undone</Text>
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
};
export default UserManagementForm;
