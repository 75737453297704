/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, HStack, Text } from '@chakra-ui/react';
import { CQSelect, TextInput } from '@laxmimanogna/code-quick-components';
import { useContext, useEffect, useState } from 'react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { useComponentVisible } from '../../../hooks';
import { ReportContext } from '../../../providers/ReportProvider';
import AccuracyErrorReport from './components/AccuracyErrorReport';
import ErrorParameterTable from './components/ErrorParameterTable';
import SelectMenu from './components/SelectMenu';
import WeekWiseChart from './components/WeekWiseChart';
import { CloseIcon } from '@chakra-ui/icons';

const InternalQualityContainer = ({
  page1Ref,
  selectedErrors,
  setSelectedErrors,
  isLoading,
  ilState,
  enableReportDownload,
}) => {
  const reportContext = useContext(ReportContext);
  const { errorOptions, healthSystems, hospitals, specialties } = reportContext;
  const [selectedHealthSystem, setSelectedHealthSystem] = useState({
    label: 'Select Health System',
    value: '',
  });
  const [selectedHospital, setSelectedHospital] = useState({
    label: 'Select Hospital',
    value: '',
  });
  const [selectedSpecialty, setSelectedSpecialty] = useState({
    label: 'Select Specialty',
    value: '',
  });

  const getData = async () => {
    reportContext.getWeekWiseErrorChart();
    reportContext.getWeekdaysAccuracyChart();
    reportContext.getWeekWiseCQAccuracyTable();
    reportContext.getErrorParameterDropdown();
    reportContext.getHealthSystems();
    reportContext.getHospitals();
    reportContext.getSpecialties();
  };

  useEffect(() => {
    getData();
  }, []);

  const commonSelectStyles = {
    control: props => ({
      ...props,
      borderRadius: 7,
      height:'50px',
      paddingRight: 10,
      paddingLeft: 10,
      fontSize: 'sm',
      color: AppColors.secondary,
      borderColor: AppColors.secondary,
    }),
    placeholder: props => ({
      ...props,
      color: AppColors.secondary,
      fontWeight: 'bold',
    }),
    dropdownIndicator: props => ({
      ...props,
      color: AppColors.secondary,
    }),
    singleValue: props => ({
      ...props,
      fontWeight: 'bold',
      color: AppColors.secondary,
    }),
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const selectedFilterIds = () => {
    const query = {};
    if (
      selectedHealthSystem.value &&
      !selectedHospital.value &&
      !selectedSpecialty.value
    ) {
      query.health_system_id = selectedHealthSystem.value;
    } else if (selectedHospital.value && !selectedSpecialty.value) {
      query.hospital_id = selectedHospital.value;
    } else if (
      !selectedHealthSystem.value &&
      !selectedHospital.value &&
      selectedSpecialty.value
    ) {
      query.specialty_id = selectedSpecialty.value;
    } else if (selectedSpecialty.value) {
      query.department_id = selectedSpecialty.value;
    }
    return query;
  };

  useEffect(() => {
    if (selectedErrors.length) {
      const query = selectedFilterIds();
      reportContext.getWeekWiseErrorTable({ parameters: selectedErrors,...query });
      if (
        selectedHealthSystem.value &&
        selectedHospital.value &&
        selectedSpecialty.value
      ) {
        enableReportDownload(true);
      }
    } else {
      reportContext.getWeekWiseErrorTable();
      enableReportDownload(false);
    }
  }, [selectedErrors]);

  const onDropdownClick = async () => {
    const query = selectedFilterIds();
    if (Object.keys(query).length) {
      if (selectedErrors.length) {
        enableReportDownload(true);
      }
      ilState.on();
      await reportContext.getWeekWiseErrorChart(query);
      await reportContext.getWeekdaysAccuracyChart(query);
      await reportContext.getWeekWiseCQAccuracyTable(query);
      await reportContext.getWeekWiseErrorTable({
        parameters: selectedErrors,
        ...query,
      });
      ilState.off();
    } else {
      enableReportDownload(false);
    }
  };

  useEffect(() => {
    onDropdownClick();
  }, [selectedHealthSystem, selectedHospital, selectedSpecialty]);

  const updateSpecalityOption = async hospitalId => {
    await reportContext.updateSpecialtiesOptions({ hospital_id: hospitalId });
  };

  useEffect(() => {
    if (selectedHospital && Object.keys(selectedHospital).length) {
      updateSpecalityOption(selectedHospital.value);
    }
  }, [selectedHospital]);

  const updatedHopital = async healthSystemId => {
    await reportContext.updateHospitalOptions({
      health_system_id: healthSystemId,
    });
  };

  useEffect(() => {
    if (selectedHealthSystem && Object.keys(selectedHealthSystem).length) {
      updatedHopital(selectedHealthSystem.value);
    }
  }, [selectedHealthSystem]);

  const handleSelect = (type, data) => {
    switch (type) {
      case 'healthSystem':
        setSelectedHealthSystem(data);
        setSelectedHospital({ label: 'Select Hospital', value: '' });
        setSelectedSpecialty({ label: 'Select Specialty', value: '' });
        break;
      case 'hospital':
        setSelectedHospital(data);
        setSelectedSpecialty({ label: 'Select Specialty', value: '' });
        break;
      case 'specialty':
        setSelectedSpecialty(data);
        break;
      default:
        return '';
    }
  };
  return (
    <Box mt={6}>
      <HStack justifyContent={'center'} spacing={3} ref={page1Ref[0].ref}>
        <CQSelect
          options={[
            { label: 'Select Health System', value: '' },
            ...healthSystems,
          ]}
          placeholder="Select Health System"
          variant="primary"
          isSearchable={false}
          styles={commonSelectStyles}
          value={selectedHealthSystem}
          onChange={data => handleSelect('healthSystem', data)}
        />
        <CQSelect
          options={
            selectedHealthSystem
              ? [{ label: 'Select Hospital', value: '' }, ...hospitals]
              : []
          }
          placeholder="Select Hospital"
          variant="primary"
          isSearchable={false}
          styles={commonSelectStyles}
          value={selectedHospital}
          onChange={data => handleSelect('hospital', data)}
        />

        <CQSelect
          options={
            selectedHealthSystem.value && !selectedHospital.value
              ? []
              : [{ label: 'Select Specialty', value: '' }, ...specialties]
          }
          placeholder="Select Specialty"
          variant="primary"
          isSearchable={false}
          styles={commonSelectStyles}
          value={selectedSpecialty}
          onChange={data => handleSelect('specialty', data)}
        />
      </HStack>
      <Box ref={page1Ref[1].ref}>
        <WeekWiseChart isLoading={isLoading} />
        <AccuracyErrorReport isLoading={isLoading} />
      </Box>
      <Box ref={page1Ref[2].ref}>
        <Box m={10} mt={0}>
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontWeight="bold"
            fontSize={'24px'}
            mb={5}
          >
            Error Parameter
          </Text>
          <HStack>
            <Box ref={ref} position="relative">
              <Box w="xs">
                <TextInput
                  height={'50px'}
                  onClick={() => {
                    setIsComponentVisible(prev => !prev);
                  }}
                  fontSize="sm"
                  value={'Select Options'}
                  rightIcon={
                    <ChevronDownIcon style={{marginTop:'10px'}}
                      onClick={() => {
                        setIsComponentVisible(prev => !prev);
                      }}
                    />
                  }
                />
              </Box>
              {!selectedErrors.length ? (
                <Text mt={3} color={'red'} fontSize={'16px'} fontFamily={FONT_FAMILY.ptSans}>
                  Select the error parameters from the dropdown.
                </Text>
              ) : null}
              <Box w="xs" position={'absolute'} zIndex={10}>
                {isComponentVisible && (
                  <SelectMenu
                    options={errorOptions}
                    selected={selectedErrors}
                    setSelected={data => setSelectedErrors(data)}
                  />
                )}
              </Box>
            </Box>

            {selectedErrors.length
              ? selectedErrors.map(error => {
                  const data = errorOptions.find(
                    option => option.value === error
                  );
                  return (
                    <Box bg={AppColors.bgColor} borderRadius="2xl" p={1}>
                      <HStack mx={2} my={1}>
                        <Text color={AppColors.black} fontSize="sm">
                          {data.label}
                        </Text>
                        <CloseIcon
                          style={{ cursor: 'pointer', height: 10, width: 10 }}
                          onClick={() => {
                            setSelectedErrors(prev =>
                              prev.filter(p => p !== error)
                            );
                          }}
                        />
                      </HStack>
                    </Box>
                  );
                })
              : null}
          </HStack>
          <ErrorParameterTable selectedErrors={selectedErrors} />
        </Box>
      </Box>
    </Box>
  );
};
export default InternalQualityContainer;
