import { API } from "../config/AppConfig";
import { FORGOT_PASSWORD, LOGOUT, MANAGER_LOGIN, USER_LOOKUP } from "../constants/Endpoints";
import { ACCESS_TOKEN, USER_DATA } from "../constants/PreferenceKeys";
// import API from "../services/ApiService";

class AuthRepository {

  async login(payload) {
    localStorage.removeItem(ACCESS_TOKEN);
    const response = await API.post(MANAGER_LOGIN, {
      body: payload
    });
    if (response.access_token) {
      localStorage.setItem(ACCESS_TOKEN, `Bearer ${response.access_token}`)
    }
    return response;
  }

  async userLookup() {
    const response = await API.get(USER_LOOKUP);
    localStorage.setItem(USER_DATA, JSON.stringify(response))
    return response;
  }

  async forgotPassword(payload) {
    const response = await API.post(FORGOT_PASSWORD, {
      body: payload
    });
    return response;
  }

  async resetPassword(payload) {
    const response = await API.put(FORGOT_PASSWORD, {
      body: payload
    });
    return response;
  }
  async logout() {
    const response = await API.post(LOGOUT);
    return response;
  }
}

const authRepository = new AuthRepository();
export default authRepository;