import { API } from '../config/AppConfig';
import {
  GET_SPECIALTIES,
  MANAGE_TEAMS,
  MEMBER_LIST,
  TEAMS,
  AUDIT_UPLOADS,
  GET_USER_API,
  DELETE_TEAMS_USER,
  TEAM_CHART_ACCURACY,
  TEAM_AVERAGE_AUDIT_HOURS,
  AVERAGE_AUDIT_HOURS,
  CHART_ACCURACY,
} from '../constants/Endpoints';
import { ALL, ARCHIVED, NEW_UPLOAD, REBUTTAL } from '../constants/Filter';

class TeamRepository {
  async getTeams(filterParams) {
    const response = await API.get(TEAMS, {
      queryParams: filterParams,
    });
    return response;
  }

  async createTeam(data) {
    const response = await API.post(TEAMS, { body: data });
    return response;
  }

  async updateTeam(data, teamId) {
    const response = await API.put(MANAGE_TEAMS, {
      body: data,
      pathParams: { teamId },
    });
    return response;
  }
  async deleteTeam(teamId) {
    const response = await API.delete(MANAGE_TEAMS, {
      pathParams: { teamId },
    });
    return response;
  }
  async getTeam(teamId) {
    const response = await API.get(MANAGE_TEAMS, {
      pathParams: { teamId },
    });
    return response;
  }
  async getSpecialties() {
    const response = await API.get(GET_SPECIALTIES);
    return response;
  }
  async getUserFromSpecialties(params) {
    const response = await API.get(MEMBER_LIST, {
      queryParams: params,
    });
    return response;
  }
  async getAuditUploads(teamId, filterParams) {
    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
      pathParams: { teamId },
    });
    return response;
  }

  async getAllAuditUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getNewUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      status: NEW_UPLOAD,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getArchivedUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      status: ARCHIVED,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getRebuttalUploads(filterParams = {}) {
    filterParams = {
      ...filterParams,
      status: REBUTTAL,
    };

    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getUser(userId) {
    const response = await API.get(GET_USER_API, {
      pathParams: { userId },
    });
    return response;
  }

  async deleteTeamsUser(userId) {
    const response = await API.delete(DELETE_TEAMS_USER, {
      pathParams: { userId },
    });
    return response;
  }

  async getTeamChartAccuracy(filterParams) {
    const response = await API.get(TEAM_CHART_ACCURACY, {
      queryParams: filterParams,
    });
    return response;
  }

  async getTeamAverageAuditHours(filterParams) {
    const response = await API.get(TEAM_AVERAGE_AUDIT_HOURS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getChartAccuracy(filterParams) {
    const response = await API.get(CHART_ACCURACY, {
      queryParams: filterParams,
    });
    return response;
  }

  async getAverageAuditHours(filterParams) {
    const response = await API.get(AVERAGE_AUDIT_HOURS, {
      queryParams: filterParams,
    });
    return response;
  }
}

const teamRepository = new TeamRepository();
export default teamRepository;
