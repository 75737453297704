import {
  Box,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { TextInput } from '@laxmimanogna/code-quick-components';
import React, { useState } from 'react';
import { CustomTab } from '../../../../components/CustomTab';
import AppColors from '../../../../constants/AppColors';
import { SearchIcon, UrgentIcon } from '../../../../constants/IconData';
import { FONT_FAMILY } from '../../../../constants/Theme';
import TeamAllUpload from '../table/TeamAllUpload';
import TeamArchivedUpload from '../table/TeamArchivedUpload';
import TeamNewUpload from '../table/TeamNewUpload';
import TeamRebuttalUpload from '../table/TeamRebuttalUpload';

const TeamAuditTabs = [
  {
    title: 'ALL',
    component: TeamAllUpload,
  },
  {
    title: 'NEW UPLOADS',
    component: TeamNewUpload,
  },
  {
    title: 'ARCHIVED',
    component: TeamArchivedUpload,
  },
  {
    title: 'REBUTTALS',
    component: TeamRebuttalUpload,
  },
];

const TeamsChartSection = props => {
  // const { onSearch, queryParam } = props;

  const [searchTerm, setSearchTerm] = useState('');

  function handleSearch(e) {
    if (e.keyCode === 13) {
      setSearchTerm(e.target.value);
    }
  }

  return (
    <React.Fragment>
      <Box bgColor={'#fff'} p={4} shadow={'lg'} borderRadius={'lg'}>
        <Tabs fontFamily={FONT_FAMILY.baiJamurjee}>
          <TabList>
            <HStack w={'100%'} justifyContent={'space-between'}>
              <HStack spacing={4} px={4}>
                {TeamAuditTabs.map((aa, index) => {
                  return (
                    <CustomTab
                      pb={4}
                      key={`team-audit-tab-${index}`}
                      _selected={{
                        fontWeight: 'bold',
                        borderBottomColor: AppColors.secondary,
                      }}
                      color={AppColors.secondary}
                      fontFamily={FONT_FAMILY.baiJamurjee}
                    >
                      {aa.title}
                      {aa.title === 'URGENT' ? (
                        <UrgentIcon
                          style={{ position: 'absolute', left: 80, top: 10 }}
                        />
                      ) : null}
                    </CustomTab>
                  );
                })}
              </HStack>
              <Box>
                <Box>
                  <TextInput
                  position={'relative'}
                  bottom={'7px'}
                  height={'50px'}
                    borderRadius={'md'}
                    rightIconProps={{ zIndex: 0 }}
                    placeholder="Search"
                    rightIcon={<SearchIcon style={{marginTop:"2px"}} />}
                    onKeyDown={handleSearch}
                  />
                </Box>
              </Box>
            </HStack>
          </TabList>

          <TabPanels>
            {TeamAuditTabs.map((aa, index) => {
              return (
                <TabPanel p={0} key={`team-audit-panel-${index}`}>
                  <aa.component {...props} searchTerm={searchTerm} />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </React.Fragment>
  );
};

export default React.memo(TeamsChartSection);
