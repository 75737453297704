/* eslint-disable react-hooks/exhaustive-deps */
import {
  HStack,
  ScaleFade,
  Skeleton,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { Heading } from '@laxmimanogna/code-quick-components';
import React, { useEffect, useState } from 'react';
import AppColors from '../../../constants/AppColors';
import { ProfileAvatarIcon, UrgentIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import accountsRepository from '../../../repositories/AccountsRepository';

const StatisticsSection = props => {
  const { sectionTitle, queryParam = {} } = props;
  const { rows = [] } = props;

  // booleans
  const [isFetching, ifState] = useBoolean(false);

  // state
  const [statistics, setStatistics] = useState(null);
  const [filterParams] = useState({
    start_date: '2022-09-01',
    end_date: '2022-09-29',
    previous_start_date: '2022-08-01',
    previous_end_date: '2022-08-29',
  });

  useEffect(() => {
    fetchStatistics();
  }, []);

  function getFilterParams() {
    return {
      ...filterParams,
      ...queryParam,
    };
  }

  async function fetchStatistics() {
    try {
      ifState.on();
      const _filterParams = getFilterParams();
      const statisticsResponse = await accountsRepository.getTeamStatistics(
        _filterParams
      );
      setStatistics(statisticsResponse[0]);
    } catch (e) {
      // TODO: log exception
    } finally {
      ifState.off();
    }
  }

  function renderRow(row, index) {
    return (
      <HStack key={sectionTitle + '-statistics-' + index}>
        <UrgentIcon style={{ width: '8px' }} />
        <span
          style={{
            fontWeight: '500',
            fontFamily: FONT_FAMILY.ptSans,
            marginLeft: '15px',
          }}
        >
          {row.label}
        </span>
        <Spacer />
        <span
          style={{
            fontWeight: 'bolder',
            fontFamily: FONT_FAMILY.ptSans,
            marginLeft: '15px',
          }}
        >
          {statistics[row.accessor]}
        </span>
      </HStack>
    );
  }

  function renderMainContent() {
    return (
      <React.Fragment>
        <ScaleFade initialScale={0.1} in={true}>
          <HStack mb="2">
            <ProfileAvatarIcon style={{ width: 80, height: 80 }} />
            <Heading
              fontSize="xl"
              textTransform={'capitalize'}
              color={AppColors.primary}
              fontFamily={FONT_FAMILY.baiJamurjee}
            >
              {statistics.name}
            </Heading>
          </HStack>
          <VStack
            mt="25px"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
          >
            <Text
              fontWeight={'semibold'}
              textTransform={'uppercase'}
              color={AppColors.secondary}
              fontFamily={FONT_FAMILY.baiJamurjee}
            >
              {sectionTitle}
            </Text>
            {rows.map(renderRow)}
          </VStack>
        </ScaleFade>
      </React.Fragment>
    );
  }

  // TODO: Design a proper loader
  function renderLoader() {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }

  function renderBroken() {
    return (
      <VStack justifyContent={'center'} h={'100%'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  }

  if (isFetching) {
    return renderLoader();
  } else if (!statistics) {
    return renderBroken();
  } else {
    return renderMainContent();
  }
};

export default React.memo(StatisticsSection);
