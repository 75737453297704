import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import TeamProvider, { TeamContext } from '../../providers/TeamProvider';
import TeamProfile from './components/TeamProfile';
import { Text, HStack, Spacer } from '@chakra-ui/react';
import { PrimaryButton } from '@laxmimanogna/code-quick-components';
import AppColors from '../../constants/AppColors';
import { BackArrowIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { useNavigate, useParams } from 'react-router-dom';
import { toTitleCase } from '../../utils/common.utils';

const TeamContainer = props => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const { currentTeam } = useContext(TeamContext);
  useEffect(() => {
    // getChartAccuracy({ team: teamId })
    // getAverageAuditHours({ team: teamId })
  }, []);

  return (
    <>
      <CQContainer py={30} px={30}>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">
              <Text
                fontFamily={FONT_FAMILY.baiJamurjee}
                fontWeight="medium"
                fontSize={'2xl'}
              >
                Team
              </Text>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">
              {toTitleCase(currentTeam.name)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack mb={4} mt={2}>
          <BackArrowIcon
            style={{ width: 45, height: 45, cursor: 'pointer' }}
            onClick={() => {
              navigate(ROUTES.TEAM);
            }}
          />
          <PrimaryButton
            fontSize={'lg'}
            fontWeight="bolder"
            size="md"
            borderRadius="3xl"
            px={10}
            variant="link"
            fontFamily={FONT_FAMILY.ptSans}
            color={AppColors.secondary}
            onClick={() => {
              navigate(ROUTES.TEAM);
            }}
          >
            Back to All Teams
          </PrimaryButton>
          <Spacer />
          <HStack>
            <PrimaryButton
              bg={AppColors.primary}
              fontSize={'16px'}
              fontWeight="normal"
              style={{ height: '50px' }}
              outline={`2px`}
              _hover={{
                transition: 'all .3s ease',
                bgColor: AppColors.white,
                color: AppColors.primary,
                outline: `2px solid ${AppColors.primary}`,
              }}
              size="md"
              borderRadius="3xl"
              px={10}
              onClick={() => {
                navigate(ROUTES.EDIT_TEAM.replace(':teamId', teamId));
              }}
            >
              Edit Team Details
            </PrimaryButton>
          </HStack>
        </HStack>
        <Box w="100%" h="150%" borderRadius="md" ml={0}>
          <TeamProfile teamId={teamId} />
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(TeamProvider, TeamContainer);
