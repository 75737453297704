import { EXCEEDED_MESSAGE, LARGER_MESSAGE, OVERLAPPED_MESSAGE, SMALLER_MESSAGE } from "../../../constants/constants";

const overlappedCheck = (splitAudits, index, page, fieldName, splitAuditsErrors, setSplitAuditErrors) => {
    let count = 0
    for (let i = 0; i < splitAudits.length; i++) {
        if (i !== index) {
            if (page >= splitAudits[i].page_number_from && page <= splitAudits[i].page_number_to) {
                splitAuditsErrors[index][fieldName] = true
                break;
            }
            count = count + 1
        } else {
            count = count + 1
        }
    }
    if (count === splitAudits.length) {
        splitAuditsErrors[index][fieldName] = false
    }
    setSplitAuditErrors([...splitAuditsErrors])
}
const maxLimitCheck = (index, totalPage, page, fieldName, splitAuditsErrors, setSplitAuditErrors) => {
    if (page && page > Number(totalPage)) {
        splitAuditsErrors[index][fieldName] = true
    } else {
        splitAuditsErrors[index][fieldName] = false
    }
    setSplitAuditErrors([...splitAuditsErrors])
}


const getIsOpen = (splitAuditError, fieldName) => {

    switch (fieldName) {
        case 'page_number_from':
            return (splitAuditError.fromMaxLimit || splitAuditError.fromOverlapped || splitAuditError.larger)
        case 'page_number_to':
            return (splitAuditError.toMaxLimit || splitAuditError.toOverlapped || splitAuditError.smaller)
        default:
            return false
    }

}

const getLabel = (splitAuditError, fieldName) => {
    switch (fieldName) {
        case 'page_number_from':
            if (splitAuditError.larger) {
                return LARGER_MESSAGE
            } else if (splitAuditError.fromMaxLimit) {
                return EXCEEDED_MESSAGE
            } else {
                return OVERLAPPED_MESSAGE
            }
        case 'page_number_to':
            if (splitAuditError.smaller) {
                return SMALLER_MESSAGE
            } else if (splitAuditError.toMaxLimit) {
                return EXCEEDED_MESSAGE
            } else {
                return OVERLAPPED_MESSAGE
            }
        default:
            return null
    }
}
export {
    overlappedCheck,
    maxLimitCheck,
    getIsOpen,
    getLabel
}