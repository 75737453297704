import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';

const MoodleModal = ({
  moodleFunction,
  onMethod,
  isModalOpen,
  closeModal,
  name,
  isMoodleLoading,
}) => {
  const isCreateMode = moodleFunction === 'CREATE';

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        size={'lg'}
        onClose={() => closeModal()}
        isCentered
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        <ModalContent p={10} style={{ alignItems: 'center' }}>
          <ModalHeader>
            <Text
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="medium"
              fontSize={'lg'}
              color={AppColors.black}
            >
              {isCreateMode ? 'Create' : 'Delete'} Moodle Account for
              <Text fontWeight={'bold'}>"{name}"</Text>
            </Text>
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-14}
            right={-10}
          >
            <Circle>
              <ModalCloseButton
                size={'md'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={closeModal}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalBody mb={2}>
            <HStack>
              <Button
                mr={1}
                color={'black'}
                borderColor={AppColors.secondary}
                bg={'white'}
                borderWidth={1}
                fontSize="sm"
                borderRadius="full"
                height={'50px'}
                onClick={closeModal}
                _hover={{
                  bg: AppColors.white,
                }}
                px={14}
              >
                Cancel
              </Button>
              <Button
                borderRadius="full"
                color="white"
                fontSize="sm"
                height={'50px'}
                isLoading={isMoodleLoading}
                bg={AppColors.secondary}
                px={14}
                _hover={{
                  bg: AppColors.darkGray,
                }}
                onClick={onMethod}
              >
                {isCreateMode ? 'Create' : 'Delete'}
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export { MoodleModal };
