import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spacer,
  Spinner,
  StackDivider,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TextInput } from '@laxmimanogna/code-quick-components';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CustomTab } from '../../../components/CustomTab';
import { DateFilter } from '../../../components/DateFilter';
import AppColors from '../../../constants/AppColors';
import {
  LinkIcon,
  ProfileAvatarIcon,
  TriangleIcon,
  TriangleRedIcon,
  UrgentIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { UserManagementContext } from '../../../providers/UserManagementProvider';
import { sendMail } from '../../../utils/send_mail.util';
import CQLineChart from './CQLineChart';
import AllMyAuditTable from './tables/AllMyAuditTable';
import MyArchivedTable from './tables/MyArchivedTable';
import MyNewUploadTable from './tables/MyNewUploadTable';
import MyRebuttalTable from './tables/MyRebuttalTable';
import { subDays } from 'date-fns';
import { format } from 'date-fns';

const UserProfile = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [onSearchText, setSearchText] = useState('');
  const { teamId, userId } = useParams();

  const userContext = useContext(UserManagementContext);
  const {
    currentTeam,
    userData,
    handleApplyFilter,
    filterParams,
    accuracyData,
    avgHours,
    isFetchingAccuracy,
    getChartAccuracy,
    isFetchingAuditHours,
    getAverageAuditHours,
  } = userContext;

  const AssignedAuditTabs = [
    {
      title: 'ALL',
      header: 'Audit Uploads',
      component: AllMyAuditTable,
    },
    {
      title: 'NEW UPLOADS',
      header: 'New Uploads',
      component: MyNewUploadTable,
    },
    {
      title: 'ARCHIVED',
      header: 'Archived',
      component: MyArchivedTable,
    },
    {
      title: 'REBUTTALS',
      header: 'Rebuttals',
      component: MyRebuttalTable,
    },
  ];

  // constants
  const current = new Date();

  const endDate = subDays(current, 30);

  const previousStartDate = subDays(endDate, 30);

  const previousEndDate = subDays(previousStartDate, 30);

  const dateFilters = {
    start_date: format(current, 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
    previous_start_date: format(previousStartDate, 'yyyy-MM-dd'),
    previous_end_date: format(previousEndDate, 'yyyy-MM-dd'),
  };

  const [auditFilterHours, setAuditFilterHours] = useState(dateFilters);

  const [filterparams, setFilterParams] = useState(dateFilters);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };
  const onFilterChange = e => {
    if (e) {
      userContext.getAuditUpload.setParams({ page: 1 });
      userContext.getAuditArchived.setParams({ page: 1 });
      userContext.getAuditNewUploads.setParams({ page: 1 });
      userContext.getAuditRebuttals.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    getChartAccuracy({ user: userId, ...filterparams });
  }, [filterparams]);

  useEffect(() => {
    getAverageAuditHours({ user: userId, ...auditFilterHours });
  }, [auditFilterHours]);

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem w="100%" background={AppColors.white} borderRadius="md" p="5">
          <HStack mb="2" gap={2}>
            <ProfileAvatarIcon style={{ width: 80, height: 80 }} />
            <Box>
              <Heading
                fontSize="xl"
                fontFamily={FONT_FAMILY.baiJamurjee}
                fontWeight={'bold'}
              >
                {userData.first_name} {userData.last_name}
              </Heading>
              <Heading
                fontSize="sm"
                fontWeight="500"
                fontFamily={FONT_FAMILY.baiJamurjee}
                color={AppColors.primary}
              >
                {userData.email}
              </Heading>
            </Box>
            <Box>
              <LinkIcon
                onClick={() => {
                  sendMail(userData.email);
                }}
                style={{ height: 15, width: 15, cursor: 'pointer' }}
              />
            </Box>
          </HStack>
          <VStack
            mt="25px"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={3}
            align="stretch"
          >
            <span
              style={{
                color: AppColors.secondary,
                fontWeight: '500',
                fontFamily: FONT_FAMILY.baiJamurjee,
              }}
            >
              TEAM STATISTICS
            </span>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <Text fontSize={'sm'}>Total Audits</Text>
              <Spacer />
              <Text fontSize={'sm'} fontWeight="bold">
                {currentTeam?.members?.length
                  ? currentTeam.members.find(m => m.id === userData.id)
                      ?.total_audits
                  : 0}
              </Text>
            </HStack>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <Text fontSize={'sm'}>In Progress</Text>
              <Spacer />
              <Text fontSize={'sm'} fontWeight="bold">
                {currentTeam?.members?.length
                  ? currentTeam.members.find(m => m.id === userData.id)
                      ?.active_audits
                  : 0}
              </Text>
            </HStack>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <Text fontSize={'sm'}>Completed</Text>
              <Spacer />
              <Text fontSize={'sm'} fontWeight="bold">
                {currentTeam?.members?.length
                  ? currentTeam.members.find(m => m.id === userData.id)
                      ?.completed
                  : 0}
              </Text>
            </HStack>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <Text fontSize={'sm'}>Open Rebuttals</Text>
              <Spacer />
              <Text fontSize={'sm'} fontWeight="bold">
                {currentTeam?.members?.length
                  ? currentTeam.members.find(m => m.id === userData.id)
                      ?.open_rebutals
                  : 0}
              </Text>
            </HStack>
          </VStack>
        </GridItem>
        <GridItem w="100%" background={AppColors.white} borderRadius="md" p="5">
          {' '}
          <HStack justifyContent={'space-between'}>
            <span
              style={{
                color: AppColors.primary,
                alignItems: 'start',
                fontSize: '16px',
              }}
            >
              Audit Accuracy
            </span>

            <Box justifyContent={'end'}>
              <DateFilter
                onFilterChange={setFilterParams}
                isLoading={isFetchingAccuracy}
                filterParams={filterparams}
              />
            </Box>
          </HStack>
          <VStack>
            {isFetchingAccuracy ? (
              <Spinner />
            ) : (
              <Box position="relative">
                <CircularProgress
                  value={accuracyData?.cq_score}
                  size="230px"
                  thickness="2px"
                  color={AppColors.primary}
                >
                  <Box position={'absolute'} top={16} left={63}>
                    <HStack mt={4} ml={7}>
                      {accuracyData?.cq_score_diff >= 0 ? (
                        <TriangleIcon />
                      ) : (
                        <TriangleRedIcon />
                      )}
                      <Text fontSize={'xs'}>
                        {accuracyData?.cq_score_diff >= 0 && '+'}
                        {accuracyData?.cq_score_diff}
                      </Text>
                    </HStack>
                  </Box>
                  <CircularProgressLabel>
                    <Heading
                      variant="h1"
                      fontFamily={FONT_FAMILY.ptSans}
                      fontWeight="md"
                    >
                      {accuracyData?.cq_score ?? 0}
                      <span
                        style={{ fontSize: '30px', color: AppColors.lightGrey }}
                      >
                        %
                      </span>
                    </Heading>
                  </CircularProgressLabel>
                  <CircularProgressLabel mt="4">
                    <span
                      style={{ fontSize: '15px', color: AppColors.lightGrey }}
                    >
                      Overall Accuracy
                    </span>
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
            )}
          </VStack>
          <VStack alignItems="start">
            <Text
              fontSize="36px"
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight="bolder"
            >
              {accuracyData?.grade}
            </Text>
            <span
              style={{
                fontFamily: FONT_FAMILY.ptSans,
                color: AppColors.black,
                alignItems: 'start',
                fontSize: '12px',
                marginTop: '0px',
              }}
            >
              RI Score
            </span>
          </VStack>
        </GridItem>
        <GridItem w="100%" background={AppColors.white} borderRadius="md" p="5">
          <HStack justifyContent={'space-between'}>
            <span
              style={{
                color: AppColors.primary,
                alignItems: 'start',
                fontSize: '16px',
              }}
            >
              Average Hours to Complete over Time
            </span>
            <Box justifyContent={'end'}>
              <DateFilter
                onFilterChange={setAuditFilterHours}
                isLoading={isFetchingAccuracy}
                filterParams={filterparams}
              />
            </Box>
          </HStack>
          {isFetchingAuditHours ? (
            <Spinner />
          ) : (
            <Box mt={4}>
              <CQLineChart chartData={avgHours?.average_hours} />
            </Box>
          )}
          <HStack mt={4} ml={7}>
            {avgHours?.previous_diff >= 0 ? (
              <TriangleIcon />
            ) : (
              <TriangleRedIcon />
            )}
            <Text fontSize={'xs'}>
              {avgHours?.previous_diff >= 0 && '+'} {avgHours?.previous_diff}
            </Text>
          </HStack>
          <Text fontSize={'4xl'} ml={7}>
            {avgHours?.current_avg}hrs
          </Text>
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} mt="12px">
        <GridItem colSpan="1" p="6"></GridItem>
        <GridItem
          colSpan="2"
          background={AppColors.white}
          borderRadius="md"
          p="5"
        >
          {userData.id && currentTeam.id ? (
            <Box bg="white" marginTop={5} borderRadius="md" padding={3}>
              <Tabs
                fontFamily={FONT_FAMILY.baiJamurjee}
                onChange={setSelectedTab}
                index={selectedTab}
              >
                <TabList>
                  <HStack w={'100%'} justifyContent={'space-between'}>
                    <HStack spacing={4} px={4}>
                      {AssignedAuditTabs.map((aa, index) => {
                        return (
                          <Box position={'relative'}>
                            <CustomTab
                              key={`assigned-audit-tab-${index}`}
                              _selected={{
                                fontWeight: 'bold',
                                borderBottomColor: AppColors.secondary,
                              }}
                              color={AppColors.secondary}
                              fontFamily={FONT_FAMILY.baiJamurjee}
                            >
                              {aa.title}
                              {aa.title === 'URGENT' ? (
                                <UrgentIcon
                                  style={{
                                    position: 'absolute',
                                    left: 80,
                                    top: 10,
                                  }}
                                />
                              ) : null}
                            </CustomTab>
                          </Box>
                        );
                      })}
                    </HStack>
                    <Box>
                      <Box>
                        <TextInput
                          borderRadius={'md'}
                          value={onSearchText}
                          onChange={e => onFilterChange(e)}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              onSearchAction();
                            }
                          }}
                          mb="2"
                          rightIconProps={{ zIndex: 0 }}
                          placeholder="Search"
                          rightIcon={<SearchIcon onClick={onSearchAction} />}
                        />
                      </Box>
                    </Box>
                  </HStack>
                </TabList>

                <TabPanels>
                  {AssignedAuditTabs.map((aa, index) => {
                    return (
                      <TabPanel p={0} key={`assigned-audit-panel-${index}`}>
                        <aa.component />
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            </Box>
          ) : null}
        </GridItem>
      </Grid>
    </>
  );
};
export default UserProfile;
