/* eslint-disable array-callback-return */
const separateIdNames = (data, key_name) => {
  const obj = {
    exist_ids: [],
    [key_name]: [],
  };
  if (data?.length) {
    data.map(d => {
      if (d && Number(d)) {
        obj.exist_ids.push(Number(d));
      } else if (d) {
        obj[key_name].push(d);
      } else {
        return;
      }
    });
  }

  return obj;
};
const separateSpecalityIdNames = (data, key_name, existing_spec = []) => {
  const obj = {
    add_spec_ids: [],
    [key_name]: [],
    remove_spec_ids: [],
  };
  if (data?.length) {
    data.map(d => {
      if (d && Number(d)) {
        obj.add_spec_ids.push(Number(d));
      } else if (d) {
        obj[key_name].push(d);
      } else {
        return;
      }
    });
  }

  obj.remove_spec_ids = existing_spec
    ?.map(item => item.id)
    ?.filter(x => !obj.add_spec_ids.map(y => y).includes(x));

  return obj;
};
export { separateIdNames, separateSpecalityIdNames };
