import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import AppColors from '../../../constants/AppColors';
import {
  CheckedIcon,
  CloseModalIcon,
  UncheckedIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { UserManagementContext } from '../../../providers/UserManagementProvider';
import './modal.css';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 4px lightGrey',
    width: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '4px',
  },
};

const SpecialtiesModal = ({
  isOpen,
  ioState,
  selected,
  setSelected,
  specialties,
}) => {
  const [selectedSpecialties, setSelectedSpecialties] = useState(selected);
  useEffect(() => {
    setSelectedSpecialties([...selected]);
  }, [selected]);

  const { isSpecialtyList } = useContext(UserManagementContext);

  return (
    <>
      <Modal
        size={'xl'}
        onClose={() => ioState.off()}
        isOpen={isOpen}
        isCentered={true}
      >
        <ModalOverlay
          bg={AppColors.modalOverlayColor}
          style={{ opacity: 0.8 }}
        />
        <ModalContent
          position={'fixed'}
          style={{ background: 'inherit', boxShadow: 'none', maxWidth: '50%' }}
        >
          <CloseModalIcon
            style={{
              cursor: 'pointer',
              height: '30px',
              width: '30px',
              alignSelf: 'end',
            }}
            onClick={() => {
              ioState.off();
            }}
          />
          <Box
            bg="white"
            p={4}
            pb={20}
            w="100%"
            h="150%"
            borderRadius="md"
            my={3}
          >
            <Center
              fontFamily={FONT_FAMILY.baiJamurjee}
              color={AppColors.secondary}
              fontSize={'2xl'}
              mb={5}
              mt={5}
              fontWeight="medium"
            >
              Specialties
            </Center>
            <SimpleGrid
              sx={scrollStyle}
              overflowY={'scroll'}
              className={'modalBody'}
              maxHeight={'400px'}
              columns={3}
              spacing={2}
              m={10}
            >
              {isSpecialtyList ? (
                <Spinner />
              ) : specialties.length ? (
                specialties.map((specialty, i) => {
                  return (
                    <HStack key={`${i}-specialties`}>
                      {selectedSpecialties.find(s => s.id === specialty.id) ? (
                        <CheckedIcon
                          style={{ cursor: 'pointer', width: 20, height: 20 }}
                          onClick={() => {
                            const temp = selectedSpecialties.filter(
                              selected => selected.id !== specialty.id
                            );
                            setSelected([...temp]);
                            setSelectedSpecialties([...temp]);
                          }}
                        />
                      ) : (
                        <UncheckedIcon
                          style={{
                            cursor: 'pointer',
                            width: 20,
                            height: 20,
                            paddingBottom: '5px',
                          }}
                          onClick={() => {
                            selected.push({
                              id: specialty.id,
                              name: specialty.name,
                            });
                            setSelected([...selected]);
                            setSelectedSpecialties(prev => [
                              ...prev,
                              { id: specialty.id, name: specialty.name },
                            ]);
                          }}
                        />
                      )}
                      <Text>
                        <Text fontSize={'sm'} textTransform={'capitalize'}>
                          {specialty.name !== 'ent' ? specialty.name : 'ENT'}
                        </Text>
                      </Text>
                    </HStack>
                  );
                })
              ) : null}
            </SimpleGrid>

            <Center mt={5}>
              <HStack position={'relative'} top={'20px'}>
                <Button
                  mr={1}
                  width={'158px'}
                  variant="outline"
                  color={AppColors.black}
                  height={'50px'}
                  _hover={{
                    bg: AppColors.secondary,
                    color: AppColors.white,
                  }}
                  style={{
                    border: `2px solid ${AppColors.secondary}`,
                  }}
                  fontSize="sm"
                  borderRadius="3xl"
                  onClick={() => {
                    ioState.off();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius="3xl"
                  color="white"
                  fontSize="sm"
                  height={'50px'}
                  width={'158px'}
                  bg={AppColors.secondary}
                  _hover={{
                    bg: AppColors.white,
                    color: AppColors.secondary,
                    border: `2px solid ${AppColors.secondary}`,
                  }}
                  style={{ paddingInline: '35px' }}
                  onClick={() => {
                    ioState.off();
                  }}
                >
                  Save Specialties
                </Button>
              </HStack>
            </Center>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
export default SpecialtiesModal;
