import * as yup from 'yup';
import { onlyAlphabetsRegex } from '../../constants/constants';

export const createDepartmentSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(/^([^0-9]*)$/, 'Numeric characters are not allowed'),
  department_id: yup.number().nullable(),
  ehr: yup.array().min(1),
  insurance: yup.array().min(1),
  address: yup.string().nullable(),
  account_contact: yup
    .array()
    .of(
      yup.object({
        is_primary: yup.bool().required('Primary Account is required'),
        first_name: yup
          .string()
          .required('First Name is required')
          .matches(onlyAlphabetsRegex, 'First Name must be valid')
          .when('is_primary', {
            is: true,
            then: yup.string().required('First Name is required'),
          }),
        last_name: yup
          .string()
          .required('Last Name is required')
          .matches(onlyAlphabetsRegex, 'Last Name must be valid')
          .when('is_primary', {
            is: true,
            then: yup.string().required('Last Name is required'),
          }),
        email: yup.string().when('is_primary', {
          is: true,
          then: yup.string().email("Enter valid email").required('Email is required'),
        }),
      })
    )
    .min(1),
});
