import axios from "axios";
import { API } from "../config/AppConfig";
import { UPLOAD_CHART, SUBMIT_UPLOADED_CHART, DELETE_CHART, GET_CLIENT_USER } from "../constants/Endpoints";
import { ACCESS_TOKEN } from "../constants/PreferenceKeys";

class ChartUploadRepository {

  async uploadCharts(formData, onUploadProgress) {
    const token = localStorage.getItem(ACCESS_TOKEN)
    const response = await axios.post(
      UPLOAD_CHART,
      formData,
      {
        headers: {
          Authorization: token,
        },
        onUploadProgress,
      },
    );
    // const response = await API.post(UPLOAD_CHART, {
    //   body: payload
    // });
    return response;
  }

  async submitUploadedCharts(formData) {
    const response = await API.post(SUBMIT_UPLOADED_CHART, { body: formData })
    return response;
  }
  async deleteChart(fileId) {
    const response = await API.delete(DELETE_CHART, {
      pathParams: { fileId }
    });
    return response;
  }
  async getClientUsers() {
    const response = await API.get(GET_CLIENT_USER);
    return response;
  }
}

const chartUploadRepository = new ChartUploadRepository();
export default chartUploadRepository;