/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Center,
  Circle,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  BellIcon,
  CodeQuickIcon,
  HelpIcon,
  LogoutIcon,
  UserIcon,
} from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import ROUTES from '../../constants/Routes';
import React, { useContext, useEffect } from 'react';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import { replaceRoute, toTitleCase } from '../../utils/common.utils';
import { withProvider } from '../../hoc/withProvider';
import NotificationProvider, {
  NotificationsContext,
} from '../../providers/NotificationProvider';
import { formatDistanceStrict } from 'date-fns';
import { FONT_FAMILY } from '../../constants/Theme';
import { useInterval } from '../../hooks/useInterval';

function CQTopbar() {
  const navigate = useNavigate();

  const notificationContext = useContext(NotificationsContext);
  const {
    notifications,
    unReadNotification,
    setUnReadNotification,
  } = notificationContext;

  const { isOpen, onToggle } = useDisclosure();
  const authContext = useContext(AuthContext);
  const initialNotificationData = React.useRef(null);
  const [notificationData, setNotificationData] = React.useState([]);
  const [isHaveNewNotification, setIsHaveNewNotification] = React.useState([]);
  const [newNotifications, setNewNotification] = React.useState(notifications);

  const myAccountRoute = () => {
    navigate(ROUTES.ACCOUNT);
  };

  useEffect(() => {
    notificationContext.getNotifications(1);
    initialNotificationData.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewNotification(notifications);
  }, [notifications]);

  const handleNotificationStatus = async n => {
    const auditSheetRoute = replaceRoute(ROUTES.VIEW_AUDIT_SHEET, {
      uploadId: n.reference_chart,
    });
    if (!n.read) await notificationContext.markRead(n.notification_user_id);
    if (n.reference_chart) navigate(auditSheetRoute);
  };

  const loadNotification = async () => {
    const response = await notificationContext.loadNewNotification();
    const sortedNotifications = response.results?.sort(
      (rn, pn) => Number(rn.read) - Number(pn.read)
    );
    if (initialNotificationData.current) {
      setNotificationData({ ...response, results: sortedNotifications });
      initialNotificationData.current = null;
    } else {
      setIsHaveNewNotification({ ...response, results: sortedNotifications });
    }
  };

  useEffect(() => {
    const data = notificationData?.results?.filter(
      isNew =>
        !isHaveNewNotification?.results?.some(
          nd => nd.notification_id === isNew.notification_id
        )
    );

    if (data?.length) {
      setNewNotification(isHaveNewNotification?.results);
      setUnReadNotification(isHaveNewNotification?.unread_count);
      // setNotificationCount(isHaveNewNotification.count);
    }
  }, [isHaveNewNotification]);

  useInterval(loadNotification, 5000);

  return (
    <Box w={'100%'}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        shadow={'md'}
        position={'relative'}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex paddingLeft={5} height={'80px'} flex={{ base: 1 }} alignItems={'center'}>
          <HStack
            cursor={'pointer'}
            onClick={() => {
              navigate(ROUTES.ASSIGNED_AUDITS);
            }}
          >
           <Box >
           <CodeQuickIcon style={{  height: '3rem', cursor: 'pointer' }} />
           </Box>
            <Text
              display={{ base: 'none', md: 'block' }}
              color={AppColors.secondary}
              fontSize={'2xl'}
              fontWeight={'bold'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              style={{ marginLeft: '34px', marginRight: '28px' }}
              letterSpacing={'0.3px'}
            >
              Manager Portal
            </Text>
          </HStack>

          <Flex display={{ base: 'none', md: 'flex' }} mr={5}>
            <DesktopNav />
          </Flex>
        </Flex>

        <HStack alignItems={'center'} spacing={5} mr={2}>
          <Menu autoSelect={false}>
            <MenuButton position={'relative'}>
              <BellIcon
                style={{
                  width: 40,
                  height: 40,
                  cursor: 'pointer',
                  display: 'inline-block',
                }}
              />
              {newNotifications?.length !== 0 && unReadNotification ? (
                <Circle
                  position={'absolute'}
                  top={-1}
                  p={3}
                  right={-3}
                  color={AppColors.white}
                  size="20px"
                  bg={AppColors.danger}
                  fontWeight="bold"
                  fontSize={'xs'}
                >
                  {unReadNotification}
                </Circle>
              ) : (
                ''
              )}
            </MenuButton>
            <MenuList shadow={'none'} border={'none'} bg={'transparent'}>
              {newNotifications?.length === 0 ? (
                <MenuItem
                  borderTopRadius={'md'}
                  bg={AppColors.white}
                  _hover={{ bg: AppColors.white }}
                >
                  <Box p={4}>No Notifications</Box>
                </MenuItem>
              ) : (
                newNotifications.slice(0, 3).map((n, i) => {
                  return (
                    <Box
                      key={`${i}-notification`}
                      cursor={'pointer'}
                      onClick={() => {
                        handleNotificationStatus(n);
                      }}
                      py={2}
                      borderTopRadius={i === 0 && 'md'}
                      bg={!n.read ? AppColors.white : '#EBEBEE'}
                      borderBottom={`1px solid ${AppColors.gray}`}
                    >
                      <MenuItem  _hover={{ bg: 'none' }}>
                        <Text
                          fontWeight={'semibold'}
                          fontSize={'sm'}
                          color={AppColors.secondary}
                          px={2} 
                        >
                          {n.message.startsWith('@') &&
                            toTitleCase(
                              `${n?.sender?.first_name} ${n?.sender?.last_name}`
                            )}
                        </Text>
                        <Text fontSize={'sm'} color={'black'}>
                          {n.message.startsWith('@')
                            ? n.message?.substring(1)
                            : n.message}
                        </Text>
                      </MenuItem>
                      <Text
                        color={AppColors.darkGray}
                        px={'25px'}
                        fontSize={'12px'}
                      >
                        {formatDistanceStrict(
                          new Date(n.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </Text>
                    </Box>
                  );
                })
              )}

              <MenuItem
                _hover={{
                  bg: newNotifications?.length
                    ? AppColors.secondary
                    : AppColors.gray,
                }}
                bg={
                  newNotifications?.length
                    ? AppColors.secondary
                    : AppColors.gray
                }
                py={3}
                color={AppColors.white}
                borderBottomRadius={'md'}
                display={'flex'}
                justifyContent={'center'}
                onFocusCapture={'false'}
                onClick={() => {
                  if (newNotifications?.length !== 0)
                    navigate(ROUTES.NOTIFICATIONS);
                }}
              >
                <Center fontSize={'sm'}> View all</Center>
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              _hover={{ bg: 'gray.100' }}
            >
              <HStack alignItems={'center'}>
                <Text fontWeight={'bold'} color={AppColors.secondary}>
                  {authContext?.currentUser?.email ?? 'N/A'}
                </Text>
                <ChevronDownIcon />
              </HStack>
            </MenuButton>
            <MenuList
              outline={'none'}
              border={'none'}
              shadow={'md'}
              padding={0}
            >
              <MenuItem
                onClick={() => myAccountRoute()}
                icon={<UserIcon style={{ width: 15, height: 15 }} />}
                height={'50px'}
                fontSize={'16px'}
              >
                My Account
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  navigate(ROUTES.HELP);
                }}
                height={'50px'}
                fontSize={'16px'}
                icon={<HelpIcon style={{ width: 15, height: 15 }} />}
              >
                Help
              </MenuItem> */}
              <MenuItem
                onClick={authContext.onLogout}
                fontSize={'16px'}
                icon={<LogoutIcon style={{ width: 15, height: 15 }} />}
                height={'50px'}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNavItem = ({ navItem }) => {
  // const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = 'white';
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  const matchObject = useMatch(navItem.href);
  const isActive = !!matchObject;

  return (
    <Box ml={5}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <NavLink to={navItem.href ?? '#'}>
            <Box
              p={3}
              borderRadius={'md'}
              fontSize="md"
              textAlign={'center'}
              bgColor={isActive ? AppColors.secondary : null}
              color={isActive ? 'white' : AppColors.black}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
                bgColor: AppColors.secondary,
                transition: 'all 0.5s ease',
              }}
            >
              <Text
                fontFamily={FONT_FAMILY.baiJamurjee}
                letterSpacing={'0.3px'}
                p={1}
                px={2}
                fontSize={'18px'}
                fontWeight={700}
              >
                {navItem.label}
              </Text>
            </Box>
          </NavLink>
        </PopoverTrigger>

        {navItem.children && (
          <PopoverContent
            border={0}
            boxShadow={'xl'}
            bg={popoverContentBgColor}
            p={4}
            rounded={'xl'}
            minW={'sm'}
          >
            <Stack>
              {navItem.children.map(child => (
                <DesktopSubNav key={child.label} {...child} />
              ))}
            </Stack>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};

const DesktopNav = () => {
  return (
    <HStack spacing={4}>
      {NAV_ITEMS.map((navItem, index) => {
        return (
          <DesktopNavItem
            key={`nav-bar-${navItem.href}-${index}`}
            style={{ fontWeight: 'bold' }}
            navItem={navItem}
          />
        );
      })}
    </HStack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <NavLink to={href ?? '#'}>
        <Flex
          py={2}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}
          >
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </NavLink>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map(child => (
              <NavLink key={child.label} py={2} to={child.href}>
                {child.label}
              </NavLink>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default withProvider(NotificationProvider, CQTopbar);

const NAV_ITEMS = [
  {
    label: 'Assign Audits',
    href: ROUTES.ASSIGNED_AUDITS,
  },
  {
    label: 'My Audits',
    href: ROUTES.MY_AUDITS,
  },
  {
    label: 'Reports',
    href: ROUTES.REPORT,
  },
  {
    label: 'Teams',
    href: ROUTES.TEAM,
  },
  {
    label: 'Accounts',
    href: ROUTES.ACCOUNTS,
  },
];
