import { SuccessToastIcon } from "../constants/IconData";

const successToast = (toast, toastProps) => {

    return toast.showSuccess({
        ...toastProps,
        icon: <SuccessToastIcon />,
        duration: 3000,
    });
}
export { successToast }