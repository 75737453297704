/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { withProvider } from "../../hoc/withProvider";
import ChartUploadProvider, { UploadContext } from "../../providers/ChartUploadProvider";
import OfflineUpload from "./components/OfflineUpload";

const ChartUploadContainer = () => {
    const { getUsers } = useContext(UploadContext)
    const callapi = async () => {
        await getUsers()
    }
    useEffect(() => {
        //load all the clients
        callapi()
    }, [])
    
    return <>
        <OfflineUpload />
    </>
}
export default withProvider(ChartUploadProvider, ChartUploadContainer);
