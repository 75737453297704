import { Text } from '@chakra-ui/react';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import React from 'react';
import DeleteConfirmationPopUp from '../../../../components/modal/delete_confirmation_modal/DeleteConfirmationPopUp';

function DeleteAccount({ data, loadList }) {
  const [isModalOpen, imoState] = useBoolean(false);
  return (
    <>
      <Text
        onClick={() => {
          imoState.on();
        }}
        p="5px"
        style={{ color: 'red' }}
      >
        Delete Upload
      </Text>
      <DeleteConfirmationPopUp
        isOpen={isModalOpen}
        onClose={imoState.off}
        rowId={data?.id}
        loadList={loadList}
      />
    </>
  );
}

export default DeleteAccount;
