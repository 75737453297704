import { useBoolean } from '@chakra-ui/react';
import { useToastr } from '@laxmimanogna/code-quick-components';
import React, { useState } from 'react';
import accountsRepository from '../repositories/AccountsRepository';

export const AccountsViewContext = React.createContext({
  isFetchingAccount: false,
  selectedAccount: null,
  fetchAccount: async accountId => {},

  isFetchingHospitals: false,
  accountHospitals: [],
  fetchAccountHospitals: async accountId => {},
});

const AccountsViewProvider = ({ children }) => {
  //toast
  const toast = useToastr();

  // booleans
  const [isFetchingAccount, ifaState] = useBoolean(true);
  const [isFetchingHospitals, ifhState] = useBoolean(false);
  // state
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountHospitals, setAccountHospitals] = useState([]);

  async function fetchAccountHospitals(accountId, filterParams) {
    try {
      ifhState.on();
      const hospitalResponse =
        await accountsRepository.getAccountBasedHospitals(
          accountId,
          filterParams
        );
      setAccountHospitals(hospitalResponse);
    } catch (e) {
      toast.showError({
        description: e.toString(),
      });
    } finally {
      ifhState.off();
    }
  }

  async function fetchAccount(accountId) {
    try {
      ifaState.on();
      const response = await accountsRepository.getAccount(accountId);
      setSelectedAccount(response);
    } catch (e) {
      toast.showError({
        description: e.toString(),
      });
    } finally {
      ifaState.off();
    }
  }

  const mContext = {
    isFetchingAccount,
    selectedAccount,
    fetchAccount,

    isFetchingHospitals,
    accountHospitals,
    fetchAccountHospitals,
  };

  return (
    <AccountsViewContext.Provider value={mContext}>
      {children}
    </AccountsViewContext.Provider>
  );
};

export default AccountsViewProvider;
