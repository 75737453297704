/* eslint-disable array-callback-return */
import React, { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { Box, HStack, ScaleFade, Skeleton } from '@chakra-ui/react';
import { FONT_FAMILY } from '../../../../constants/Theme';
import CQBarchart from '../../../../components/CQBarchart';
import { ReportContext } from '../../../../providers/ReportProvider';
import CQError from '../../../../components/CQError';

const WeekWiseChart = ({ isLoading, errorRef, accuracyRef }) => {
  const reportContext = useContext(ReportContext);
  const {
    weekAccuracyChartData,
    weekErrorChartData,
    isWeekAccuracyChartLoading,
    isWeekErrorChartLoading,
    currentMonth,
  } = reportContext;

  const errorOption = {
    responsive: true,
    hover: { mode: null },
    responsiveAnimationDuration: 0,
    animation: {
      duration: 0,
      onComplete: function ({ chart }) {
        const ctx = chart.ctx;

        chart.config.data.datasets.forEach(function (dataset, i) {
          const meta = chart.getDatasetMeta(i);

          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];
            const length = data?.toString().length;
            ctx.fillText(
              data,
              bar.x - (length === 1 ? 6 : length * 3),
              bar.y - 5
            );
          });
        });
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        onClick: () => {},
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,
          color: 'black',
          // This more specific font property overrides the global property
          font: {
            size: 10,
            family: FONT_FAMILY.ptSans,
          },
        },
      },
      title: {
        display: true,
        text: `Week Wise - Error Trend - ${currentMonth} - MTD`,
        color: 'black',
        padding: 20,
        // This more specific font property overrides the global property
        font: {
          size: 18,
          family: FONT_FAMILY.baiJamurjee,
          weight: 'normal',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };
  const accuracyOption = {
    responsive: true,
    hover: { mode: null },
    responsiveAnimationDuration: 0,
    animation: {
      duration: 0,
      onComplete: function ({ chart }) {
        const ctx = chart.ctx;

        chart.config.data.datasets.forEach(function (dataset, i) {
          const meta = chart.getDatasetMeta(i);

          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];
            const length = data.toString().length + 1;
            ctx.fillText(
              `${data}%`,
              bar.x - (length === 1 ? 6 : length * 3),
              bar.y - 5
            );
          });
        });
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        onClick: () => {},
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,
          // This more specific font property overrides the global property
          color: 'black',
          font: {
            size: 10,
            family: FONT_FAMILY.ptSans,
          },
        },
      },
      title: {
        display: true,
        text: `Week Wise - Accuracy & Error % -${currentMonth} - MTD`,
        padding: 20,
        color: 'black',
        // This more specific font property overrides the global property
        font: {
          size: 18,
          family: FONT_FAMILY.baiJamurjee,
          weight: 'normal',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return value + '%';
          },
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const labels = ['1', '2', '3', '4', '5'];

  const errorDataValues2 = [];
  const errorDataValues1 = [];
  if (Object.keys(weekErrorChartData).length) {
    Object.keys(weekErrorChartData).map((key, i) => {
      if (i > 0) {
        errorDataValues2.push(weekErrorChartData[key]['E&M']);
        errorDataValues1.push(weekErrorChartData[key].CPT);
      }
    });
  }

  const accuracyDataValues2 = [];
  const accuracyDataValues1 = [];
  if (Object.keys(weekAccuracyChartData).length) {
    Object.keys(weekAccuracyChartData).map(key => {
      accuracyDataValues2.push(weekAccuracyChartData[key].error);
      accuracyDataValues1.push(weekAccuracyChartData[key].cq_accuracy);
    });
  }

  const errorData = {
    labels,
    datasets: [
      {
        label: 'CPT',
        data: errorDataValues1,
        borderColor: AppColors.secondary,
        backgroundColor: AppColors.secondary,
      },
      {
        label: 'E&M',
        data: errorDataValues2,
        borderColor: AppColors.red,
        backgroundColor: AppColors.red,
      },
    ],
  };
  const accuracyData = {
    labels,
    datasets: [
      {
        label: 'RI Accuracy %',
        data: accuracyDataValues1,
        borderColor: AppColors.secondary,
        backgroundColor: AppColors.secondary,
      },
      {
        label: 'Error %',
        data: accuracyDataValues2,
        borderColor: AppColors.red,
        backgroundColor: AppColors.red,
      },
    ],
  };

  const renderLoader = () => {
    return (
      <Box>
        <Skeleton w={'100%'} h={200} borderRadius="2xl" />
      </Box>
    );
  };

  return (
    <HStack m={5} justifyContent="center" spacing={4} minHeight={250}>
      {/* <Box width={'45%'} ref={errorRef}> */}
      <Box width={'45%'}>
        {isWeekErrorChartLoading ? (
          renderLoader()
        ) : !Object.keys(weekErrorChartData).length ? (
          <CQError />
        ) : (
          <ScaleFade initialScale={0.1} in={true}>
            <CQBarchart option={errorOption} data={errorData} />
          </ScaleFade>
        )}
      </Box>
      {/* <Box width={'45%'} ref={accuracyRef}> */}
      <Box width={'45%'}>
        {isLoading || isWeekAccuracyChartLoading ? (
          renderLoader()
        ) : !Object.keys(weekAccuracyChartData).length ? (
          <CQError />
        ) : (
          <ScaleFade initialScale={0.1} in={true}>
            <CQBarchart option={accuracyOption} data={accuracyData} />
          </ScaleFade>
        )}
      </Box>
    </HStack>
  );
};
export default WeekWiseChart;
