import CodeQuickShortIconData from '../assets/images/revintegrity-logo-no-bg.png';
import BellIconData from '../assets/icons/bell-icon.svg';
import MailIconData from '../assets/icons/mail-icon.svg';
import LockIconData from '../assets/icons/lock-icon.svg';
import EyeHiddenIconData from '../assets/icons/eye-hidden-icon.svg';
import SearchIconData from '../assets/icons/search-icon.svg';
import PersonIconData from '../assets/icons/person-icon.svg';
import EditIconData from '../assets/icons/edit-icon.svg';
import ViewIconData from '../assets/icons/view-icon.svg';
import EyeIconData from '../assets/icons/eye-icon.svg';
import UserIconData from '../assets/icons/user-icon.svg';
import HelpIconData from '../assets/icons/help-icon.svg';
import LogoutIconData from '../assets/icons/logout-icon.svg';
import MarkIconData from '../assets/icons/mark-icon.svg';
import DotMenuIconData from '../assets/icons/dot-menu-icon.svg';
import UploadIconData from '../assets/icons/upload-icon.svg';
import PinIconData from '../assets/icons/pin-icon.svg';
import CrossIconData from '../assets/icons/cross-icon.svg';
import TickIconData from '../assets/icons/tick-icon.svg';
import JustTickIconData from '../assets/icons/just-tick.svg';
import RedCrossIconData from '../assets/icons/cross-red-icon.svg';
import ClientSelectIconData from '../assets/icons/client-select-icon.svg';
import EyeAvatarIconData from '../assets/icons/eye-avatar-icon.svg';
import SortIconData from '../assets/icons/sort-icon.svg';
import PersonAvatarIconData from '../assets/icons/person-avatar-icon.svg';
import UpIconData from '../assets/icons/up-icon.svg';
import DownIconData from '../assets/icons/down-icon.svg';
import LeftIconData from '../assets/icons/left.svg';
import RightIconData from '../assets/icons/right.svg';
import UrgentIconData from '../assets/icons/urgent-icon.svg';
import OpenIconData from '../assets/icons/open-icon.svg';
import CloseIconData from '../assets/icons/close-icon.svg';
import RebuttalIconData from '../assets/icons/rebuttal-icon.svg';
import CloseModalIconData from '../assets/icons/close-modal-icon.svg';
import UncheckedIconData from '../assets/icons/unchecked-icon.svg';
import CheckedIconData from '../assets/icons/checked-icon.svg';
import XIconData from '../assets/icons/x-icon.svg';
import BackArrowIconData from '../assets/icons/back-arrow-icon.svg';
import ProfileAvatarIconData from '../assets/icons/profile-avatar-icon.svg';
import TriangleIconData from '../assets/icons/triangle-icon.svg';
import AddIconData from '../assets/icons/add-icon.svg';
import TrashIconData from '../assets/icons/trash-icon.svg';
import SuccessToastIconData from '../assets/icons/success-toast-icon.svg';
import NoteIconData from '../assets/icons/note-icon.svg';
import SheetEyeIconData from '../assets/icons/sheet-eye-icon.svg';
import CommentIconData from '../assets/icons/comment-icon.svg';
import BackIconData from '../assets/icons/back-icon.svg';
import LeftTriangleIconData from '../assets/icons/left-triangle-icon.svg';
import RightTriangleIconData from '../assets/icons/right-triangle-icon.svg';
import PlusIconData from '../assets/icons/plus-icon.svg';
import MinusIconData from '../assets/icons/minus-icon.svg';
import LinkIconData from '../assets/icons/link-icon.svg';
import TriangleRedIconData from '../assets/icons/triangle-red-icon.svg';
import XIconGrayData from '../assets/icons/x-icon-gray.svg';
import UncheckedSquareIconData from '../assets/icons/unchecked-square.svg';
import CheckedSquareIconData from '../assets/icons/checked-square.svg';
import HealthSystemIconData from '../assets/icons/health-system-icon.svg';
import AddressIconData from '../assets/icons/address-icon.svg';
import DepartmentIconData from '../assets/icons/department-icon.svg';
import PatientIconData from '../assets/icons/patient-per-month-icon.svg';
import YellowTickIconData from '../assets/icons/yellow-tick-icon.svg';
import FilledPlusIconData from '../assets/icons/filled-plus-icon.svg';
import UpDownIconData from '../assets/icons/up-down-arrows.svg';
import SortUpIconData from '../assets/icons/up-arrow.svg';
import SortDownIconData from '../assets/icons/down-arrow.svg';
import LeftEnableIconData from "../assets/icons/left-enable-icon.svg"
import RightEnableIconData from "../assets/icons/right-enable-icon.svg"
import GoogleDriveIconData from "../assets/icons/google-drive.svg"
import MsOneDriveIconData from "../assets/icons/ms-onedrive-svgrepo-com.svg"

import { Tooltip } from '@chakra-ui/react';

const CodeQuickIcon = props => (
  <img alt="code-quick-short-icon" src={CodeQuickShortIconData} {...props} />
);
const BellIcon = props => (
  <img
    alt="bell-icon"
    src={BellIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const MailIcon = props => (
  <img
    alt="mail-icon"
    src={MailIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const LockIcon = props => (
  <img
    alt="lock-icon"
    src={LockIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EyeHiddenIcon = props => (
  <img
    alt="eye-icon"
    src={EyeHiddenIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SearchIcon = props => (
  <img
    alt="search-icon"
    src={SearchIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const PersonIcon = props => (
  <img
    alt="person-icon"
    src={PersonIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EditIcon = props => (
  <img
    alt="edit-icon"
    src={EditIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const ViewIcon = props => (
  <img
    alt="view-icon"
    src={ViewIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EyeIcon = props => (
  <img
    alt="eye-icon"
    src={EyeIconData}
    style={{ width: 20, height: 18 }}
    {...props}
  />
);
const UserIcon = props => (
  <img
    alt="user-icon"
    src={UserIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const HelpIcon = props => (
  <img
    alt="help-icon"
    src={HelpIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const LogoutIcon = props => (
  <img
    alt="logout-icon"
    src={LogoutIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const MarkIcon = props => (
  <img
    alt="mark-icon"
    src={MarkIconData}
    style={{ width: 84, height: 84 }}
    {...props}
  />
);
const DotMenuIcon = props => (
  <img
    alt="dot-menu-icon"
    src={DotMenuIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const EyeAvatarIcon = props => (
  <img
    alt="eye-avatar-icon"
    src={EyeAvatarIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const SortIcon = props => (
  <img
    alt="sort-icon"
    src={SortIconData}
    style={{ width: 14, height: 14 }}
    {...props}
  />
);
const UploadIcon = props => (
  <img
    alt="upload-icon"
    src={UploadIconData}
    style={{ width: 80, height: 80 }}
    {...props}
  />
);
const PinIcon = props => (
  <img
    alt="pin-icon"
    src={PinIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const CrossIcon = props => (
  <img
    alt="cross-icon"
    src={CrossIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const TickIcon = props => (
  <img
    alt="tick-icon"
    src={TickIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const JustTickIcon = props => (
  <img
    alt="tick-icon"
    src={JustTickIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const RedCrossIcon = props => (
  <img
    alt="red-cross-icon"
    src={RedCrossIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const ClientSelectIcon = props => (
  <img
    alt="client-selection-icon"
    src={ClientSelectIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);
const OpenIcon = props => (
  <img
    alt="open-icon"
    src={OpenIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);
const CloseIcon = props => (
  <img
    alt="client-selection-icon"
    src={CloseIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const PersonAvatarIcon = props => (
  <img
    alt="preson-avatart-icon"
    src={PersonAvatarIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const UpIcon = props => (
  <img
    alt="up-icon"
    src={UpIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const DownIcon = props => (
  <img
    alt="down-icon"
    src={DownIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const UrgentIcon = props => (
  <img
    alt="urgent-icon"
    src={UrgentIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);

const RightIcon = props => (
  <img
    alt="right-icon"
    src={RightIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const LeftIcon = props => (
  <img
    alt="left-icon"
    src={LeftIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const RebuttalIcon = props => (
  <img
    alt="rebuttal-icon"
    src={RebuttalIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const CloseModalIcon = props => (
  <img
    alt="close-icon"
    src={CloseModalIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const CheckedIcon = props => (
  <img
    alt="checked-icon"
    src={CheckedIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const UncheckedIcon = props => (
  <img
    alt="unchecked-icon"
    src={UncheckedIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const XIcon = props => (
  <img
    alt="X-icon"
    src={XIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const BackArrowIcon = props => (
  <img
    alt="back-arrow-icon"
    src={BackArrowIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const ProfileAvatarIcon = props => (
  <img
    alt="profile-avatar-icon"
    src={ProfileAvatarIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const TriangleIcon = props => (
  <img
    alt="triangle-icon"
    src={TriangleIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const AddIcon = props => (
  <img
    alt="add-icon"
    src={AddIconData}
    style={{ width: 50, height: 50 }}
    {...props}
  />
);
const TrashIcon = props => (
  <img
    alt="trash-icon"
    src={TrashIconData}
    style={{ width: 50, height: 50 }}
    {...props}
  />
);
const SuccessToastIcon = props => (
  <img
    alt="success-toast-icon"
    src={SuccessToastIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const NoteIcon = props => (
  <img
    alt="Note-icon"
    src={NoteIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const SheetEyeIcon = props => (
  <img
    alt="sheet-eye-icon"
    src={SheetEyeIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const CommentIcon = props => (
  <img
    alt="comment-icon"
    src={CommentIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const BackIcon = props => (
  <img
    alt="sheet-eye-icon"
    src={BackIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const LeftTriangleIcon = props => (
  <img
    alt="LeftTriangle-icon"
    src={LeftTriangleIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const RightTriangleIcon = props => (
  <img
    alt="right-triangle-icon"
    src={RightTriangleIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const PlusIcon = props => (
  <img
    alt="plus-icon"
    src={PlusIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const MinusIcon = props => (
  <img
    alt="minus-icon"
    src={MinusIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const LinkIcon = props => (
  <img
    alt="Link-icon"
    src={LinkIconData}
    style={{ width: 13, height: 13 }}
    {...props}
  />
);
const TriangleRedIcon = props => (
  <img
    alt="TriangleRed-icon"
    src={TriangleRedIconData}
    style={{ width: 13, height: 13 }}
    {...props}
  />
);
const XIconGray = props => (
  <img
    alt="X-icon-gray"
    src={XIconGrayData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const CheckedSquareIcon = props => (
  <img
    alt="checked-square-icon"
    src={CheckedSquareIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const UncheckedSquareIcon = props => (
  <img
    alt="unchecked-square-icon"
    src={UncheckedSquareIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const HealthSystemIcon = props => (
  <img
    alt="health-system-icon"
    src={HealthSystemIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const AddressIcon = props => (
  <img
    alt="address-icon"
    src={AddressIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const DepartmentIcon = props => (
  <img
    alt="department-icon"
    src={DepartmentIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const PatientIcon = props => (
  <img
    alt="patient-icon"
    src={PatientIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);
const YellowTickIcon = props => (
  <img
    alt="yellow-tick-icon"
    src={YellowTickIconData}
    style={{ width: 20, height: 20 }}
    {...props}
  />
);

const FilledPlusIcon = props => (
  <img
    alt="filled-plus-icon"
    src={FilledPlusIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const UpDownIcon = props => (
  <img
    alt="up-down-icon"
    src={UpDownIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SortUpIcon = props => (
  <img
    alt="sort-up-icon"
    src={SortUpIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SortDownIcon = props => (
  <img
    alt="sort-down-icon"
    src={SortDownIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);

const LeftEnableIcon = props => (
    <img
      alt="code-quick-short-icon"
      src={LeftEnableIconData}
      style={{ width: 40, height: 40 }}
      {...props}
    />
  );
  
  const RightEnableIcon = props => (
    <img
      alt="code-quick-short-icon"
      src={RightEnableIconData}
      style={{ width: 40, height: 40 }}
      {...props}
    />
  );

  const GoogleDriveIcon = props => (
    <Tooltip label="Google Drive" fontSize="md">
      <img
        alt="google-drive-icon"
        src={GoogleDriveIconData}
        style={{
          width: 40,
          height: 40,
          transition: 'transform 0.2s ease',
          cursor: 'pointer',
          filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))',
        }}
        onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.1)')}
        onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
        {...props}
      />
    </Tooltip>
  );
  
  const MsOneDriveIcon = props => (
    <Tooltip label="OneDrive" fontSize="md">
      <img
        alt="ms-one-drive-icon"
        src={MsOneDriveIconData}
        style={{
          width: 40,
          height: 40,
          transition: 'transform 0.2s ease',
          cursor: 'pointer',
          filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))',
        }}
        onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.1)')}
        onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
        {...props}
      />
    </Tooltip>
  );
  

export {
  CodeQuickIcon,
  BellIcon,
  MailIcon,
  LockIcon,
  EyeHiddenIcon,
  SearchIcon,
  ViewIcon,
  EditIcon,
  PersonIcon,
  EyeIcon,
  UserIcon,
  HelpIcon,
  LogoutIcon,
  DotMenuIcon,
  EyeAvatarIcon,
  SortIcon,
  MarkIcon,
  UploadIcon,
  PinIcon,
  CrossIcon,
  TickIcon,
  RedCrossIcon,
  ClientSelectIcon,
  CloseIcon,
  OpenIcon,
  PersonAvatarIcon,
  UpIcon,
  DownIcon,
  LeftIcon,
  RightIcon,
  UrgentIcon,
  CloseModalIcon,
  CheckedIcon,
  UncheckedIcon,
  XIcon,
  BackArrowIcon,
  ProfileAvatarIcon,
  TriangleIcon,
  AddIcon,
  TrashIcon,
  SuccessToastIcon,
  NoteIcon,
  SheetEyeIcon,
  CommentIcon,
  BackIcon,
  LeftTriangleIcon,
  RightTriangleIcon,
  PlusIcon,
  MinusIcon,
  RebuttalIcon,
  LinkIcon,
  TriangleRedIcon,
  XIconGray,
  CheckedSquareIcon,
  UncheckedSquareIcon,
  HealthSystemIcon,
  AddressIcon,
  JustTickIcon,
  DepartmentIcon,
  PatientIcon,
  YellowTickIcon,
  FilledPlusIcon,
  UpDownIcon,
  SortUpIcon,
  SortDownIcon,
  LeftEnableIcon,
  RightEnableIcon,
  GoogleDriveIcon,
  MsOneDriveIcon,
};
