import { Box, Center, Text } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import {
  defaultReactTableProp,
  getCellProps,
} from '../../../../utils/accuracy_error_report.util';
import { createColumnHelper } from '@tanstack/react-table';
import { ReportContext } from '../../../../providers/ReportProvider';
import { SELECTED_SPECIFICS, SPECIFICS } from '../../constants';
import { CQReactTable } from '@laxmimanogna/code-quick-components';

const columnHelper = createColumnHelper();

const AccuracyErrorReport = ({ isLoading }) => {
  const reportContext = useContext(ReportContext);
  const { weekAccuracyTableData, isWeekAccuracyTableLoading, currentMonth } =
    reportContext;

  const columns = useMemo(
    () => [
      columnHelper.accessor('specifics', {
        header: () => 'Specifics',
        enableSorting: false,
        cell: info => {
          const cellValue = info.getValue();

          return <Text py={2}>{SPECIFICS[cellValue]}</Text>;
        },
      }),
      columnHelper.accessor('current_month', {
        id: 'current_month',
        enableSorting: false,
        header: () => <span>{currentMonth} - MTD </span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return <Text>{cellValue}</Text>;
        },
      }),
      columnHelper.accessor('week1', {
        id: 'week1',
        enableSorting: false,
        header: () => <span>Week 1</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'N/A';
          }
          if (
            cellValue === 0 &&
            SELECTED_SPECIFICS.includes(info.row.original.specifics)
          ) {
            return 'N/A';
          }
          return <Text>{cellValue}</Text>;
        },
      }),
      columnHelper.accessor('week2', {
        id: 'week2',
        enableSorting: false,
        header: () => <span>Week 2</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'N/A';
          }
          if (
            cellValue === 0 &&
            SELECTED_SPECIFICS.includes(info.row.original.specifics)
          ) {
            return 'N/A';
          }
          return <Text>{cellValue}</Text>;
        },
      }),
      columnHelper.accessor('week3', {
        id: 'week3',
        enableSorting: false,
        header: () => <span>Week 3</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'N/A';
          }
          if (
            cellValue === 0 &&
            SELECTED_SPECIFICS.includes(info.row.original.specifics)
          ) {
            return 'N/A';
          }
          return <Text>{cellValue}</Text>;
        },
      }),
      columnHelper.accessor('week4', {
        id: 'week4',
        enableSorting: false,
        header: () => <span>Week 4</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'N/A';
          }
          if (
            cellValue === 0 &&
            SELECTED_SPECIFICS.includes(info.row.original.specifics)
          ) {
            return 'N/A';
          }
          return <Text>{cellValue}</Text>;
        },
      }),
      columnHelper.accessor('week5', {
        id: 'week5',
        enableSorting: false,
        header: () => <span>Week 5</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (cellValue === null) {
            return 'N/A';
          }
          if (
            cellValue === 0 &&
            SELECTED_SPECIFICS.includes(info.row.original.specifics)
          ) {
            return 'N/A';
          }
          return <Text>{cellValue}</Text>;
        },
      }),
    ],
    [currentMonth]
  );

  return (
    <Box m={10} pb={5}  >
      <CQReactTable
        data={weekAccuracyTableData}
        isLoading={isLoading || isWeekAccuracyTableLoading}
        columns={columns}
        {...defaultReactTableProp}
        getCellProps={(cell, data) =>
          getCellProps(cell, data, weekAccuracyTableData.length)
        }
        tableContainerProps={{
          borderRadius: '2xl',
        }}
      />
      {!isLoading && !weekAccuracyTableData.length ? (
        <Center mt={10}> No data available</Center>
      ) : null}
    </Box>
  );
};
export default AccuracyErrorReport;
