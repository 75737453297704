import {
  Box,
  Checkbox,
  HStack,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';

const CustomMenuComponent = props => {
  const { selected, setSelected } = props;
  const [selectedOption, setSelectedOption] = useState(selected);

  return (
    <PerfectScrollbar
      style={{
        maxHeight: '170px',
        marginTop: 5,
      }}
    >
      {props.options.length ? (
        props.options.map((option, i) => {
          return (
            <Box
              p={3}
              m={1}
              width={'full'}
              key={`${i}-menu list`}
              bg={AppColors.loginFormBg}
            >
              <Checkbox
                size="md"
                colorScheme={'brand'}
                onChange={e => {
                  if (e.target.checked) {
                    if (!selectedOption.includes(option.id)) {
                      setSelected([...selectedOption, option.id]);
                      setSelectedOption([...selectedOption, option.id]);
                      props.setCustomError('members', {
                        ref: { name: 'members' },
                      });
                    }
                  } else {
                    if (selectedOption.includes(option.id)) {
                      const temp = selectedOption.filter(d => d !== option.id);
                      setSelected(temp);
                      setSelectedOption(temp);
                      if (temp.length) {
                        props.setCustomError('members', {
                          ref: { name: 'members' },
                        });
                      }
                    }
                  }
                }}
                defaultChecked={selectedOption.includes(option.id)}
              >
                <Text
                  ml={2}
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize={14}
                  color={AppColors.secondary}
                  fontWeight={'bold'}
                >
                  {option.name} |{' '}
                  <span
                    style={{
                      color: AppColors.secondary,
                      fontWeight: 'normal',
                    }}
                  >
                    {option.role}
                  </span>
                </Text>
              </Checkbox>
              <Stack ml={8}>
                <Text
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize={14}
                  color={AppColors.primary}
                >
                  {option.email}
                </Text>
                <Text
                  textTransform={'capitalize'}
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize={14}
                  color={AppColors.black}
                  fontWeight={
                    selectedOption.includes(option.id) ? 'bold' : 'normal'
                  }
                >
                  <HStack>
                    <Text>{option.specialty[0]}</Text>
                    {option.specialty.length > 1 ? (
                      <>
                        <Tooltip
                          hasArrow
                          label={option.specialty.slice(1).join(' | ')}
                          bg={AppColors.secondary}
                          border={'none'}
                        >
                          <Text
                            fontSize={'sm'}
                            bg={AppColors.secondary}
                            color={'#fff'}
                            px={2}
                            py={0.7}
                            borderRadius={'full'}
                            cursor={'pointer'}
                            fontWeight={'md'}
                          >
                            + {option.specialty.length - 1}
                          </Text>
                        </Tooltip>
                      </>
                    ) : (
                      ''
                    )}
                  </HStack>
                </Text>
              </Stack>
            </Box>
          );
        })
      ) : (
        <Box p={3} m={1} bg={AppColors.loginFormBg}>
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontSize={14}
            color={AppColors.black}
            textAlign="center"
          >
            No Options
          </Text>
        </Box>
      )}
    </PerfectScrollbar>
  );
};
export { CustomMenuComponent };
