/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppColors from '../../../constants/AppColors';
import { XIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { TeamContext } from '../../../providers/TeamProvider';

const MemberList = ({ setSelected, getValues }) => {
  const { memberList: options } = useContext(TeamContext);
  const [selectedOption, setSelectedOption] = useState(getValues('members'));

  useEffect(() => {
    setSelectedOption(getValues('members'));
  }, [getValues('members')]);

  const render = useCallback(() => {
    return (
      <SimpleGrid columns={2} spacing={2}>
        {selectedOption.map((selectedId, i) => {
          const option = options.find(data => data.id === selectedId);
          return option && Object.keys(option).length ? (
            <Box bg={AppColors.loginFormBg} mr={4} p={2} key={`${i}-select`}>
              <Box style={{ direction: 'rtl' }}>
                <XIcon
                  style={{ width: 8, height: 8, cursor: 'pointer' }}
                  onClick={() => {
                    const temp = selectedOption.filter(d => d !== option.id);
                    setSelected(temp);
                    setSelectedOption(temp);
                  }}
                />
              </Box>
              <Text
                fontFamily={FONT_FAMILY.ptSans}
                fontSize="sm"
                color={AppColors.secondary}
                fontWeight="bold"
              >
                {option.name}{' '}
                <span style={{ color: 'black', fontWeight: 'normal' }}>
                  | {option.role}
                </span>
              </Text>
              <Text
                fontFamily={FONT_FAMILY.ptSans}
                fontSize="sm"
                color={AppColors.primary}
                fontWeight="normal"
              >
                {option.email}
              </Text>
              <Text
                fontFamily={FONT_FAMILY.ptSans}
                fontSize="sm"
                color={AppColors.black}
                fontWeight="normal"
              >
                {option.specialty}
              </Text>
            </Box>
          ) : null;
        })}
      </SimpleGrid>
    );
  }, [options, getValues('members')]);

  return (
    <PerfectScrollbar
      style={{
        maxHeight: '170px',
        marginTop: 5,
      }}
    >
      {render()}
    </PerfectScrollbar>
  );
};
export default MemberList;
