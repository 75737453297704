/* eslint-disable react-hooks/exhaustive-deps */
import {
  SearchIcon,
  EditIcon,
  CheckIcon,
  CloseIcon,
  SmallAddIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Flex,
  GridItem,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  useEditableControls,
  VStack,
} from '@chakra-ui/react';
import {
  CQReactTable,
  Heading,
} from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { BackArrowIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { FONT_FAMILY } from '../../constants/Theme';
import { withMultiProviders } from '../../hoc/withProvider';
import AccountsProvider, {
  AccountContext,
} from '../../providers/AccountsProvider';
import EditAccountProvider, {
  EditAccountContext,
} from '../../providers/EditAccountProvider';
import { defaultReactTableProp } from '../../utils/assigned_audit.util';
import { replaceRoute } from '../../utils/common.utils';
import DeleteHealthSystemAlert from './alerts/DeleteHealthSystemAlert';
import AddHospitalForm from './forms/AddHospitalForm';
import EditHealthSystem from './EditHealthSystem';

const columnHelper = createColumnHelper();

const EDIT_TYPES = {
  inline: 'inline',
  modal: 'modal',
  inline_area: 'inline_area',
};

function getHeaderCellProps(header) {
  return {
    textTransform: 'none',
    fontFamily: FONT_FAMILY.ptSans,
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'black',
    justifyContent: 'flex-start',
  };
}

function getCellProps(row, cell) {
  const isInactive = !cell.row.original?.is_active;
  if (cell.column.id === 'name') {
    return {
      style: {
        color: isInactive ? AppColors.darkGray : AppColors.secondary,
        fontWeight: 'bold',
      },
    };
  }

  return { style: { color: isInactive ? AppColors.darkGray : 'inherit' } };
}

const EditableLabel = ({
  label,
  value,
  editable = true,
  render,
  editType,
  onSave,
  onModalEdit,
}) => {
  const [editableValue, setEditableValue] = useState(value);

  React.useEffect(() => {
    setEditableValue(value);
  }, [value]);

  function SaveControl() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
      useEditableControls();

    return isEditing ? (
      <ButtonGroup
        ml={2}
        justifyContent="center"
        size="sm"
        alignItems={'center'}
      >
        <IconButton
          rounded={'full'}
          variant={'outline'}
          size={'xs'}
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          rounded={'full'}
          variant={'outline'}
          size={'xs'}
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : null;
  }

  function EditControl() {
    const { isEditing, getEditButtonProps } = useEditableControls();

    return isEditing ? null : (
      <Flex justifyContent="center">
        <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    );
  }

  function renderCommonInput() {
    return (
      <HStack
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        w={'100%'}
        spacing={2}
      >
        <Box w={'100%'}>
          <Text
            mb={3}
            fontSize={'sm'}
            fontWeight={'bold'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            color={AppColors.secondary}
          >
            {label}
          </Text>
          {render ? (
            render()
          ) : (
            <Text color={'black'} fontSize={'sm'}>
              {value}
            </Text>
          )}
        </Box>
      </HStack>
    );
  }

  function renderInlineAreaInput() {
    return (
      <Editable
        value={editableValue}
        onChange={setEditableValue}
        fontSize={'sm'}
        w={'100%'}
        isPreviewFocusable={false}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          w={'100%'}
          spacing={2}
        >
          <Box w={'100%'}>
            <Text
              mb={3}
              fontSize={'sm'}
              fontWeight={'bold'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              color={AppColors.secondary}
            >
              {label}
            </Text>
            {render ? (
              render()
            ) : (
              <React.Fragment>
                <EditablePreview />
                <InputGroup>
                  <Input size={'sm'} as={EditableTextarea} minH={24} />
                  <SaveControl />
                </InputGroup>
              </React.Fragment>
            )}
          </Box>
          <EditControl />
        </HStack>
      </Editable>
    );
  }

  function renderInlineInput() {
    return (
      <Editable
        value={editableValue}
        onChange={setEditableValue}
        fontSize={'sm'}
        w={'100%'}
        isPreviewFocusable={false}
      >
        <HStack
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          w={'100%'}
          spacing={2}
        >
          <Box w={'100%'}>
            <Text
              mb={3}
              fontSize={'sm'}
              fontWeight={'bold'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              color={AppColors.secondary}
            >
              {label}
            </Text>
            {render ? (
              render()
            ) : (
              <React.Fragment>
                <EditablePreview />
                <InputGroup>
                  <Input size={'sm'} as={EditableInput} />
                  <SaveControl />
                </InputGroup>
              </React.Fragment>
            )}
          </Box>
          <EditControl />
        </HStack>
      </Editable>
    );
  }

  function renderModalInput() {
    return (
      <Editable
        value={editableValue}
        onChange={setEditableValue}
        fontSize={'sm'}
        w={'100%'}
        isPreviewFocusable={false}
      >
        {editProps => {
          return (
            <HStack
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              w={'100%'}
              spacing={2}
            >
              <Box w={'100%'}>
                <Text
                  mb={3}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  color={AppColors.secondary}
                >
                  {label}
                </Text>
                {render ? (
                  render()
                ) : (
                  <React.Fragment>
                    <EditablePreview />

                    <HStack alignItems={'center'}>
                      <InputGroup size={'sm'}>
                        <Input
                          _focusVisible={{
                            borderRightWidth: 0,
                            borderColor: '#ccc',
                            boxShadow: 'none',
                          }}
                          as={EditableInput}
                          textOverflow={'ellipsis'}
                          size={'sm'}
                        />
                        {editProps.isEditing && (
                          <InputRightAddon
                            onClick={e => e.preventDefault()}
                            borderColor={'#ccc'}
                            bgColor={'inherit'}
                            cursor={'pointer'}
                          >
                            <SmallAddIcon
                              borderColor={AppColors.primary}
                              color={AppColors.primary}
                              fontSize={'sm'}
                              borderRadius={'full'}
                              borderWidth={1}
                            />
                          </InputRightAddon>
                        )}
                      </InputGroup>
                      <SaveControl />
                    </HStack>
                  </React.Fragment>
                )}
              </Box>
              <EditControl />
            </HStack>
          );
        }}
      </Editable>
    );
  }

  function renderInput() {
    switch (editType) {
      case EDIT_TYPES.inline:
        return renderInlineInput();
      case EDIT_TYPES.inline_area:
        return renderInlineAreaInput();
      case EDIT_TYPES.modal:
        return renderModalInput();
      default:
        return renderCommonInput();
    }
  }

  return renderInput();
};

const EditAccountContainer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const editAccountContext = useContext(EditAccountContext);
  const accountContext = useContext(AccountContext);

  const { accountId } = params;

  const [showAccountDeleteConfirmation, sadcState] = useBoolean(false);
  const [hospitalFormVisible, hfvState] = useBoolean(false);

  const [, ioemState] = useBoolean(false);

  const [selectedHospitalId] = useState('');

  const [search, setSearch] = React.useState('');
  const [ordering, setOrdering] = React.useState('');
  const [isUnderstood, iuState] = useBoolean(false);
  const [hsState, setHsState] = React.useState(null);

  useEffect(() => {
    // close the add form hosital form
    if (!selectedHospitalId) ioemState.off();
    // open the modal
    else ioemState.on();
  }, [selectedHospitalId]);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    accountContext.fetchEHROptions();
    accountContext.fetchInsuranceOptions();
    accountContext.fetchSpecialtyOptions();

    editAccountContext.fetchHealthSystem(accountId);
  }, []);

  useEffect(() => {
    getHospitalData();
  }, [ordering]);

  const getHospitalData = () => {
    editAccountContext.fetchHealthSystemHospitals(accountId, {
      search,
      ordering,
    });
  };

  function handleOpenAddHospital() {
    hfvState.on();
  }

  const hospitalColumns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => 'Hospitals',
      }),
      columnHelper.accessor('is_active', {
        header: () => 'Status',
        cell: info => {
          if (info.row.original.is_active) {
            return 'Active';
          } else {
            return 'Inactive';
          }
        },
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const isInactive = !info.row.original.is_active;
          const id = info.row.original.id;
          return (
            <Button
              outline={`2px solid ${AppColors.secondary}`}
              color={AppColors.white}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
              }}
              bgColor={isInactive ? AppColors.lightGrey : AppColors.secondary}
              textColor={'#fff'}
              _disabled={{
                bgColor: '#e6e6e6',
                textColor: '#6d747e',
              }}
              size={'sm'}
              px={12}
              rightIcon={<EditIcon />}
              rounded={'full'}
              onClick={() => {
                // setSelectedHospitalId(id);
                const hospitalEditPage = replaceRoute(ROUTES.GET_HOSPITAL, {
                  accountId,
                  hospitalId: id,
                });

                navigate(hospitalEditPage, {
                  state: {
                    accountName: location.state?.accountName || 'Health System',
                    hospitalName: info.row.original?.name,
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      }),
    ];
  }, []);

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack>
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon
                style={{ width: 44, height: 44, cursor: 'pointer' }}
              />
              <Text
                fontSize={'md'}
                paddingLeft={2}
                fontWeight="semibold"
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
      </HStack>
    );
  }

  function renderHospitalSection() {
    return (
      <VStack w={'100%'} alignItems={'flex-start'} spacing={4}>
        <HStack justifyContent={'space-between'} w={'100%'} spacing={4}>
          <Heading variant={'h6'}>Hospitals</Heading>

          <HStack spacing={4}>
            <Button
              onClick={sadcState.on}
              fontWeight={'normal'}
              color={AppColors.red}
              size={'sm'}
              variant={'unstyled'}
              _hover={{ textDecoration: 'underline' }}
            >
              Delete Account
            </Button>
            <Button
              variant="solid"
              outline={`2px solid ${AppColors.primary}`}
              color={AppColors.white}
              height={'40px'}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.primary,
              }}
              backgroundColor={AppColors.primary}
              px={6}
              size={'sm'}
              onClick={handleOpenAddHospital}
              rounded={'full'}
            >
              + Add Hospital
            </Button>
            <Button
              variant={'outline'}
              rounded={'full'}
              borderWidth={2}
              height={'40px'}
              px={5}
              fontWeight={'normal'}
              outline={`2px solid ${AppColors.secondary}`}
              color={AppColors.secondary}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.secondary,
                color: AppColors.white,
              }}
              onClick={() => {
                const dashboardRoute = replaceRoute(ROUTES.GET_ACCOUNT, {
                  accountId: accountId,
                });
                navigate(dashboardRoute);
              }}
              textColor={AppColors.secondary}
              size={'sm'}
            >
              Go to Dashboard
            </Button>
            {/* <span>Go To Dashboard</span> */}
            <Box>
              <InputGroup>
                <Input
                  height={'50px'}
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      getHospitalData();
                    }
                  }}
                  placeholder="Search..."
                />
                <InputRightElement
                  children={
                    <SearchIcon
                      marginTop={'10px'}
                      cursor={'pointer'}
                      onClick={e => {
                        getHospitalData();
                      }}
                    />
                  }
                />
              </InputGroup>
            </Box>
          </HStack>
        </HStack>

        <Box p={2} bgColor={'#fff'} borderRadius={'md'} w={'100%'}>
          <CQReactTable
            {...defaultReactTableProp}
            isLoading={editAccountContext.isFetchingHospitals}
            getCellProps={getCellProps}
            getHeaderCellProps={getHeaderCellProps}
            columns={hospitalColumns}
            data={editAccountContext.healthSystemHospitals}
            onAsc={header => {
              setOrdering(header);
            }}
            onDesc={header => {
              setOrdering(`-${header}`);
            }}
          />
        </Box>
      </VStack>
    );
  }

  const onDelete = async () => {
    await accountContext.deleteAccount(accountId);
    sadcState.off();
    navigate(ROUTES.ACCOUNTS);
  };

  const modalOnClose = () => {
    iuState.off();
    sadcState.off();
  };

  return (
    <CQContainer>
      <Heading
        variant={'h6'}
        fontSize={'x-large'}
        fontFamily={FONT_FAMILY.baiJamurjee}
        fontWeight={600}
        textTransform="capitalize"
        mb={5}
      >
        {location.state?.accountName || 'Health System'}
      </Heading>
      {renderBackToAccounts()}
      <SimpleGrid w={'100%'} columns={{ md: 3, sm: 1 }} spacing={4}>
        <GridItem colSpan={1}>
          <VStack w={'100%'} spacing={8}>
            <Flex
              boxShadow={'sm'}
              bg={'#fff'}
              alignItems={'flex-start'}
              w={'100%'}
              spacing={10}
              p={5}
              borderRadius={'md'}
            >
              {editAccountContext.isFetchingHealthSystem ? (
                <>
                  <Box
                    mt={1}
                    p={1}
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    <Stack gap={5}>
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                      <Skeleton height="40px" />
                    </Stack>
                  </Box>
                </>
              ) : (
                <EditHealthSystem
                  profileData={editAccountContext.healthSystemInfo}
                  accountId={accountId}
                  updateHsState={setHsState}
                />
              )}
            </Flex>

            <VStack
              boxShadow={'sm'}
              bgColor={'#fff'}
              alignItems={'flex-start'}
              w={'100%'}
              spacing={8}
              p={5}
              borderRadius={'md'}
            >
              <EditableLabel
                label={'Prefix'}
                value={editAccountContext.healthSystemInfo?.prefix}
                editType={null}
                editable={false}
              />
            </VStack>
          </VStack>
        </GridItem>

        <GridItem colSpan={2}>{renderHospitalSection()}</GridItem>
      </SimpleGrid>

      <DeleteHealthSystemAlert
        isOpen={showAccountDeleteConfirmation}
        isDeleteLoading={accountContext.isDepartmentLoading}
        onConfirm={onDelete}
        isUnderstood={isUnderstood}
        iuState={iuState}
        onClose={modalOnClose}
        healthSystemName={editAccountContext.healthSystemInfo?.name}
      />
      <AddHospitalForm
        healthSystemId={accountId}
        isOpen={hospitalFormVisible}
        onClose={hfvState.off}
        accountId={accountId}
        hsAddress={hsState}
        setHsState={setHsState}
      />
      {/* <AddHospitalForm
        editHospitalId={selectedHospitalId}
        isOpen={isOpenEditModal}
        onClose={() => {
          ioemState.off();
          setSelectedHospitalId();
        }}
        accountId={accountId}
      /> */}
    </CQContainer>
  );
};

export default withMultiProviders(
  [AccountsProvider, EditAccountProvider],
  EditAccountContainer
);
