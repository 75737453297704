import { API } from '../config/AppConfig';
import {
  AUDITOR_QUALITY_DROPDOWN,
  AUDITOR_QUALITY_REPORT,
  ERROR_DROPDOWN,
  GET_HEALTH_SYSTEM,
  GET_HOSPITAL,
  GET_SPECIALTY,
  WEEKDAYS_ACCURACY_CHART,
  WEEK_WISE_CQ_ACCURACY_ERROR,
  WEEK_WISE_ERROR,
  WEEK_WISE_ERROR_TREND,
} from '../constants/Endpoints';

class ReportRepository {
  constructor() {
    this.specialtyBasedAuditors = {};
  }

  async getWeekWiseErrorTrend(filterParams) {
    //chart - filterParams - graph
    // table - parameters
    const response = await API.get(WEEK_WISE_ERROR, {
      queryParams: filterParams,
    });
    return response;
  }

  async getWeekWiseError(filterParams) {
    //chart - filterParams - graph
    // table - parameters
    const response = await API.get(WEEK_WISE_ERROR_TREND, {
      queryParams: filterParams,
    });
    return response;
  }

  async getWeekdaysAccuracyChart(filterParams) {
    const response = await API.get(WEEKDAYS_ACCURACY_CHART, {
      queryParams: filterParams,
    });
    return response;
  }

  async getWeekWiseCQAccuracyError(filterParams) {
    const response = await API.get(WEEK_WISE_CQ_ACCURACY_ERROR, {
      queryParams: filterParams,
    });
    return response;
  }

  async getErrorDropdown() {
    const response = await API.get(ERROR_DROPDOWN);
    return response;
  }

  //Auditor Report api
  async getAuditorQualityDropdown() {
    const response = await API.get(AUDITOR_QUALITY_DROPDOWN);
    return response;
  }

  async getAuditorQualityReport(filterParams) {
    //filterParams - auditor
    const response = await API.get(AUDITOR_QUALITY_REPORT, {
      queryParams: filterParams,
    });
    return response;
  }

  async getHealthSystemDropdown(filterParams) {
    const response = await API.get(GET_HEALTH_SYSTEM, {
      queryParams: filterParams,
    });
    return response;
  }

  async getHospitalDropdown(filterParams) {
    const response = await API.get(GET_HOSPITAL, {
      queryParams: filterParams,
    });
    return response;
  }

  async getSpecialtyDropdown(filterParams) {
    const response = await API.get(GET_SPECIALTY, {
      queryParams: filterParams,
    });
    return response;
  }

  async updateHospitalOption(filterParams) {
    const response = await API.get(GET_HEALTH_SYSTEM, {
      queryParams: filterParams,
    });
    return response;
  }
  async updateSpecialtiesOptions(filterParams) {
    const response = await API.get(GET_HOSPITAL, {
      queryParams: filterParams,
    });
    return response;
  }
}

const reportRepository = new ReportRepository();
export default reportRepository;
