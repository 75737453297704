import { Box, HStack, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { TextInput } from '@laxmimanogna/code-quick-components';
import React, { useState } from 'react';
import { CustomTab } from '../../../components/CustomTab';
import AppColors from '../../../constants/AppColors';
import { SearchIcon, UrgentIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import AccountAllUpload from '../table/AccountAllUpload';
import AccountArchivedUpload from '../table/AccountArchivedUpload';
import AccountNewUpload from '../table/AccountNewUpload';
import AccountRebuttalUpload from '../table/AccountRebuttalUpload';

const AccountAuditTabs = [{
  title: 'ALL',
  component: AccountAllUpload,
},
{
  title: 'NEW UPLOADS',
  component: AccountNewUpload,
},
{
  title: 'ARCHIVED',
  component: AccountArchivedUpload,
},
{
  title: 'REBUTTALS',
  component: AccountRebuttalUpload,
}]

const AccountsChartSection = props => {
  // const { onSearch, queryParam } = props;

  const [searchTerm, setSearchTerm] = useState('');

  function handleSearch(e) {
    if (e.keyCode === 13) {
      setSearchTerm(e.target.value)
    }
  }

  return (
    <React.Fragment>
      <Text fontSize={'lg'} fontWeight={'bold'}>Charts</Text>

      <Box
        bgColor={'#fff'}
        p={4}
        mt={4}
        shadow={'lg'}
        borderRadius={'lg'}>
        <Tabs fontFamily={FONT_FAMILY.baiJamurjee}>
          <TabList>
            <HStack w={'100%'} justifyContent={'space-between'}>
              <HStack spacing={4} px={4}>
                {AccountAuditTabs.map((aa, index) => {
                  return (
                    <CustomTab
                      pb={4}
                      key={`account-audit-tab-${index}`}
                      color={AppColors.secondary}
                      fontFamily={FONT_FAMILY.baiJamurjee}
                    >
                      {aa.title}
                      {aa.title === 'URGENT' ? <UrgentIcon style={{ position: 'absolute', left: 80, top: 10 }} /> : null}
                    </CustomTab>
                  );
                })}
              </HStack>
              <Box>
                <Box>
                  <TextInput
                  height={"50px"}
                  mb={3}
                    borderRadius={'md'}
                    rightIconProps={{ zIndex: 0 }}
                    placeholder="Search"
                    rightIcon={<SearchIcon style={{marginTop:'10px'}} />}
                    onKeyDown={handleSearch}
                  />
                </Box>
              </Box>
            </HStack>
          </TabList>

          <TabPanels>
            {AccountAuditTabs.map((aa, index) => {
              return <TabPanel p={0} key={`account-audit-panel-${index}`}>
                <aa.component {...props} searchTerm={searchTerm} />
              </TabPanel>
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </React.Fragment>
  );
};

export default React.memo(AccountsChartSection);