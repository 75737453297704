import { Box, HStack, Image, Skeleton, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { MyAuditContext } from '../../../providers/MyAuditProvider';
import { replaceRoute } from '../../../utils/common.utils';

const RecentAudits = props => {
  // other hooks
  const navigate = useNavigate();

  // contexts-data's
  const { recentAudits, isRecentAuditLoading } = useContext(MyAuditContext);

  // render loaders
  if (isRecentAuditLoading) {
    return (
      <HStack mt={2} gap={4} overflow={'hidden'}>
        {[1, 2, 3, 4, 5].map(i => {
          return (
            <Box key={`${i}-loader`}>
              <Skeleton
                minWidth={250}
                key={`recent-audit-image-${i}`}
                h={140}
                borderRadius="2xl"
              />
              <Skeleton
                mt={2}
                w={10}
                key={`recent-audit-id-${i}`}
                h={4}
                borderRadius="sm"
              />
            </Box>
          );
        })}
      </HStack>
    );
  }

  // render - no-audit to show
  if (!recentAudits.length) {
    return (
      <Box
        height={'100%'}
        width={'100%'}
        display="flex"
        justifyContent="center"
        alignItems={'center'}
      >
        <Text
          fontSize={'md'}
          fontFamily={FONT_FAMILY.ptSans}
          fontWeight={'bold'}
        >
          No audits to show
        </Text>
      </Box>
    );
  }

  const onAuditSheetClick = uploadId => {
    //  naviagting to auditSheetConatainer
    const auditSheetContainerRoute = replaceRoute(ROUTES.AUDIT_SHEET, {
      uploadId,
    });
    navigate(auditSheetContainerRoute);
  };

  // render-sheet
  const renderSheet = () => {
    return recentAudits.map((chart, index) => {
      return (
        <Box key={`${index}-charts`}>
          <Image
            position={'relative'}
            src={chart.recent_audit_snaps}
            minWidth="250"
            height="133"
            cursor="pointer"
            borderRadius="10px"
            border="2px solid #fff"
            boxShadow={'0 0 10px 0 #e4e4e4'}
            onClick={() => {
              onAuditSheetClick(chart.id);
            }}
          />
          <Text
            mt={5}
            pb={3}
            fontFamily={FONT_FAMILY.baiJamurjee}
            color={AppColors.secondary}
            fontSize="18px"
          >
            {chart.chart_id}
          </Text>
        </Box>
      );
    });
  };

  // render audit-sheets
  return (
    <HStack justifyContent={'flex-start'} gap={2} mt={2} overflowX="hidden">
      {renderSheet()}
    </HStack>
  );
};

export default RecentAudits;
