/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { RebuttalIcon, UrgentIcon } from '../../../../constants/IconData';
import { AuditContext } from '../../../../providers/AuditProvider';
import { createColumnHelper } from '@tanstack/react-table';
import AppColors from '../../../../constants/AppColors';
import { toTitleCase } from '../../../../utils/common.utils';
import {
  ASSIGNED_AUDITS_ORDERING,
  getAuditStatus,
  REBUTTAL_STATUS,
} from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import {
  defaultReactTableProp,
  getRowProps,
  paginationProps,
} from '../../../../utils/assigned_audit.util';
import { format, formatDistanceStrict } from 'date-fns';

import '../style.css';
import { CQReactTable } from '@laxmimanogna/code-quick-components';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../constants/Routes';
import FileViewAction from '../FileViewAction';

const columnHelper = createColumnHelper();

const AuditedTable = () => {
  const auditContext = useContext(AuditContext);
  const [ordering, setOrdering] = useState('');

  // media query
  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  const navigate = useNavigate();
  const { specialties, isUpdating, getAudited, filterParams } = auditContext;

  useEffect(() => {
    let searching = !!(filterParams.searchText && filterParams.searchText.trim());
    getAudited.loadAPI("Assign Audit", "Audited Audit", searching);
  }, [filterParams, getAudited.showOrdering]);

  useEffect(() => {
    if (ASSIGNED_AUDITS_ORDERING.includes(ordering)) {
      getAudited.setParams({ ordering });
    }
  }, [ordering]);

  const renderActionComponent = auditUpload => {
    return (
      <HStack>
        <Button
          px={12}
          size={'sm'}
          borderRadius={'full'}
          bgColor={AppColors.secondary}
          color={'white'}
          width={'150px'}
          height={'40px'}
          outline={`2px`}
          _hover={{
            transition: 'all .3s ease',
            bgColor: AppColors.white,
            color: AppColors.secondary,
            outline: `2px solid ${AppColors.secondary}`,
          }}
          onClick={() => {
            navigate(ROUTES.AUDIT_SHEET.replace(':uploadId', auditUpload.id));
          }}
        >
          Review
        </Button>
        <FileViewAction auditUpload={auditUpload} />
      </HStack>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: info => {
          return (
            <Flex
              textAlign={'left'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            // gap={5}
            >
              <Box>
                {REBUTTAL_STATUS.includes(info.row.original?.status) ? (
                  <RebuttalIcon
                    style={{ height: 20, width: 20, marginRight: 10 }}
                  />
                ) : info.row.original?.urgent ? (
                  <UrgentIcon
                    style={{ height: 10, width: 10, marginRight: 20 }}
                  />
                ) : (
                  <Box ml={2} />
                )}
              </Box>
            </Flex>
          );
        },
      }),
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'chart_id',
        cell: info => {
          return (
            <Flex
              textAlign={'left'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            // gap={5}
            >
              <Text>{info.renderValue()}</Text>
            </Flex>
          );
        },
      }),
      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => <span>Chart Upload</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor(row => row.audited_date, {
        id: 'audited_date',
        header: () => <span>Audit Upload</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor(p => p.specialties?.name, {
        header: () => 'Specialties',
        id: 'specialty__name',
        cell: info => {
          const specialties = info.row.original.specialties?.name;

          if (!specialties) {
            return 'N/A';
          }
          return <Text lineHeight={'27px'}> {specialties === 'ent' ? 'ENT' : toTitleCase(specialties)}</Text>
        },
      }),
      columnHelper.accessor(p => p.assigned_auditor?.first_name, {
        header: () => 'Auditor',
        id: 'auditor',
        cell: info => {
          const cellValue = info.row.original.assigned_auditor;
          if (!cellValue) {
            return 'N/A';
          }

          return <Text lineHeight={'27px'}>{`${cellValue.first_name} ${cellValue.last_name}`}</Text>
        },
      }),
      columnHelper.accessor(p => p.assigned_qa?.first_name, {
        header: () => 'QA',
        id: 'qa',
        cell: info => {
          const cellValue = info.row.original.assigned_qa;
          if (!cellValue) {
            return 'N/A';
          }

          return `${cellValue.first_name} ${cellValue.last_name}`;
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => <Text lineHeight={'27px'}>{getAuditStatus(info.row.original?.status)}</Text>,
      }),
      columnHelper.accessor('updated_date', {
        header: () => 'Last Updated',
        id: 'updated_date',
        cell: info => {
          let updatedAt = info.getValue();

          updatedAt = updatedAt
            ? formatDistanceStrict(new Date(updatedAt), new Date(), {
              addSuffix: true,
            })
            : 'N/A';

          return (
            <Text>
              {updatedAt !== 'N/A' ? toTitleCase(updatedAt) : updatedAt}
            </Text>
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(info.row.original);
        },
      }),
    ],
    [specialties]
  );

  function handlePreviousPage() {
    auditContext.getAudited.loadPrevious("Assign Audit", "Audited Audit");
  }

  function handleNextPage() {
    auditContext.getAudited.loadNext("Assign Audit", "Audited Audit");
  }

  function handleSizeChange(size) {
    auditContext.getAudited("Assign Audit", "Audited Audit", size );
  }

  return (
    <Box
      mt={3}
      pointerEvents={isUpdating ? 'none' : 'inherit'}
      overflowX={canScroll ? 'scroll' : 'inherit'}
    >
      <Box>
        <CQReactTable
          manualSortBy={true}
          isLoading={auditContext.isFetchingAudit}
          data={auditContext.auditedList}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(getAudited)}
          getRowProps={r => getRowProps(r, { backgroundColor: '#F7F7FA' })}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
    </Box>
  );
};
export default AuditedTable;
