/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import TeamProvider, { TeamContext } from '../../providers/TeamProvider';
import { Text } from '@chakra-ui/react';
import TeamForm from './components/TeamForm';
import { CREATE_MODE } from '../../constants/constants';

const TeamFormContainer = props => {
  const { getSpecialties } = useContext(TeamContext);
  useEffect(() => {
    const getSpecialtiesData = async () => {
      await getSpecialties();
    };
    getSpecialtiesData();
  }, []);
  return (
    <>
      <CQContainer py={30} px={30} pb={20}>
        <Text
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontWeight="bold"
          fontSize={22}
        >
          Add New Team
        </Text>
        <Box
          bg="white"
          p={4}
          pb={20}
          w="100%"
          h="150%"
          borderRadius="md"
          ml={0}
          mt={3}
        >
          <TeamForm mode={CREATE_MODE} />
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(TeamProvider, TeamFormContainer);
