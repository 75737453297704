/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import React, { useEffect, useState } from 'react';
import AppColors from '../../../../constants/AppColors';
import { TriangleIcon, TriangleRedIcon } from '../../../../constants/IconData';
import teamRepository from '../../../../repositories/TeamRepository';
import AuditAccuracyDatePicker from '../date_picker_modal/AuditAccuracyDatePicker';
import CQLineChart from './CQLineChart';
import { format, subDays } from 'date-fns';

const MetricSection = props => {
  const { queryParam = {} } = props;

    // constants
    const current = new Date();

    const endDate = subDays(current, 30);
  
    const previousStartDate = subDays(endDate, 30);
  
    const previousEndDate = subDays(previousStartDate, 30);

  // booleans
  const [isFetching, ifState] = useBoolean(false);

  // state
  const [metric, setMetric] = useState(null);

  const [filterParams, setFilterParams] = useState({
    start_date: format(current,'yyyy-MM-dd'), 
    end_date: format(endDate,'yyyy-MM-dd'), 
    previous_start_date: format(previousStartDate,'yyyy-MM-dd'), 
    previous_end_date:format(previousEndDate,'yyyy-MM-dd'), 
  });


  useEffect(() => {
    fetchMetric();
  }, [queryParam, filterParams]);

  function getFilterParams() {
    return {
      ...filterParams,
      ...queryParam,
    };
  }

  async function fetchMetric() {
    try {
      ifState.on();
      const _filterParams = getFilterParams();
      const metricResponse = await teamRepository.getTeamAverageAuditHours(
        _filterParams
      );
      setMetric(metricResponse);
    } catch (e) {
      // unable to load audit accuracy
    } finally {
      ifState.off();
    }
  }

  function renderMainContent() {
    return (
      <React.Fragment>
        <ScaleFade initialScale={0.1} in={true}>
          <HStack justifyContent={'space-between'}>
            <Text
              color={AppColors.primary}
              alignItems={'start'}
              fontSize={'md'}
            >
              Average hours to complete over time
            </Text>
            <Box position="relative">
              <AuditAccuracyDatePicker
                isLoading={isFetching}
                onFilterChange={setFilterParams}
                filterParams={filterParams}
              />
            </Box>
          </HStack>
          <Box my={4}>
            <CQLineChart chartData={metric.average_hours} />
          </Box>

          <Box mt={8} mx={8}>
            <HStack>
              {metric.previous_diff >= 0 ? (
                <TriangleIcon />
              ) : (
                <TriangleRedIcon />
              )}
              <Text fontSize={'xs'}>
                {metric.previous_diff >= 0 && '+'} {metric.previous_diff}
              </Text>
            </HStack>
            <Text fontSize={'5xl'}>{metric.current_avg}</Text>
          </Box>
        </ScaleFade>
      </React.Fragment>
    );
  }

  function renderBroken() {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  }

  function renderLoader() {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }

  if (isFetching) {
    return renderLoader();
  } else if (!metric) {
    return renderBroken();
  } else {
    return renderMainContent();
  }
};

export default React.memo(MetricSection);
