import { API } from '../config/AppConfig';
import {
  USER_LOOKUP,
  GET_SPECIALTIES,
  GET_ALL_USER_API,
  USER_CREATE_API,
  USER_UPDATE_API,
  GET_USER_API,
  DELETE_USER,
  AVERAGE_AUDIT_HOURS,
  CHART_ACCURACY,
  TEAMS,
} from '../constants/Endpoints';

class UserManagementRepository {
  async updateCurrentUser(params) {
    const response = await API.put(USER_LOOKUP, { body: params });
    return response;
  }
  async getSpecialties() {
    const response = await API.get(GET_SPECIALTIES);
    return response;
  }

  async getUsers() {
    const response = await API.get(GET_ALL_USER_API);
    return response;
  }

  async createUser(params) {
    const response = await API.post(USER_CREATE_API, { body: params });
    return response;
  }
  async updateUser(userId, params) {
    const response = await API.put(USER_UPDATE_API, {
      pathParams: { userId },
      body: params,
    });
    return response;
  }
  async getUser(userId) {
    const response = await API.get(GET_USER_API, { pathParams: { userId } });
    return response;
  }
  async deleteUser(userId) {
    const response = await API.delete(DELETE_USER, {
      pathParams: { userId },
    });
    return response;
  }

  async getChartAccuracy(filterParams) {
    const response = await API.get(CHART_ACCURACY, {
      queryParams: filterParams,
    });
    return response;
  }

  async getAverageAuditHours(filterParams) {
    const response = await API.get(AVERAGE_AUDIT_HOURS, {
      queryParams: filterParams,
    });
    return response;
  }

  async getTeams() {
    const response = await API.get(TEAMS);
    return response;
  }
}

const userManagementRepository = new UserManagementRepository();
export default userManagementRepository;
