/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import AppColors from '../../../constants/AppColors';
import { BackArrowIcon } from '../../../constants/IconData';
import { EditIcon } from '@chakra-ui/icons';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import AccountsProvider, {
  AccountContext,
} from '../../../providers/AccountsProvider';
import { getHeaderCellProps } from '../../../utils/assigned_audit.util';
import { replaceRoute, toTitleCase } from '../../../utils/common.utils';
import { DeleteModal } from '../components/DeleteModal';
import AddPhysicianForm from '../forms/AddPhysicianForm';
import ProfileSection from './components/ProfileSection';
import RightContainer from './components/RightContainer';

const columnHelper = createColumnHelper();

const DepartmentProfileViewContainer = props => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const accountsContext = useContext(AccountContext);

  const { accountId, hospitalId, departmentId } = params;

  const [isDeleteModalOpen, idmoState] = useBoolean(false);
  const [physionFormVisible, pfvState] = useBoolean(false);
  const [providerFilters, setProviderFilters] = useState({});

  const [isChecked, icState] = useBoolean(false);

  const healthSystemRoute = replaceRoute(ROUTES.EDIT_ACCOUNT, {
    accountId: params.accountId,
  });
  const hospitalRoute = replaceRoute(ROUTES.GET_HOSPITAL, {
    hospitalId: params.hospitalId,
    accountId: params.accountId,
  });

  const [breadcrumbData, setBreadcrumbData] = useState({
    accountName: toTitleCase(location.state?.accountName) || '',
    hospitalName: toTitleCase(location.state?.hospitalName) || '',
    departmentName: toTitleCase(location.state?.departmentName) || '',
  });

  useEffect(() => {
    if (!location.state?.accountName || !location.state?.hospitalName || !location.state?.departmentName) {
      const fetchData = async () => {
        try {
          const accountData = await accountsContext.getAccount(accountId);
          const hospitalData = await accountsContext.getHospital(hospitalId);
          const departmentData = await accountsContext.getDepartment(departmentId);

          setBreadcrumbData({
            accountName: toTitleCase(accountData.name),
            hospitalName: toTitleCase(hospitalData.name),
            departmentName: toTitleCase(departmentData.name),
          });
        } catch (error) {
          console.error('Error fetching breadcrumb data:', error);
        }
      };

      fetchData();
    }
  }, [location.state, accountId, hospitalId, departmentId]);

  useEffect(() => {
    accountsContext.getDepartment(departmentId);
  }, [departmentId]);

  useEffect(() => {
    // fetchPhysicians();
    if (!accountsContext.ehrOptions.length) {
      accountsContext.fetchEHROptions();
    }
    if (!accountsContext.insuranceOptions.length) {
      accountsContext.fetchInsuranceOptions();
    }
    if (hospitalId) {
      accountsContext.getDepartmentDropdown(hospitalId);
    }
  }, []);

  useEffect(() => {
    const syncAndFetchProviders = async () => {
      if (departmentId) {
        await accountsContext.syncProvidersWithMoodle(departmentId);  
        accountsContext.getProvidersByDeparment(departmentId, providerFilters);  
      }
    };
    syncAndFetchProviders();
  }, [departmentId, providerFilters])

  // department columns
  const physicianColumns = useMemo(() => {
    return [
      columnHelper.accessor('first_name', {
        header: () => 'Provider Name',
        enableSorting: true,
        cell: info => {
          const first_name = toTitleCase(info.row.original?.first_name);
          const last_name = toTitleCase(info.row.original?.last_name);
          return (
            <Text fontSize="sm" fontWeight={'bold'}>
              {`${first_name} ${last_name}`}
            </Text>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        enableSorting: true,
        cell: info => {
          const is_active = info.row.original?.is_active;
          return (
            <Text
              fontSize="sm"
              color={is_active ? 'black' : AppColors.darkGray}
            >
              {is_active ? 'Active' : 'Inactive'}
            </Text>
          );
        },
      }),
      columnHelper.accessor('moodle_status', {
        header: () => 'Moodle Account',
        enableSorting: true,
        cell: info => {
          const is_active = info.row.original?.moodle_user_id ;
          return (
            <Text
              fontSize="sm"
              color={is_active ? 'black' : AppColors.darkGray}
            >
              {is_active ? 'Yes' : 'No'}
            </Text>
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const is_active = info.row.original?.is_active;
          const id = info.row.original?.id;
          return (
            <Button
              size={'sm'}
              px={14}
              fontSize="sm"
              rightIcon={<EditIcon />}
              textColor={'#fff'}
              borderRadius={'full'}
              color={is_active ? 'white' : AppColors.darkGray}
              // listProviderData
              outline={
                is_active
                  ? `2px solid ${AppColors.secondary}`
                  : `2px solid ${AppColors.offWhite}`
              }
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
              }}
              bg={is_active ? AppColors.secondary : AppColors.offWhite}
              onClick={() => {
                const editPhysicianRoute = replaceRoute(ROUTES.EDIT_PHYSICIAN, {
                  physicianId: id,
                  accountId,
                  hospitalId,
                  departmentId,
                });
                navigate(editPhysicianRoute, {
                  state: {
                    ...location.state,
                    providerName:
                      `${info.row.original?.first_name} ${info.row.original?.last_name}` ||
                      'Provider',
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      }),
    ];
  }, [accountsContext.providerData]);

  function _getHeaderCellProps(header) {
    return {
      ...getHeaderCellProps(header),
      justifyContent: header.id === 'name' ? 'flex-start' : 'center',
    };
  }

  function _getCellProps(row, cell) {
    const isName = cell.column.id === 'name';
    const textAlign = isName ? 'left' : 'center';
    const textTransform = isName ? 'capitalize' : 'none';

    return {
      fontSize: 'md',
      textAlign,
      textTransform,
      style: {
        fontSize: 'sm',
        // color: STATUS[row.original.status].color,
      },
    };
  }

  function renderBreadCrumb() {
    const { accountName, hospitalName, departmentName } = breadcrumbData;
    return (
      <Breadcrumb mb={5}>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              navigate(healthSystemRoute, {
                state: {...location.state, accountName },
              });
            }}
          >
            <Heading
              variant={'h6'}
              fontSize={'x-large'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={600}
              textTransform="capitalize"
            >
              {' '}
              {accountName}
            </Heading>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Text fontFamily={FONT_FAMILY.baiJamurjee}>
            <BreadcrumbLink
              onClick={() => {
                navigate(hospitalRoute, {
                  state: { ...location.state, hospitalName },
                });
              }}
            >
              {hospitalName}
            </BreadcrumbLink>
          </Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Text
            fontFamily={FONT_FAMILY.baiJamurjee}
            color={AppColors.secondary}
            fontWeight={'semibold'}
            textTransform={'capitalize'}
          >
            {departmentName}
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack>
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
              />
              <Text
                fontSize={'md'}
                fontWeight="bold"
                size={'md'}
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
      </HStack>
    );
  }

  function renderDepartmentInfo() {
    return (
      <GridItem colSpan={1}>
        <ProfileSection
          profileData={accountsContext.department}
          hospitalId={hospitalId}
        />
      </GridItem>
    );
  }
  function handleOpenPhysician() {
    pfvState.on();
  }

  function renderMiddleContent() {
    return (
      <HStack gap={2}>
        <Text
          color={AppColors.red}
          fontWeight="sm"
          cursor={'pointer'}
          onClick={() => idmoState.on()}
          _hover={{ textDecoration: 'underline' }}
        >
          {' '}
          Delete Department
        </Text>
        <Button
          borderRadius={'full'}
          bg={AppColors.primary}
          color={AppColors.white}
          fontWeight={'normal'}
          outline={`2px solid ${AppColors.primary}`}
          _hover={{
            transition: 'all .1s ease',
            bgColor: AppColors.white,
            color: AppColors.primary,
          }}
          onClick={handleOpenPhysician}
        >
          + Add Provider
        </Button>
        <Button
          borderWidth={1}
          bg={'white'}
          outline={`2px solid ${AppColors.secondary}`}
          _hover={{
            transition: 'all .1s ease',
            bgColor: AppColors.secondary,
            color: AppColors.white,
          }}
          fontWeight={'normal'}
          color={AppColors.secondary}
          borderRadius={'full'}
          onClick={() => {
            navigate(
              replaceRoute(ROUTES.GET_ACCOUNT_DEPARTMENT, {
                accountId,
                hospitalId,
                departmentId,
              }),
              { state: location.state }
            );
          }}
        >
          Go to Dashboard
        </Button>
      </HStack>
    );
  }

  function renderPhysicians() {
    return (
      <RightContainer
        sectionTitle={'Providers'}
        columns={physicianColumns}
        // data={physicians}
        data={accountsContext.providerData}
        isLoading={accountsContext.isFetchingPhysicians || accountsContext.moodleSyncLoading}
        tableProps={{
          getCellProps: _getCellProps,
          getHeaderCellProps: _getHeaderCellProps,
        }}
        renderMiddleContent={renderMiddleContent}
        onSearch={value => {
          setProviderFilters(prev => ({ ...prev, search: value }));
        }}
      />
    );
  }

  function renderTable() {
    return (
      <GridItem colSpan={2}>
        <VStack align={'stretch'}>{renderPhysicians()}</VStack>
      </GridItem>
    );
  }

  function renderMainContent() {
    return (
      <Box width={'100%'}>
        <Grid
          // templateColumns="repeat(3, 1fr)"
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={4}
          width={'100%'}
        >
          {renderDepartmentInfo()}
          {renderTable()}
        </Grid>
      </Box>
    );
  }

  return (
    <CQContainer>
      {renderBreadCrumb()}
      {renderBackToAccounts()}
      {renderMainContent()}
      <DeleteModal
        isDeleteLoading={accountsContext.isDepartmentLoading}
        onDelete={async () => {
          await accountsContext.onDeleteDepartment(departmentId);
          idmoState.off();
          //navigate to previous page
          navigate(ROUTES.ACCOUNTS);
        }}
        isChecked={isChecked}
        icState={icState}
        isDeleteModalOpen={isDeleteModalOpen}
        closeDeleteModal={() => {
          icState.off();
          idmoState.off();
        }}
        name={accountsContext.department?.name}
        condition={
          'I understand this will remove all physicians related to this department.'
        }
      />
      <AddPhysicianForm
        isOpen={physionFormVisible}
        onClose={pfvState.off}
        departmentId={departmentId}
        setListProviderData={accountsContext.setProviderData}
        listProviderData={accountsContext.providerData}
      />
    </CQContainer>
  );
};

export default withProvider(AccountsProvider, DepartmentProfileViewContainer);
