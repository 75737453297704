import React, { useContext } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { TeamContext } from '../../../providers/TeamProvider';
import AppColors from '../../../constants/AppColors';
import '../../../components/tableStyles.css';
import { FONT_FAMILY } from '../../../constants/Theme';

import TeamTable from './table/TeamTable';
import { successToast } from '../../../components/successToast';

const Team = () => {
  const toast = useToastr();

  const [isOpen, setIsOpen] = React.useState(false);
  const [currentTeam, setCurrentTeam] = React.useState(false);

  const teamContext = useContext(TeamContext);
  const { isMemberListLoading } = teamContext;

  const deleteTeam = () => {
    teamContext
      .deleteTeam(currentTeam)
      .then(() => {
        const toastProps = {
          header: 'Success!',
          description: `Team is deleted`,
        };
        successToast(toast, toastProps);
      })
      .finally(() => setIsOpen(false));
  };

  return (
    <Box>
      <TeamTable setCurrentTeam={setCurrentTeam} setIsOpen={setIsOpen} />
      <Modal isOpen={isOpen} size={'md'} onClose={setIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent p={10} style={{ alignItems: 'center' }}>
          <ModalHeader>
            <Text
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="bold"
              fontSize={'xl'}
              color={AppColors.secondary}
            >
              Are you sure you want to delete this team?
            </Text>
          </ModalHeader>
          <ModalBody mb={2}>
            <HStack spacing={5}>
              <Button
                // variant="outline"
                color={AppColors.secondary}
                border={`2px solid ${AppColors.secondary}`}
                height={'50px'}
                bg={'#ffff'}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.secondary,
                  color: AppColors.white,
                  // outline: `2px solid ${AppColors.secondary}`,
                }}
                style={{
                  paddingInline: '25px',
                  // outlineColor: AppColors.secondary,
                }}
                fontSize="sm"
                borderRadius="3xl"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                No, Keep Team
              </Button>
              <Button
                borderRadius="3xl"
                color={AppColors.red}
                fontSize="sm"
                bg={AppColors.white}
                style={{ paddingInline: '35px' }}
                isLoading={isMemberListLoading}
                loadingText={'Deleting'}
                spinnerPlacement={'end'}
                height={'50px'}
                border={`2px solid ${AppColors.red}`}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.red,
                  color: AppColors.white,
                  border: `2px solid ${AppColors.red}`,
                }}
                onClick={() => {
                  deleteTeam();
                }}
              >
                Yes, Delete
              </Button>
            </HStack>
          </ModalBody>
          <Center mt={3} mb={4}>
            <Text fontSize="xs">Note: this action can not be undone</Text>
          </Center>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Team;
