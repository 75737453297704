import AppColors from '../constants/AppColors';
import STATUS_FILTER from '../constants/Filter';
import {
  DownIcon,
  LeftEnableIcon,
  LeftIcon,
  RightEnableIcon,
  RightIcon,
  SortIcon,
  UpIcon,
} from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';
import { getRowObject } from '../pages/my_audits/components/MyAuditsComponents';

export const defaultReactTableProp = {
  renderSortUpIcon,
  renderSortIcon,
  renderSortDownIcon,
  getCellProps,
  getHeaderCellProps,
  getRowProps,

  RightIcon: <RightIcon />,
  LeftIcon: <LeftIcon />,
};
export function paginationProps(pagination) {
    const canGoBack = pagination?.currentPage !== 1;
    const canGoNext = pagination?.totalPage !== pagination.currentPage;
  return {
    total: pagination.totalCount,
    pageCount: pagination.totalPage,
    size: pagination.showSize,
    currentPage: pagination.currentPage,
    LeftIcon: canGoBack ? <LeftEnableIcon /> : <LeftIcon />,
    RightIcon: canGoNext ? <RightEnableIcon /> : <RightIcon />
  };
}

export function renderSortUpIcon() {
  return (
    <UpIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortDownIcon() {
  return (
    <DownIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortIcon() {
  return (
    <SortIcon
      style={{
        width: 15,
        height: 15,
        marginLeft: 1,
      }}
    />
  );
}

export function getHeaderCellProps(header) {
  const startingHeaderId = ['my-audit-chart_id'];

  return {
    textTransform: 'none',
    fontFamily: FONT_FAMILY.ptSans,
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'black',
    justifyContent: startingHeaderId.includes(header.id)
      ? 'flex-start'
      : 'center',
  };
}

export function getRowProps(row, options = { ignoreUrgent: false }) {
  const isUrgent = row.original?.urgent;
  const rowObject = getRowObject(row.original.status);
  const backgroundColor = isUrgent ? AppColors.lightDanger : rowObject.bgColor;

  return {
    style: { backgroundColor },
  };
}

export function getCellProps(cell) {
  const status = cell.original?.status;
  let fontWeight = 'normal';

  if (
    status === STATUS_FILTER.awaitingAudit ||
    status === STATUS_FILTER.clientRebuttal ||
    status === STATUS_FILTER.qaRebuttal
  ) {
    fontWeight = 'bold';
  }
  const isUrgent = cell.original?.urgent;
  if (isUrgent) {
    fontWeight = 'bold';
  }

  return {
    style: {
      color: AppColors.secondary,
      fontWeight,
      fontFamily: FONT_FAMILY.ptSans,
      fontSize: '16px',
      textAlign: 'center',
    },
  };
}
