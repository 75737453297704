import AppColors from "../../../constants/AppColors";

const getRowObject = (status) => {
    let buttonObj
    switch (status) {
        case 'AWAITING REVIEW':
            buttonObj = { data: 'View', color: AppColors.primary, bgColor: AppColors.loginFormBg }
            break;
        case 'AWAITING AUDIT':
            buttonObj = { data: 'Begin Audit', color: AppColors.primary, bgColor: AppColors.loginFormBg, }
            break
        case 'ON HOLD':
            buttonObj = { data: 'Begin Review', color: AppColors.primary, bgColor: AppColors.loginFormBg }
            break
        case 'IN PROGRESS':
            buttonObj = { data: 'Edit Audit', color: AppColors.secondary, bgColor: AppColors.loginFormBg }
            break
        case 'IN REVIEW':
            buttonObj = { data: 'Begin Review', color: AppColors.primary, bgColor: AppColors.loginFormBg }
            break
        case 'ARCHIVED':
            buttonObj = { data: 'View', color: AppColors.secondary, bgColor: AppColors.loginFormBg }
            break
        case 'AWAITING ASSIGNMENT':
            buttonObj = { data: 'View', color: AppColors.primary, bgColor: 'white' }
            break
        case 'QA REBUTTAL':
            buttonObj = { data: 'Edit Audit', color: AppColors.danger, bgColor: AppColors.lightDanger }
            break
        case 'CLIENT REBUTTAL':
            buttonObj = { data: 'View', color: AppColors.danger, bgColor: AppColors.lightDanger }
            break
        default:
            buttonObj = { data: 'View', color: AppColors.secondary, bgColor: 'white' }

    }
    return buttonObj
}
export { getRowObject }