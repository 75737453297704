import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';
import {
  CQSelect,
  useBoolean,
} from '@laxmimanogna/code-quick-components';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import CQContainer from '../../../components/layout/CQContainer';
import DeleteConfirmationPopUp from '../../../components/modal/delete_confirmation_modal/DeleteConfirmationPopUp';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { TeamContext } from '../../../providers/TeamProvider';
import { toTitleCase } from '../../../utils/common.utils';

function EditTeamUser(props) {
  const teamContext = useContext(TeamContext);
  const { register } = useForm({
    defaultValues: {
      firstName: props?.teamsUser?.first_name,
      lastName: props?.teamsUser?.last_name,
      email: props?.teamsUser?.email,
      role: props?.teamsUser?.role,
    },
  });
  // const options =
  //     props?.teamsUser?.teams?.length && props?.teamsUser?.teams.map((opt) => {
  //     return
  //     })

  const deleteUser = async () => {
    await teamContext.deleteTeamsUser(props?.teamsUser?.id);
  };
  const [openDeleteConfirmationModal, odcmState] = useBoolean(false);
  return (
    <>
      <CQContainer ml={0} mr={0}>
        <form>
          <HStack>
            <Heading
              variant={'h4'}
              fontSize={'x-large'}
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight={'semibold'}
              marginLeft={4}
              marginTop={3}
            >
              {toTitleCase(
                `${props?.teamsUser?.first_name} ${props?.teamsUser?.last_name}`
              )}
            </Heading>
          </HStack>
          <Grid templateColumns="repeat(2, 1fr)" gap={5} w={'80%'}>
            <Box p={5}>
              <Text marginBottom={3} fontWeight={'semibold'}>
                First Name
              </Text>
              <Input
                type={'text'}
                id="firstName"
                name="firstName"
                size="md"
                {...register('firstName')}
              />
            </Box>
            <Box p={5}>
              <Text marginBottom={3} fontWeight={'semibold'}>
                Last Name
              </Text>
              <Input
                name="LastName"
                id="lastName"
                type={'text'}
                size="md"
                {...register('lastName')}
              />
            </Box>
          </Grid>

          {/* form-2 */}
          <Grid templateColumns="repeat(2, 1fr)" gap={5} w={'80%'}>
            <Box p={5}>
              <Text marginBottom={3} fontWeight={'semibold'}>
                Email
              </Text>
              <Input
                {...register('email')}
                type={'email'}
                name="email"
                id="email"
                size="md"
              />
            </Box>
            <Box p={5}>
              <Text marginBottom={3} fontWeight={'semibold'}>
                Role
              </Text>
              <Input {...register('role')} size="md" />
            </Box>
          </Grid>

          {/* form -3 */}
          <Grid templateColumns="repeat(2, 1fr)" gap={5} w={'80%'}>
            <Box p={5}>
              <Text marginBottom={3} fontWeight={'semibold'}>
                Assign to team (optional)
              </Text>
              <CQSelect options={props?.teamsUser?.teams} />
            </Box>
            <Box p={5}>
              <Text marginBottom={3} fontWeight={'semibold'}>
                Specialties
              </Text>
              <HStack flexWrap={'wrap'}>
                {props?.teamsUser?.specialties?.length &&
                  props?.teamsUser?.specialties.map(s => {
                    return <Text>{s?.name},</Text>;
                  })}
                <Text
                  fontWeight={'bold'}
                  color={AppColors.secondary}
                  cursor={'pointer'}
                >
                  | Change
                </Text>
              </HStack>
            </Box>
          </Grid>

          {/* foter */}
          <Grid templateColumns="repeat(2, 1fr)" gap={5} w={'95%'}>
            <Box p={5}>
              <Button
                marginRight={3}
                h={10}
                variant="outline"
                color={AppColors.secondary}
                style={{
                  paddingInline: '35px',
                  outlineColor: AppColors.secondary,
                }}
                fontSize="sm"
                borderRadius="3xl"
              >
                Cancel
              </Button>
              <Button
                borderRadius="3xl"
                color="white"
                fontSize="sm"
                bg={AppColors.primary}
                style={{ paddingInline: '35px', height: '50px' }}
                type="submit"
                _hover={{
                  background: AppColors.primary,
                }}
              >
                Save Changes
              </Button>
            </Box>
            <Box p={5}>
              <HStack alignItems={'center'} justifyContent={'end'}>
                <Text
                  onClick={() => {
                    odcmState.on();
                  }}
                  cursor={'pointer'}
                  color={AppColors.orange}
                >
                  Delete User
                </Text>
                <Text textDecoration={'underline'} color={AppColors.secondary}>
                  Send Password Reset Link
                </Text>
              </HStack>
            </Box>
          </Grid>
        </form>

        <DeleteConfirmationPopUp
          isOpen={openDeleteConfirmationModal}
          size={'lg'}
          onClose={() => odcmState.off()}
          title={`Are you sure you want to delete User?`}
          closebutton={{
            children: 'Cancel',
            onClick: () => {
              odcmState.off();
            },
          }}
          submitButton={{
            children: 'Yes,Delete',
            onClick: () => {
              deleteUser();
            },
            isLoading: teamContext.isTeamsUserDeleting,
            loadingText: 'Deleting',
            spinnerPlacement: 'end',
          }}
          text={'Note:this action cannot be undone'}
        />
      </CQContainer>
    </>
  );
}

export default EditTeamUser;
