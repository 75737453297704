import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Circle,
  Center,
  Button,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import Proptypes from 'prop-types';
import CQTextArea from '../../form/TextArea/CQTextArea';

function AuditModal(props) {
  return (
    <>
      <Modal
        size={'xl'}
        isOpen={props.isOpen}
        onClose={props.onClose}
        isCentered
      >
        <ModalOverlay style={{ backgroundColor: AppColors.lightSkyBlue }} />
        <ModalContent maxW={886}>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-59}
            right={-59}
          >
            <Circle>
              <ModalCloseButton
                size={'xl'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalHeader mt={5} mb={5}>
            <Center fontFamily={FONT_FAMILY.baiJamurjee}>
              <Text fontSize={'24px'}>{props.title}</Text>
            </Center>
          </ModalHeader>
          <ModalBody>
            <Center>
              <CQTextArea
                text={props.text}
                keyText={props.keyText}
                placeholder={props.placeholder}
                height={props.height}
                width={props.width}
                onChange={props.onChange}
                setAlert={props.setAlert}
              />
            </Center>
          </ModalBody>
          <ModalFooter mb={10} mr={10}>
            <Button
            
              color={AppColors.white}
              backgroundColor={AppColors.primary}
              variant={props.variant}
              _hover={{
                bg:AppColors.secondary,
                color:AppColors.white
              }}
              borderRadius={25}
              style={{ width: '122px', height: '50px', marginRight: '10px' }}
              {...props.submitButton}
            />
            <Button
              color={AppColors.secondary}
              variant={'solid'}
              _hover={{
                bg:AppColors.secondary,
                color:AppColors.white
              }}
              backgroundColor={AppColors.white}
              borderRadius={25}
              border={`1px solid ${AppColors.secondary}`}
              style={{ width: '122px', height: '50px' }}
              {...props.cancelButton}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

AuditModal.proptype = {
  isOpen: Proptypes.bool,
  onClose: Proptypes.bool,
  title: Proptypes.string,
  text: Proptypes.string,
  keyText: Proptypes.string,
  placeholder: Proptypes.string,
  height: Proptypes.number,
  width: Proptypes.number,
  submitButton: Proptypes.object,
  cancelButton: Proptypes.object,
  onChange: Proptypes.func,
  setAlert: Proptypes.bool,
};

export default AuditModal;
