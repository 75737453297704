import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import AppColors from '../../../constants/AppColors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CQLineChart = ({ chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  if (!chartData) {
    return null;
  }
  if (chartData && !Object.keys(chartData).length) {
    return null;
  }

  const dataValues = Object.keys(chartData).map(key => chartData[key]);

  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        borderColor: AppColors.primary,
        backgroundColor: AppColors.primary,
      },
    ],
  };
  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};
export default CQLineChart;
