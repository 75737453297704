import { Box, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import { Text, HStack, Spacer } from '@chakra-ui/react';
import {
  PrimaryButton,
  useBoolean,
} from '@laxmimanogna/code-quick-components';
import AppColors from '../../constants/AppColors';
import ReportProvider from '../../providers/ReportProvider';
import InternalQualityContainer from './internal_quality/InternalQualityContainer';
import AuditorQualityContainer from './auditor_quality/AuditorQualityContainer';
import { downloadReport } from './downloadReport';
import { CustomTab } from '../../components/CustomTab';

const ReportContainer = props => {
  const [selectedErrors, setSelectedErrors] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, ilState] = useBoolean(false);
  const [reportDownload, setReportDownload] = useState([false, true]);

  const selectRef = useRef(null);

  const errorQualityRef = useRef(null);
  const tableRef = useRef(null);
  const auditorQualityRef = useRef(null);
  const reportFile = [
    {
      fileName: 'internal_report.pdf',
      header: 'Internal Report',
      page1Ref: [
        { ref: selectRef, height: () => 17, width: 550, paddingTop: 40 },
        { ref: errorQualityRef, height: () => 280, width: 550, paddingTop: 60 },
        {
          paddingTop: 380,
          ref: tableRef,
          height: length => {
            if (length === 0) {
              return 60;
            }
            return 60 + 25 * (length + 1);
          },
          width: 550,
        },
      ],
    },
    {
      fileName: 'auditor_report.pdf',
      header: 'Auditor Report',
      page1Ref: [
        {
          ref: auditorQualityRef,
          height: () => 280,
          width: 550,
          paddingTop: 40,
        },
      ],
    },
  ];

  const enableReportDownload = flag => {
    if (flag) {
      reportDownload[selectedTab] = true;
      setReportDownload([...reportDownload]);
    } else {
      reportDownload[selectedTab] = false;
      setReportDownload([...reportDownload]);
    }
  };

  const reportTabs = [
    {
      title: 'INTERNAL QUALITY',
      header: 'Internal Quality',
      component: (
        <InternalQualityContainer
          page1Ref={reportFile[0].page1Ref}
          selectedErrors={selectedErrors}
          setSelectedErrors={setSelectedErrors}
          isLoading={isLoading}
          ilState={ilState}
          enableReportDownload={enableReportDownload}
        />
      ),
    },
    {
      title: 'AUDITOR QUALITY',
      header: 'Auditor Quality',
      component: (
        <AuditorQualityContainer
          page1Ref={reportFile[1].page1Ref}
          isLoading={isLoading}
          ilState={ilState}
        />
      ),
    },
  ];

  return (
    <>
      <CQContainer py={30} px={30}>
        <HStack mb={4}>
          <Text
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontSize={'2xl'}
            fontWeight="bold"
          >
            Reports
          </Text>
          <Spacer />
          <PrimaryButton
            bg={AppColors.primary}
            fontSize={'sm'}
            fontWeight="normal"
            size="md"
            borderRadius="3xl"
            outline={`2px`}
            height={'50px'}
            _hover={{
              transition: 'all .3s ease',
              bgColor: AppColors.white,
              color: AppColors.primary,
              outline: `2px solid ${AppColors.primary}`,
            }}
            px={10}
            disabled={isLoading || !reportDownload[selectedTab]}
            onClick={async () => {
              await downloadReport(reportFile[selectedTab], selectedErrors);
            }}
          >
            Generate Aggregate Report
          </PrimaryButton>
        </HStack>
        <Box bg="white" p={4} w="100%" h="150%" borderRadius="md" ml={0}>
          <Tabs
            fontFamily={FONT_FAMILY.baiJamurjee}
            onChange={setSelectedTab}
            index={selectedTab}
          >
            <TabList>
              <HStack spacing={4} px={4}>
                {reportTabs.map((aa, index) => {
                  return (
                    <Box position={'relative'}>
                      <CustomTab
                        key={`reports-tab-${index}`}
                        color={AppColors.secondary}
                        fontFamily={FONT_FAMILY.baiJamurjee}
                        fontSize={'18px'}
                      >
                        {aa.title}
                      </CustomTab>
                    </Box>
                  );
                })}
              </HStack>
            </TabList>

            <TabPanels>
              {reportTabs.map((aa, index) => {
                return (
                  <TabPanel p={0} key={`reports-panel-${index}`}>
                    {aa.component}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(ReportProvider, ReportContainer);
