import { FormCQSelect } from '@laxmimanogna/code-quick-components';
import React from 'react';
import { STATES } from '../constants/constants';
import { Text } from '@chakra-ui/react';

function StateDropDown(props) {
  const { control, errors } = props;
  return (
    <>
      <FormCQSelect
        control={control}
        options={STATES}
        styles={{
          control: styles => ({
            ...styles,
            height: '50px',
            borderRadius: '5px',
            border: `1px solid rgba(0,0,0,0.1)`,
            zIndex:99
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 9999999,
          }),
        }}
        id={'state'}
        name={'state'}
        placeholder="Select State"
        variant="primary"
        isSearchable={true}
      />
      {errors['state'] && errors['state']['label'] && (
        <Text color={'red'}>State is required.</Text>
      )}
    </>
  );
}
export default StateDropDown;
