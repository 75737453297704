import AppColors from '../constants/AppColors';
import {
  DownIcon,
  LeftEnableIcon,
  LeftIcon,
  RightEnableIcon,
  RightIcon,
  SortIcon,
  UpIcon,
} from '../constants/IconData';
import { FONT_FAMILY } from '../constants/Theme';

export const defaultReactTableProp = {
  renderSortUpIcon,
  renderSortIcon,
  renderSortDownIcon,
  getHeaderCellProps,
  getHeaderProps,
  getHeaderWrapperProps,

  RightIcon: <RightIcon />,
  LeftIcon: <LeftIcon />,
};
export function paginationProps(pagination) {
    const canGoBack = pagination?.currentPage !== 1;
    const canGoNext = pagination?.totalPage !== pagination.currentPage;
  return {
    total: pagination.totalCount,
    pageCount: pagination.totalPage,
    size: pagination.showSize,
    currentPage: pagination.currentPage,
    LeftIcon: canGoBack ? <LeftEnableIcon /> : <LeftIcon />,
    RightIcon: canGoNext ? <RightEnableIcon /> : <RightIcon />
  };
}

export function renderSortUpIcon() {
  return (
    <UpIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortDownIcon() {
  return (
    <DownIcon
      style={{
        width: 10,
        height: 10,
        marginLeft: 1,
      }}
    />
  );
}

export function renderSortIcon() {
  return (
    <SortIcon
      style={{
        width: 15,
        height: 15,
        marginLeft: 1,
      }}
    />
  );
}

export function getHeaderCellProps(header) {
  const startingHeaderId = ['specifics'];

  return {
    textTransform: 'none',
    fontFamily: FONT_FAMILY.ptSans,
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'white',
    justifyContent: startingHeaderId.includes(header.id)
      ? 'flex-start'
      : 'center',
  };
}

export function getHeaderWrapperProps(header) {
  let round = {};
  if (header.id === 'specifics') {
    round = {
      borderTopLeftRadius: '10px',
    };
  }
  if (header.id === 'week5') {
    round = {
      borderTopRightRadius: '10px',
      borderRightWidth: 0,
    };
  }
  return {
    borderRightWidth: 1,
    fontSize: '16px',
    fontFamily: FONT_FAMILY.ptSans,
    ...round,
  };
}

export function getHeaderProps(props, data) {
  let backgroundColor = AppColors.secondary;
  return {
    style: {
      backgroundColor,
      fontSize: '16px',
      fontFamily: FONT_FAMILY.ptSans,
    },
  };
}

export function getCellProps(cell, data, rowLength) {
  let backgroundColor = 'white';
  let fontWeight = 'normal';
  let round = {};
  if (data.column.id === 'specifics') {
    backgroundColor = '#8ee9e5';
    backgroundColor = 'rgba(67, 218, 212, .24)';
    fontWeight = 'bold';
  } else if (cell.index === rowLength - 1) {
    backgroundColor = 'rgba(249, 72, 15, .1)';
  } else if (cell.index === 2) {
    backgroundColor = AppColors.bgColor;
  }
  if (cell.index === rowLength - 1 && data.column.id === 'specifics') {
    round = {
      borderBottomLeftRadius: '10px',
      borderBottomWidth: 0,
    };
  }

  if (cell.index === rowLength - 1 && data.column.id === 'week5') {
    round = {
      borderBottomRightRadius: '10px',
      borderBottomWidth: 0,
      borderColor: 'white',
    };
  } else if (data.column.id === 'week5') {
    round = {
      borderRightWidth: 0,
    };
  }
  let borderBottomWidth = 1;
  if (cell.index === rowLength - 1) {
    borderBottomWidth = 0;
  }
  const headerId = ['specifics'];

  const targetHeader = data?.id.split('_')[1];

  return {
    style: {
      color: AppColors.black,
      fontWeight,
      fontSize: '16px',
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: headerId.includes(targetHeader) ? 'left' : 'center',
      backgroundColor,
      borderRightWidth: 1,
      padding:'10px',
      borderBottomWidth,
      ...round,
    },
  };
}
