import  { useEffect, useState } from 'react';
import useDrivePicker from '@fyelci/react-google-drive-picker'
import { GoogleDriveIcon } from '../../../constants/IconData';
import { Flex } from '@chakra-ui/react';

//Handel multiple file logic and check for errors
const CILENTID = process.env.REACT_APP_GOOGLE_DRIVE_CILENTID
const API_KEY= process.env.REACT_APP_GOOGLE_DRIVE_API_KEY

function GoogleDriveUploader({children, uploadCharts}) {
    const [openPicker, authResponse] = useDrivePicker();  
    const [selectedFiles, setSelectedFiles] = useState([]);

    const downloadUrl = (fileId, mimeType) => {
      let downloadUrl;
      switch (mimeType) {
        case "application/vnd.google-apps.spreadsheet":
            downloadUrl = `https://docs.google.com/spreadsheets/d/${fileId}/export?format=xlsx&key=${API_KEY}`;
            break;
        case "application/vnd.google-apps.document":
            downloadUrl = `https://docs.google.com/document/d/${fileId}/export?format=docx&key=${API_KEY}`;
            break;
        case "application/pdf":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            downloadUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${API_KEY}`;
            break;
        default:
            downloadUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${API_KEY}`;
      }
      return downloadUrl;

    }


    const handleOpenPicker = () => {
        openPicker({
        clientId: CILENTID,
        developerKey: API_KEY,
        viewMimeTypes: 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/octet-stream',
        // token: authResponse?.access_token || null, // pass oauth token in case you already have one
        supportDrives: true,
        multiselect: true,
        customScopes: ["https://www.googleapis.com/auth/drive.file"],
        callbackFunction: (data) => {
            if(data.action === 'picked'){
              const files = data.docs.map(doc => ({
                id: doc.id,
                name: doc.name,
                mimeType: doc.mimeType,
              }));
              setSelectedFiles(files); 
            }
        },
        })
    }

    useEffect(() => {
      if (selectedFiles.length > 0 && authResponse) {
        const fetchPromises = selectedFiles.map(file => 
          fetch(downloadUrl(file.id, file.mimeType), {
            headers: {
              Authorization: `Bearer ${authResponse.access_token}`,
            },
          })
            .then(response => response.blob())
            .then(blob => {
              const driveFile = new File([blob], file.name, { type: blob.type });
              return driveFile; // Return the file to be collected
            })
            .catch(error => {
              console.error('Error fetching Google Drive file:', error);
              return null; // Return null on error to filter out later
            })
        );
    
        Promise.all(fetchPromises)
          .then(uploadFiles => {
            uploadFilesInDropZone(uploadFiles.filter(file => file)); // Filter out any null values
          })
          .catch(error => {
            console.error('Error in fetching all files:', error);
          });
      }
    }, [selectedFiles, authResponse]);
    
    const uploadFilesInDropZone = async (uploadFiles) => {
      if (uploadFiles.length > 0) {
        await uploadCharts(uploadFiles);
      }
    }
    
  
    return (
        <Flex
          p={4}
          border="2px dashed"
          borderColor="#A0AEC0"
          borderRadius="12px"
          bgColor={'white'}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          _hover={{ borderColor: '#48BB78', bgColor: '#E6FFFA' }}
          style={{ cursor: 'pointer' }}
          mb={4}
          onClick={handleOpenPicker}
         >
          {children}
        </Flex>
    );
  }
  
  export default GoogleDriveUploader;