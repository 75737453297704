/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Heading, TextInput, useTabStorage } from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { FONT_FAMILY } from '../../constants/Theme';
import MyAuditProvider, {
  MyAuditContext,
} from '../../providers/MyAuditProvider';
import RecentAudits from './components/RecentAudits';
import { withProvider } from '../../hoc/withProvider';
import useCQMediaQuery from '../../hooks/useCQMediaQuery';
import { SearchIcon } from '@chakra-ui/icons';
import AppColors from '../../constants/AppColors';
import { UrgentIcon } from '../../constants/IconData';
import UrgentTable from './components/tables/UrgentTable';

// tables
import AllMyAuditTable from './components/tables/AllMyAuditTable';
import MyArchivedTable from './components/tables/MyArchivedTable';
import MyNewUploadTable from './components/tables/MyNewUploadTable';
import MyRebuttalTable from './components/tables/MyRebuttalTable';
import { CustomTab } from '../../components/CustomTab';

const AssignedAuditTabs = [
  {
    title: 'ALL',
    header: 'Audit Uploads',
    component: AllMyAuditTable,
  },
  {
    title: 'NEW UPLOADS',
    header: 'New Uploads',
    component: MyNewUploadTable,
  },
  {
    title: 'ARCHIVED',
    header: 'Archived',
    component: MyArchivedTable,
  },
  {
    title: 'REBUTTALS',
    header: 'Rebuttals',
    component: MyRebuttalTable,
  },
  {
    title: 'URGENT',
    header: 'Urgents',
    component: UrgentTable,
  },
];

const DashboardContainer = props => {
  // conext
  const auditContext = useContext(MyAuditContext);
  const { handleApplyFilter, filterParams, auditUrgentListCount } =
    auditContext;

  // states
  const tabStorage = useTabStorage();
  const [onSearchText, setSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState(tabStorage.getTab() || 0);

  // other hooks
  const marginHorizontal = useCQMediaQuery({
    lg: '12%',
    md: '6%',
    sm: '2%',
  });

  // life-cycle hooks
  useEffect(() => {tabStorage.setTab(selectedTab)}, [selectedTab]);

  useEffect(() => {
    auditContext.getRecentAudits();
  }, []);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onFilterChange = e => {
    if (e) {
      auditContext.getAuditUpload.setParams({ page: 1 });
      auditContext.getAuditNewUploads.setParams({ page: 1 });
      auditContext.getAuditRebuttals.setParams({ page: 1 });
      auditContext.getAuditArchived.setParams({ page: 1 });
      auditContext.getAuditUrgent.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  const renderAuditSheet = () => {
    return <RecentAudits />;
  };


  return (
    <Box>
      <Box
        py={5}
        height={280}
        bg={'white'}
        overflow={'hidden'}
        px={marginHorizontal}
      >
        <Heading
          fontSize={'24px'}
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontWeight="bold"
          py={3}
        >
          Recent Audits
        </Heading>
        {renderAuditSheet()}
      </Box>
      <Box mx={marginHorizontal}>
        <Heading my={5} fontSize={'24px'}>
          My Audits
        </Heading>
        <Box
          shadow={'md'}
          bg="white"
          p={4}
          w="100%"
          style={{ borderRadius: '12px' }}
        >
          <Tabs
            fontFamily={FONT_FAMILY.baiJamurjee}
            onChange={setSelectedTab}
            index={selectedTab}
          >
            <TabList>
              <HStack w={'100%'} justifyContent={'space-between'}>
                <HStack spacing={4} px={4}>
                  {AssignedAuditTabs.map((aa, index) => {
                    return (
                      // <Box position={'relative'}>
                      <CustomTab
                        key={`assigned-audit-tab-${index}`}
                        color={AppColors.secondary}
                        pb={4}
                        fontFamily={FONT_FAMILY.baiJamurjee}
                      >
                        {aa.title}
                        <Box position={'relative'}>
                          {aa.title === 'URGENT' && auditUrgentListCount ? (
                            <UrgentIcon
                              style={{
                                position: 'absolute',
                                left: '75px',
                                top: '-30px',
                              }}
                            />
                          ) : null}
                        </Box>
                      </CustomTab>
                      // </Box>
                    );
                  })}
                </HStack>
                <Box>
                  <Box>
                    <TextInput
                      height={'50px'}
                      borderRadius={'md'}
                      value={onSearchText}
                      onChange={e => onFilterChange(e)}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                      }}
                      mb="2"
                      rightIconProps={{ zIndex: 0 }}
                      placeholder="Search"
                      rightIcon={
                        <SearchIcon
                          style={{ marginTop: '10px' }}
                          onClick={onSearchAction}
                        />
                      }
                    />
                  </Box>
                </Box>
              </HStack>
            </TabList>

            <TabPanels>
              {AssignedAuditTabs.map((aa, index) => {
                return (
                  <TabPanel p={0} key={`assigned-audit-panel-${index}`}>
                    <aa.component />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default withProvider(MyAuditProvider, DashboardContainer);
