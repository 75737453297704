import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Flex,
  Center,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';
import PropTypes from 'prop-types';
import theme from '../../../constants/Theme';
import AppColors from '../../../constants/AppColors';
import { AuditContext } from '../../../providers/AuditProvider';

function UrgentConfirmationPopup(props) {
  const auditContext = useContext(AuditContext);
  const [isNotSmallScren] = useMediaQuery('(min-width: 410px)');
  const [reduceModalWidth] = useMediaQuery('(min-width: 630px)');
  async function markAsUrgent() {
    await auditContext.markAsUrgent(props.rowId, props.isUrgent);
    props.onClose();
  }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        size={'lg'}
        onClose={props.onClose}
        isCentered
      >
        <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
        <ModalContent
          maxH={'55rem'}
          maxW={reduceModalWidth ? '38rem' : '27rem'}
          p={15}
          alignItems={'center'}
        >
          <ModalHeader
            fontSize={'2xl'}
            mr={'10px'}
            marginBottom={'15px'}
            color={AppColors.secondary}
            w={'450px'}
            fontFamily={theme.fonts.heading}
            letterSpacing={'1px'}
          >
            <Center textAlign={'center'}>
              Are you sure you want to{' '}
              {props.isUrgent
                ? `mark ${props.uploadId} as Urgent`
                : `remove ${props.uploadId} from Urgent`}
              ?
            </Center>
          </ModalHeader>
          <ModalBody mb={3}>
            <Flex
              alignItems={'center'}
              direction={isNotSmallScren ? 'row' : 'column'}
            >
              <Button
                variant="outline"
                style={{border:`2px solid ${AppColors.secondary}`}}
                height={'50px'}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.secondary,
                  color: AppColors.white,
                }}
                borderRadius={25}
                fontWeight={500}
                paddingInline={'35px'}
                backgroundColor={AppColors.white}
                marginBottom={isNotSmallScren ? '0px' : '10px'}
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                ml={isNotSmallScren ? 7 : 0}
                borderRadius={25}
                // width={'170px'}
                height={'50px'}
                color={AppColors.orange}
                paddingInline={'35px'}
                fontWeight={500}
                backgroundColor={AppColors.white}
                border={ `2px solid ${AppColors.red}`}
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.red,
                  color: AppColors.white,
                  border: `2px solid ${AppColors.red}`,
                }}
                onClick={() => {
                  markAsUrgent();
                }}
                isLoading={auditContext.isMarkedAsUrgent}
                loadingText="updating"
                spinnerPlacement="end"
              >
                {props.isUrgent ? `Mark as Urgent` : `Remove from Urgent`}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
UrgentConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
export default UrgentConfirmationPopup;
