import { Avatar, HStack, Text, Box, Divider, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import AppColors from '../constants/AppColors';
import { useState } from 'react';
import { FONT_FAMILY } from '../constants/Theme';
import { LinkIcon } from '../constants/IconData';
import { useComponentVisible } from '../hooks';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../constants/Routes';
import { ROLES_VALUES } from '../constants/constants';
import Ellipses from './Ellipses';
import { sendMail } from '../utils/send_mail.util';
import { replaceRoute } from '../utils/common.utils';

const AvatarEllipses = ({ users, outerIndex, teamId }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(null);
  if (!users.length) {
    return <Text>-</Text>;
  }
  let remainingCount = 0;
  if (users.length > 4) {
    remainingCount = users.length - 4;
  }

  return (
    <HStack>
      {users.map((user, i) => {
        if (i > 3) {
          return null;
        }
        return (
          <Box key={`${i}-user-avatar`} position="relative">
            <Avatar
              key={`${i}-avatar`}
              name={user.name}
              bg={AppColors.secondary}
              color={'white'}
              size="sm"
              cursor={'pointer'}
              onClick={() => {
                setIsComponentVisible(true);
                setShow(`${outerIndex}-${i}`);
              }}
            />
            {show === `${outerIndex}-${i}` && isComponentVisible ? (
              <Box
                ref={ref}
                fontWeight="normal"
                zIndex={10}
                position="absolute"
                borderRadius={'md'}
                boxShadow="xl"
                padding={5}
                bg={'white'}
                width={'250px'}
              >
                <Text
                  color={AppColors.black}
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize="sm"
                  fontWeight={'bold'}
                  textTransform="capitalize"
                  align={'left'}
                >
                  {user.name}
                </Text>
                <Divider my={3} />
                <Text
                  color={AppColors.black}
                  align={'left'}
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize="sm"
                  textTransform="capitalize"
                  fontWeight={'normal'}
                >
                  {ROLES_VALUES[user.role]}
                </Text>
                <HStack mt={4} mr={2} justifyContent="space-between">
                  {/* <Text fontFamily={FONT_FAMILY.ptSans} fontSize='sm' color={AppColors.primary} fontWeight='normal'>
                            {user.email}
                        </Text> */}
                  <Ellipses str={user.email} count={27} />
                  <LinkIcon
                    onClick={() => {
                      sendMail(user.email);
                    }}
                    style={{ height: 13, width: 13, cursor: 'pointer' }}
                  />
                </HStack>
                <HStack mt={4} justifyContent="space-between">
                  <Box>
                    <Text
                      align={'left'}
                      fontFamily={FONT_FAMILY.ptSans}
                      fontSize="sm"
                      color={AppColors.black}
                      fontWeight={'normal'}
                    >
                      Active Audits
                    </Text>
                    <Text
                      align={'left'}
                      fontFamily={FONT_FAMILY.ptSans}
                      fontSize="sm"
                      color={AppColors.secondary}
                      fontWeight="bold"
                    >
                      {user.active_audits}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      align={'left'}
                      fontFamily={FONT_FAMILY.ptSans}
                      fontSize="sm"
                      color={AppColors.black}
                      fontWeight={'normal'}
                    >
                      Total Audits
                    </Text>
                    <Text
                      align={'left'}
                      fontFamily={FONT_FAMILY.ptSans}
                      fontSize="sm"
                      color={AppColors.secondary}
                      fontWeight="bold"
                    >
                      {user.total_audits}
                    </Text>
                  </Box>
                </HStack>
                <Button
                  display={'flex'}
                  width="100%"
                  mt={4}
                  bg={'white'}
                  borderColor={AppColors.secondary}
                  borderWidth={'2px'}
                  color={AppColors.secondary}
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  fontWeight={'bold'}
                  fontSize={'sm'}
                  cursor="pointer"
                  size={'sm'}
                  onClick={() => {
                    const userId = user.id;
                    const USER_PROFILE_ROUTE = replaceRoute(
                      ROUTES.USER_PROFILE,
                      {
                        teamId,
                        userId,
                      }
                    );
                    navigate(USER_PROFILE_ROUTE);
                  }}
                >
                  Go to Profile
                </Button>
              </Box>
            ) : null}
          </Box>
        );
      })}
      {remainingCount > 0 ? (
        <Text color={AppColors.secondary} fontWeight="bold">
          <AddIcon w={2} h={2} />
          {remainingCount}
        </Text>
      ) : null}
    </HStack>
  );
};
export { AvatarEllipses };
