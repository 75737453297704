import { Stack, Image, Flex } from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { QWatermark } from '../../../constants/ImageData';
import { renderCoverImage } from '../../../components/AuthCoverImage';
import ROUTES from '../../../constants/Routes';
import { useNavigate } from 'react-router-dom';
import { MarkIcon } from '../../../constants/IconData';
import { CQResetPasswordMessage } from '@laxmimanogna/code-quick-components';
import useDeviceScreen from '../../../hooks/useMediaQuery';

export default function ResetConfirmationContainer() {
  const navigate = useNavigate();
  const { isTablet, isMobile } = useDeviceScreen();

  return (
    <Flex
      bgColor={AppColors.primary}
      // py={'5%'}
      // px={isMobile ? '20%' : '10%'}
      alignItems={'center'}
      justifyContent={'center'}
      minH={'100vh'}
    >
      {isMobile ? (
        <Image
          top={'5vh'}
          left={30}
          style={{ height: '95vh' }}
          position={'absolute'}
          src={QWatermark}
          alt="q-watermark"
        />
      ) : (
        <Image
          top={'5vh'}
          left={5}
          style={{ height: '80vh' }}
          position={'absolute'}
          src={QWatermark}
          alt="q-watermark"
        />
      )}
      <Stack
        spacing={-8}
        height={'730px'}
        direction={{ base: 'column', md: 'row' }}
        // marginTop={!isTablet ? '30%' : ''}
        // width={'100%'}
        px={!isTablet ? '5%' : '0'}
        width={'1150px'}
      >
        {renderCoverImage()}
        <CQResetPasswordMessage
          icon={<MarkIcon />}
          buttonName="Login"
          buttonOnClick={() => {
            navigate(ROUTES.LOGIN_SCREEN);
          }}
          message="Successful password reset!"
          buttonProps={{
            mt: 5,
          }}
        />
      </Stack>
    </Flex>
  );
}
