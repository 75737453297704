import { API } from '../config/AppConfig';
import {
  ACCOUNTS_LIST,
  CREATE_DEPARTMENT,
  DEPARTMENT,
  EHR_LIST,
  GET_ACCOUNT,
  GET_ACCOUNT_DEPARTMENTS,
  GET_ACCOUNT_HOSPITALS,
  GET_ACCOUNT_MEMBERS,
  GET_ACCOUNT_TEAM_STATISTICS,
  GET_SPECIALTIES,
  HEALTH_SYSTEM_INFO,
  HOSPITAL_LIST,
  INSURANCE_LIST,
  DELETE_ACCOUNT,
  GET_PROVIDER_LIST,
  CREATE_PROVIDER,
  GET_PROVIDER_BY_DEPARTMENT,
  GET_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  ALL_DEPARTMENT,
  GET_DEPARTMENT_DROPDOWN,
  HOSPITAL,
  CREATE_HEALTH_SYSTEM,
  CREATE_HOSPITAL,
  GET_HOSPITAL_DATA,
  HEALTH_SYSTEM_VALIDATION,
  CQ_USERS_MAIL_ID,
  CQ_HOSPITAL_DATA,
  HOSPITAL_DEPARTMENTS,
  PHYSICIAN_LIST,
  MOODLE_ACTION,
  MOODLE_ACTION_MODIFY,
  MOODLE_SYNC_PROVIDER_BY_DEPARTMENT,
} from '../constants/Endpoints';

class AccountsRepository {
  // constructor() {
  // this.ehrOptions = [...ehrOptions];
  // this.insuranceOptions = [...insuranceOptions];
  // this.specialtyOptions = [...specialtyOptions];
  // this.hospitals = [...hospitalData];

  // this.healthSystem = healthSystemInformation;
  // }

  async getHealthSystemInformation(healthSystemId) {
    const response = await API.get(HEALTH_SYSTEM_INFO, {
      pathParams: { healthSystemId },
    });
    return response;
  }

  async updateHealthSystemInformation(healthSystemId, payload) {
    const response = await API.put(HEALTH_SYSTEM_INFO, {
      pathParams: { healthSystemId },
      body: payload,
    });
    return response;
  }

  async getHospitalsForHealthSystem(queryParams) {
    const response = await API.get(HOSPITAL_LIST, {
      queryParams,
    });
    return response;
  }

  async createHospital(hospitalData) {
    const { health_system, ...hospitalCreateData } = hospitalData;
    const response = await API.post(CREATE_HOSPITAL, {
      body: hospitalCreateData,
      pathParams: { health_system },
    });
    return response;
  }

  async updateHospital(hospitalID, updatedhospitalData) {
    const response = await API.put(GET_HOSPITAL_DATA, {
      pathParams: { hospitalID },
      body: updatedhospitalData,
    });
    return response;
  }

  async getSpecialtyOptions(queryParams) {
    const response = await API.get(GET_SPECIALTIES, {
      queryParams
    });
    return response.map(res => ({
      label: res.name,
      value: res.id ? res.id : res.name,
      id: res.id,
    }));
  }

  async createSpecialtyOption(optionName) {
    await new Promise((res, rej) => setTimeout(res, 3000));
    const newOption = {
      value: Date.now(),
      label: optionName,
    };
    this.specialtyOptions.push(newOption);
    return newOption;
  }

  async getInsuranceOptions() {
    const response = await API.get(INSURANCE_LIST);
    return response;
  }

  async createInsuranceOption(optionName) {
    await new Promise((res, rej) => setTimeout(res, 3000));
    const newOption = {
      value: Date.now(),
      label: optionName,
    };
    this.insuranceOptions.push(newOption);
    return newOption;
  }

  async getEHROptions() {
    const response = await API.get(EHR_LIST);
    return response;
  }

  async createEHROption(optionName) {
    await new Promise((res, rej) => setTimeout(res, 3000));
    const newOption = {
      value: Date.now(),
      label: optionName,
    };
    this.ehrOptions.push(newOption);
    return newOption;
  }

  async accountsList(filterParams) {
    const response = await API.get(ACCOUNTS_LIST, {
      queryParams: filterParams,
    });
    return response;
  }

  async getAccount(accountId) {
    const response = await API.get(GET_ACCOUNT, {
      pathParams: { accountId },
    });

    return response;
  }

  async getAccountBasedHospitals(accountId, queryParams) {
    const response = await API.get(GET_ACCOUNT_HOSPITALS, {
      pathParams: { accountId },
      queryParams,
    });

    return response;
  }

  async getAccountBasedDepartments(accountId,filterParams) {
    const response = await API.get(GET_ACCOUNT_DEPARTMENTS, {
      pathParams: { accountId },
      queryParams:filterParams
    });

    return response;
  }

  async getTeamStatistics(queryParam) {
    const response = await API.get(GET_ACCOUNT_TEAM_STATISTICS, {
      queryParams: queryParam,
    });

    return response;
  }

  async getAccountMembers(accountId, queryParam) {
    const response = await API.get(GET_ACCOUNT_MEMBERS, {
      queryParams: queryParam,
      pathParams: { accountId },
    });

    return response;
  }

  async searchAccount(queryParam) {
    const response = await API.get(ACCOUNTS_LIST, {
      queryParams: queryParam,
    });
    return response;
  }

  async deleteAccount(healthsystemId) {
    const response = await API.delete(DELETE_ACCOUNT, {
      pathParams: { healthsystemId },
    });
    return response;
  }

  //DEPARTMENT

  async deleteDepartment(departmentId) {
    const response = await API.delete(DEPARTMENT, {
      pathParams: { departmentId },
    });
    return response;
  }

  async updateDepartment(departmentId, payload) {
    const response = await API.put(DEPARTMENT, {
      pathParams: { departmentId },
      body: payload,
    });
    return response;
  }

  async getDepartment(departmentId) {
    const response = await API.get(DEPARTMENT, {
      pathParams: { departmentId },
    });
    return response;
  }

  async getDepartments(queryParams) {
    const response = await API.get(ALL_DEPARTMENT, {
      queryParams,
    });
    return response;
  }

  async createDepartment(hospitalId, payload) {
    const response = await API.post(CREATE_DEPARTMENT, {
      pathParams: { hospitalId },
      body: payload,
    });
    return response;
  }

  async getHospital(hospitalId) {
    const response = await API.get(HOSPITAL, {
      pathParams: { hospitalId },
    });
    return response;
  }

  async deleteHospital(hospitalId) {
    const response = await API.delete(HOSPITAL, {
      pathParams: { hospitalId },
    });
    return response;
  }

  async getDepartmentDropdown(hospitalId) {
    const response = await API.get(GET_DEPARTMENT_DROPDOWN, {
      pathParams: { hospitalId },
    });
    return response;
  }
  async createHealthSystem(payload) {
    const response = await API.post(CREATE_HEALTH_SYSTEM, {
      body: payload,
    });
    return response;
  }

  async getProviderEmail(keyword) {
    const response = await API.get(GET_PROVIDER_LIST, {
      queryParams: { search: keyword },
    });
    return response;
  }

  async createProvider(departmentID, providerBody) {
    const response = await API.post(CREATE_PROVIDER, {
      pathParams: { departmentID },
      body: providerBody,
    });
    return response;
  }

  async moodleAccountCreate(moodleData) {
    const response = await API.post(MOODLE_ACTION, {
      body: moodleData,
    });
    return response;
  }

  async moodleAccountUpdate(providerId, providerBody) {
    const response = await API.put(MOODLE_ACTION_MODIFY, {
      pathParams: { providerId },
      body: providerBody,
    });
    return response;
  }

  async moodleAccountDelete(providerId) {
    const response = await API.delete(MOODLE_ACTION_MODIFY, {
      pathParams: { providerId },
    });
    return response;
  }

  async syncProvidersWithMoodle(departmentId) {
    const response = await API.get(MOODLE_SYNC_PROVIDER_BY_DEPARTMENT, {
      pathParams: { departmentID: departmentId },
    });
    return response;
  }
  
  async getProvidersByDeparment(departmentID, queryParams) {
    const response = await API.get(GET_PROVIDER_BY_DEPARTMENT, {
      pathParams: { departmentID },
      queryParams
    });
    return response;
  }
  async getProvider(providerId) {
    const response = await API.get(GET_PROVIDER, {
      pathParams: { providerId },
    });
    return response;
  }
  async onProviderUpdate(providerId, providerBody) {
    const response = await API.put(UPDATE_PROVIDER, {
      pathParams: { providerId },
      body: providerBody,
    });
    return response;
  }
  async onDeleteProvider(providerId) {
    const response = await API.delete(DELETE_PROVIDER, {
      pathParams: { providerId },
    });
    return response;
  }

  async healthSystemNamesAndPrefix() {
    const response = await API.get(HEALTH_SYSTEM_VALIDATION);
    return response;
  }

  async cqUsersMailId() {
    const response = await API.get(CQ_USERS_MAIL_ID);
    return response;
  }

  async cqHospitalsData() {
    const response = await API.get(CQ_HOSPITAL_DATA);
    return response;
  }

  async getHospitalDepartments(queryParams) {
    const response = await API.get(HOSPITAL_DEPARTMENTS, {
      pathParams: { hospitalId: queryParams?.hospital_id },
      queryParams,
    });
    return response;
  }

  async getPhysicians(queryParams) {
    const response = await API.get(PHYSICIAN_LIST, {
      queryParams,
    });
    return response;
  }
}

const accountsRepository = new AccountsRepository();
export default accountsRepository;
