import { useBoolean } from '@chakra-ui/react';
import React, { useState } from 'react';
import accountsRepository from '../repositories/AccountsRepository';

export const EditAccountContext = React.createContext({
  healthSystemInfo: {},
  isFetchingHealthSystem: false,
  fetchHealthSystem: async healthSystemId => {},

  // hospitals
  healthSystemHospitals: [],
  isFetchingHospitals: false,
  fetchHealthSystemHospitals: async healthSystemId => {},
});

const EditAccountProvider = ({ children }) => {
  // health system info
  const [healthSystemInfo, setHealthSystemInfo] = useState({});
  const [isFetchingHealthSystem, ifhsState] = useBoolean(false);

  // health system hospitals related
  const [healthSystemHospitals, setHealthSystemHospitals] = React.useState([]);
  const [isFetchingHospitals, ifhState] = useBoolean(false);

  async function fetchHealthSystem(healthSystemId) {
    try {
      ifhsState.on();
      const response = await accountsRepository.getHealthSystemInformation(
        healthSystemId
      );
      setHealthSystemInfo(response);
      return response;
    } catch (e) {
      // TODO: Capture Exception
    } finally {
      ifhsState.off();
    }
  }

  async function fetchHealthSystemHospitals(
    healthSystemId,
    { search, ordering }
  ) {
    try {
      ifhState.on();
      let filterParams = {};
      filterParams.health_system = healthSystemId;
      if (search) filterParams.search = search;
      if (ordering) filterParams.ordering = ordering;
      const response = await accountsRepository.getHospitalsForHealthSystem(
        filterParams
      );
      setHealthSystemHospitals(response);
    } catch (e) {
      // TODO: Capture Exception
    } finally {
      ifhState.off();
    }
  }

  const mContext = {
    healthSystemInfo,
    isFetchingHealthSystem,
    fetchHealthSystem,

    // hospitals
    healthSystemHospitals,
    isFetchingHospitals,
    fetchHealthSystemHospitals,
  };

  return (
    <EditAccountContext.Provider value={mContext}>
      {children}
    </EditAccountContext.Provider>
  );
};

export default EditAccountProvider;
