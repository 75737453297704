/* eslint-disable react-hooks/exhaustive-deps */
import { CQSelect } from '@laxmimanogna/code-quick-components';
import React, {  useEffect, useMemo, useState } from 'react';
import AppColors from '../../constants/AppColors';
import { toTitleCase } from '../../utils/common.utils';

const SpecialtyRow = props => {
  const controlBackground = 'transparent';

  const { specialties, selectedValue } = props;

  const transformedSpecialties = useMemo(() => {
    return specialties.map(s => ({
      ...s,
      label: s.label !== 'ent' ? toTitleCase(s.label) : 'ENT',
    }))
  }, [specialties])

  const [selectedSpecialty, setSelectedSpecialty] = useState(null);

  useEffect(() => {
    setParentValue()
  }, [selectedValue]);

  function setParentValue() {
    const nextSelectedValue = transformedSpecialties.find(s => s.value === selectedValue)
    setSelectedSpecialty(nextSelectedValue)
  }

  const handleSpecialtyChange = event => {
    const nextSelectedValue = transformedSpecialties.find(
      s => s.value === event.value
    );
    setSelectedSpecialty(nextSelectedValue);
    props.onChange(nextSelectedValue.value)
    .catch(e => {
      setParentValue();
    })
  };

  return (
    <CQSelect
      menuPortalTarget={document.body}
      options={transformedSpecialties}
      m={0}
      p={0}
      isDisabled={!!props.isDisabled}
      styles={{
        menu: (provided, state) => ({
          ...provided,
          width: "max-content",
          minWidth: "100%"
        }),
        menulist: (provided, state) => ({
          ...provided,
          color: AppColors.secondary,
        }),
        control: styles => ({
          ...styles,
          borderWidth: 0,
          background: controlBackground,
          maxWidth: 150,
        }),
        singleValue: (provided, state) => {
          return {
            ...provided,
            color: AppColors.secondary,
          };
        },
        dropdownIndicator: (provided, state) => {
          return {
            ...provided,
            color: AppColors.secondary,
          };
        },
        placeholder: provided => ({
          ...provided,
          color: AppColors.secondary,
        }),
      }}
      placeholder="Select"
      variant="secondary"
      isSearchable={false}
      value={selectedSpecialty}
      onChange={handleSpecialtyChange}
    />
  );
};

// export default SpecialtyRow;
export default React.memo(SpecialtyRow);
