import {
  useToastr,
  useBoolean,
  usePagination,
  paginationReturnType,
} from '@laxmimanogna/code-quick-components';
import { createContext, useState } from 'react';
import teamRepository from '../repositories/TeamRepository';
import { successToast } from '../components/successToast';

export const TeamContext = createContext({
  isTeamListLoading: false,
  isSpecialtiesLoading: false,
  isMemberListLoading: false,
  isLoading: false,
  isGettingUser: false,
  isTeamsUserDeleting: false,
  isGettingAverageTimes: false,
  isGettingChartAccuracy: false,
  isGettingAverageAuditHours: false,
  filterParams: {},
  teamList: [],
  specialties: [],
  memberList: [],
  currentTeam: {},
  teamsUser: {},

  getTeamList: paginationReturnType,

  handleApplyFilter: async () => {},
  getSpecialties: async () => {},
  getUserFromSpecialties: async () => {},
  createTeam: async () => {},
  updateTeam: async () => {},
  deleteTeam: async () => {},
  getTeam: async () => {},
  getUser: async userId => {},
  deleteTeamsUser: async userId => {},

  chartFilters: {},
  setChartFilters: () => {},
  getTeamChartAccuracy: async () => {},
  getTeamAverageAuditHours: async () => {},
  accuracyData: {},
  avgHours: {},
  setMemberList: () => {},
});
const initialFilterParams = {
  searchText: '',
  ordering: '',
};

const TeamProvider = props => {
  const toast = useToastr();

  const [filterParams, setFilterParams] = useState(
    Object.assign({}, initialFilterParams)
  );

  const [isTeamListLoading, itlState] = useBoolean(false);
  const [isMemberListLoading, imlState] = useBoolean(false);
  const [isLoading, ilState] = useBoolean(false);
  const [isSpecialtiesLoading, islState] = useBoolean(false);
  const [isGettingUser, iguState] = useBoolean(false);
  const [isTeamsUserDeleting, itudState] = useBoolean(false);

  const [teamListCount, setTeamListCount] = useState(0);

  const [specialties, setSpecialties] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const [teamsUser, setTeamsUser] = useState({});
  const [avgHours, setAvgHours] = useState({});
  const [accuracyData, setAccuracyData] = useState({});
  const [currentTeam, setCurrentTeam] = useState({});

  const getTeamList1 = usePagination(getTeamList);

  const initialFilters = {
    // user: '',
    team: '',
    // start_date: new Date(date.getFullYear(), currentMonth, 1),
    // end_date: new Date(date.getFullYear(), currentMonth + 1, 1),
    // previous_start_date: new Date(date.getFullYear(), previousMonth, 1),
    // previous_end_date: new Date(date.getFullYear(), previousMonth + 1, 1)
  };

  const [chartFilters, setChartFilters] = useState(
    Object.assign({}, initialFilters)
  );

  const getFilterParams = () => {
    const queryParams = {};
    const { searchText, ordering } = filterParams;
    if (searchText) queryParams['search'] = searchText;
    if (ordering) queryParams['ordering'] = ordering;
    return queryParams;
  };

  async function getTeamList(paginationParams) {
    const filterParams = getFilterParams();
    try {
      itlState.on();
      const response = await teamRepository.getTeams({
        ...paginationParams,
        ...filterParams,
      });

      setTeamListCount(response.count);
      setTeamList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      itlState.off();
    }
  }
  const getSpecialties = async () => {
    try {
      islState.on();
      const response = await teamRepository.getSpecialties();
      setSpecialties(response);
      if (response.length) {
        setSpecialties(response);
      }
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      islState.off();
    }
  };

  const getUserFromSpecialties = async specialties => {
    try {
      imlState.on();
      const response = await teamRepository.getUserFromSpecialties(specialties);
      const memberData = response.filter(
        (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
      );
      setMemberList(memberData);
      // if (Object.keys(response).length) {
      //   let options = [];
      //   Object.keys(response).forEach(data => {
      //     // TODO: BETTER TO USE FOR EACH HERE
      //     const temp = response[data].map(user => ({
      //       ...user,
      //       name: `${user.first_name} ${user.last_name}`,
      //       specialty: data,
      //     }));
      //     options = [...options, ...temp];

      //     return options;
      //   });
      //   setMemberList(options);
      // } else {
      //   setMemberList([]);
      // }
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      imlState.off();
    }
  };

  const createTeam = async data => {
    try {
      ilState.on();
      await teamRepository.createTeam(data);
      const toastProps = {
        header: 'Success!',
        description: `Team is created`,
      };
      successToast(toast, toastProps);
    } catch (e) {
      const response = e.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        if (response.errors[0] || response.errors) {
          const keys = response.errors[0]
            ? Object.values(response.errors[0])
            : Object.values(response.errors);
          toast.showError({
            description: keys.map(key => {
              return (
                <span>
                  {key}
                  <br />
                </span>
              );
            }),
          });
        }
      }
      return response;
    } finally {
      ilState.off();
    }
  };

  const updateTeam = async (data, teamId) => {
    try {
      ilState.on();
      await teamRepository.updateTeam(data, teamId);
      const toastProps = {
        header: 'Success!',
        description: `Team is updated`,
      };
      successToast(toast, toastProps);
    } catch (e) {
      const response = e.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        if (response.errors[0] || response.errors) {
          const keys = response.errors[0]
            ? Object.values(response.errors[0])
            : Object.values(response.errors);
          toast.showError({
            description: keys.map(key => {
              return (
                <span>
                  {key}
                  <br />
                </span>
              );
            }),
          });
        }
        return response;
      }
    } finally {
      ilState.off();
    }
  };
  const deleteTeam = async teamId => {
    try {
      imlState.on();
      const response = await teamRepository.deleteTeam(teamId);
      const temp = teamList.filter(team => team.id !== teamId);
      setTeamList([...temp]);
      return response;
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    } finally {
      imlState.off();
    }
  };

  const getTeam = async (teamId, reset) => {
    try {
      const response = await teamRepository.getTeam(teamId);
      setCurrentTeam(response);
      getUserFromSpecialties({ specialties: response.specialties });
      return response;
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    }
  };

  const handleApplyFilter = selectedFilters => {
    setFilterParams(selectedFilters);
  };

  async function getUser(userId) {
    try {
      iguState.on();
      const response = await teamRepository.getUser(userId);
      setTeamsUser(response);
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      iguState.off();
    }
  }

  async function deleteTeamsUser(userId) {
    try {
      itudState.on();
      await teamRepository.deleteTeamsUser(userId);
      const toastProps = {
        description: `${'Deleted Successfully'}`,
        header: 'Success!',
      };
      successToast(toast, toastProps);
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      itudState.off();
    }
  }

  const getTeamChartAccuracy = async filters => {
    try {
      const response = await teamRepository.getTeamChartAccuracy({
        ...chartFilters,
        ...filters,
      });
      setAccuracyData(response);
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    }
  };

  const getTeamAverageAuditHours = async filters => {
    try {
      const response = await teamRepository.getTeamAverageAuditHours({
        ...chartFilters,
        ...filters,
      });
      setAvgHours(response);
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    }
  };
  const mContext = {
    isTeamListLoading,
    isMemberListLoading,
    isSpecialtiesLoading,
    isLoading,

    filterParams,
    teamList,
    specialties,
    memberList,
    currentTeam,
    handleApplyFilter,
    teamListCount,

    getTeamList: getTeamList1,

    getSpecialties,
    getUserFromSpecialties,
    createTeam,
    updateTeam,
    deleteTeam,
    getTeam,

    getUser,
    teamsUser,
    isGettingUser,

    deleteTeamsUser,
    isTeamsUserDeleting,

    chartFilters,
    setChartFilters,
    getTeamChartAccuracy,
    getTeamAverageAuditHours,
    accuracyData,
    avgHours,
    setMemberList,
  };

  return (
    <TeamContext.Provider value={mContext}>
      {props.children}
    </TeamContext.Provider>
  );
};
export default TeamProvider;
