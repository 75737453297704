import React from "react";
import { Alert, AlertDescription, AlertTitle, Box, Skeleton, Spinner } from '@chakra-ui/react'
import { min } from "../utils";

export const ToastLoading = ({ title = 'Loading...', description = null }) => {
    return (
        <Alert
            status="info"
            variant="subtle"
            alignItems="start"
            borderRadius="md"
            paddingEnd={8}
            textAlign="start"
            width="auto"
        >
            <Spinner marginRight="4" />
            <Box flex="1">
                <AlertTitle>{title}</AlertTitle>
                {description && (
                    <AlertDescription display="block">{description}</AlertDescription>
                )}
            </Box>
        </Alert>
    );
}


const Loader = () => {

    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
        return (
            <Skeleton
                w={min(25 * i, 100) + '%'}
                key={`audit-upload-loader-${i}`}
                mb={2}
                h={2}
                borderRadius="sm"
            />
        );
    });
};
export { Loader }