import {
  useBoolean,
  usePagination,
} from '@laxmimanogna/code-quick-components';
import { createContext, useContext, useState } from 'react';
import userManagementRepository from '../repositories/UserManagementRepository';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../constants/Routes';
import teamRepository from '../repositories/TeamRepository';
import { ALL, NEW_UPLOAD } from '../constants/constants';
import myAuditRepository from '../repositories/MyAuditRepository';
import { ARCHIVED, REBUTTAL } from '../constants/Filter';
import { successToast } from '../components/successToast';
import { toTitleCase } from '../utils/common.utils';
import { AuthContext } from './AuthProvider';

export const UserManagementContext = createContext({
  isUserLoading: false,
  isUserList: false,
  isSpecialtyList: false,
  isLoading: false,

  specialties: [],
  users: [],
  userData: {},

  updateCurrentUser: async () => {},
  getAllSpecialties: async () => {},
  getAllUsers: async () => {},
  updateUser: async () => {},
  createUser: async () => {},
  getUser: async () => {},
  getTeam: async () => {},
  currentTeam: {},

  isAuditUploadListLoading: false,
  getAuditUpload: () => {},
  getAuditNewUploads: () => {},
  getAuditArchived: () => {},
  getAuditRebuttals: () => {},

  auditUploadList: [],
  auditNewUploadsList: [],
  auditArchivedList: [],
  auditRebuttalsList: [],

  teams: [],
  isGettingteamList: false,

  auditUploadListCount: 0,
  auditNewUploadsListCount: 0,
  auditArchivedListCount: 0,
  auditRebuttalsListCount: 0,
  filterParams: {},
  handleApplyFilter: () => {},

  chartFilters: {},
  setChartFilters: () => {},
  getChartAccuracy: async () => {},
  getAverageAuditHours: async () => {},
  accuracyData: {},
  avgHours: {},

  getTeams: async () => {},

  isFetchingAccuracy: false,
  isFetchingAuditHours: false,
});

const UserManagementProvider = props => {
  const toast = useToastr();
  const navigate = useNavigate();

  const params = useParams();

  const authContext = useContext(AuthContext);
  //loading state
  const [isUserLoading, isuState] = useBoolean(false);
  const [isUserList, iuState] = useBoolean(false);
  const [isSpecialtyList, isState] = useBoolean(false);
  const [isLoading, isucState] = useBoolean(false);
  const [currentTeam, setCurrentTeam] = useState({});
  const [specialties, setSpecialties] = useState([]);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const [teams, setTeams] = useState([]);
  const [isGettingteamList, igtlState] = useBoolean(false);

  //
  const [isFetchingAccuracy, ifadState] = useBoolean(false);
  const [isFetchingAuditHours, iahState] = useBoolean(false);

  const [isAuditUploadListLoading, iaState] = useBoolean(false);

  const [auditUploadList, setAuditUploadList] = useState([]);
  const [auditNewUploadsList, setAuditNewUploadsList] = useState([]);
  const [auditArchivedList, setAuditArchivedList] = useState([]);
  const [auditRebuttalsList, setAuditRebuttalsList] = useState([]);
  const [auditUploadListCount, setAuditUploadListCount] = useState(0);
  const [auditNewUploadsListCount, setAuditNewUploadsListCount] = useState(0);
  const [auditArchivedListCount, setAuditArchivedListCount] = useState(0);
  const [auditRebuttalsListCount, setAuditRebuttalsListCount] = useState(0);
  const [accuracyData, setAccuracyData] = useState({});
  const [avgHours, setAvgHours] = useState({});

  const initialFilterParams = {
    newUploadStatus: '',
    searchText: '',
  };

  const [filterParams, setFilterParams] = useState(
    Object.assign({}, initialFilterParams)
  );

  // let date = new Date();
  // const currentMonth = date.getMonth();
  // const previousMonth = currentMonth - 1;

  const initialFilters = {
    user: '',
    // team: '',
    // start_date: new Date(date.getFullYear(), currentMonth, 1),
    // end_date: new Date(date.getFullYear(), currentMonth + 1, 1),
    // previous_start_date: new Date(date.getFullYear(), previousMonth, 1),
    // previous_end_date: new Date(date.getFullYear(), previousMonth + 1, 1)
  };

  const [chartFilters, setChartFilters] = useState(
    Object.assign({}, initialFilters)
  );

  const getFilterParams = () => {
    const queryParams = {};
    const { searchText } = filterParams;
    if (searchText) queryParams['search'] = searchText;
    return queryParams;
  };

  async function getAuditUpload(paginationParams) {
    const filterParams = getFilterParams();
    if (ALL) {
      filterParams.user = userData.id;
      filterParams.status = ALL;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditUploadListCount(response.count);
      setAuditUploadList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditNewUploads(paginationParams) {
    const filterParams = getFilterParams();
    if (NEW_UPLOAD) {
      //usershould not be sent
      filterParams.status = NEW_UPLOAD;
      filterParams.user = userData.id;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditNewUploadsListCount(response.count);
      setAuditNewUploadsList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditArchived(paginationParams) {
    const filterParams = getFilterParams();
    if (ARCHIVED) {
      filterParams.status = ARCHIVED;
      filterParams.user = userData.id;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditArchivedListCount(response.count);
      setAuditArchivedList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditRebuttals(paginationParams) {
    const filterParams = getFilterParams();
    if (REBUTTAL) {
      filterParams.status = REBUTTAL;
      filterParams.user = userData.id;
    }
    try {
      iaState.on();
      const response = await myAuditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditRebuttalsListCount(response.count);
      setAuditRebuttalsList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  //upadate the current user
  async function updateCurrentUser(updateParams) {
    try {
      isuState.on();
      const response = await userManagementRepository.updateCurrentUser(
        updateParams
      );
      const toastProps = {
        header: 'Success!',
        description: `User updated successfully`,
      };
      successToast(toast, toastProps);
      navigate(ROUTES.ASSIGNED_AUDITS);
      authContext.updateCurrentUser(response);
      return response;
    } catch (e) {
      const response = e.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        const keys = Object.values(response.errors);
        const descriptionData =
          keys && keys.length && keys.map(key => `${key}, `);
        toast.showError({
          description: descriptionData,
        });
        return { errors: response.errors };
      }
    } finally {
      isuState.off();
    }
  }
  async function getAllSpecialties() {
    try {
      isState.on();
      const response = await userManagementRepository.getSpecialties();
      setSpecialties(response);
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      isState.off();
    }
  }

  async function getAllUsers() {
    try {
      iuState.on();
      const response = await userManagementRepository.getUsers();
      if (response.length) {
        const userOptions = response.map(data => {
          const options = {
            label: `${data.first_name} ${data.last_name}`,
            value: data.id,
          };
          return options;
        });
        setUsers(userOptions);
      }
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iuState.off();
    }
  }

  //upadate particular user
  async function createUser(createParams) {
    try {
      isucState.on();
      await userManagementRepository.createUser(createParams);
      const toastProps = {
        header: 'Success!',
        description: `User created successfully`,
      };
      successToast(toast, toastProps);
    } catch (e) {
      const response = e.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        if (response.errors[0] || response.errors) {
          const keys = response.errors[0]
            ? Object.values(response.errors[0])
            : Object.values(response.errors);
          toast.showError({
            description: keys.map(key => {
              return (
                <span>
                  {key}
                  <br />
                </span>
              );
            }),
          });
        }
      }
      return response;
    } finally {
      isucState.off();
    }
  }

  //upadate particular user
  async function updateUser(userId, updateParams) {
    try {
      isucState.on();
      const response = await userManagementRepository.updateUser(
        userId,
        updateParams
      );
      const toastProps = {
        header: 'Success!',
        description: `User updated successfully`,
      };
      successToast(toast, toastProps);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
        position: 'top-right',
      });
      throw e;
    } finally {
      isucState.off();
    }
  }

  //get separate user
  async function getUser(userId) {
    try {
      isuState.on();
      const response = await userManagementRepository.getUser(userId);
      setUserData(response);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      isuState.off();
    }
  }
  const deleteUser = async userId => {
    try {
      isuState.on();
      await userManagementRepository.deleteUser(userId);
      navigate(ROUTES.TEAM);
      const toastProps = {
        header: 'Success!',
        description: `User deleted successfully`,
      };
      successToast(toast, toastProps);
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    } finally {
      isuState.off();
    }
  };
  const getTeam = async teamId => {
    try {
      const response = await teamRepository.getTeam(teamId);
      setCurrentTeam(response);
      return response;
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    }
  };

  const handleApplyFilter = selectedFilters => {
    setFilterParams(selectedFilters);
  };
  const getAuditUpload1 = usePagination(getAuditUpload);
  const getAuditNewUploads1 = usePagination(getAuditNewUploads);
  const getAuditArchived1 = usePagination(getAuditArchived);
  const getAuditRebuttals1 = usePagination(getAuditRebuttals);

  const getChartAccuracy = async filters => {
    try {
      ifadState.on();
      const response = await userManagementRepository.getChartAccuracy({
        ...chartFilters,
        ...filters,
      });
      setAccuracyData(response);
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    } finally {
      ifadState.off();
    }
  };

  const getAverageAuditHours = async filters => {
    try {
      iahState.on();
      const response = await userManagementRepository.getAverageAuditHours({
        ...chartFilters,
        ...filters,
      });
      setAvgHours(response);
    } catch (e) {
      const message = e.response?.data?.message;
      toast.showError({
        description: message,
      });
    } finally {
      iahState.off();
    }
  };

  const getTeams = async () => {
    try {
      igtlState.on();
      const response = await userManagementRepository.getTeams();
      const teamsOptions = await response?.results.map(data => {
        const options = {
          label: toTitleCase(data.name),
          value: data.id,
          isFixed: +params.teamId === data.id,
        };
        return options;
      });
      setTeams(teamsOptions);
      return response.results;
    } catch (error) {
      toast.showError({
        description: 'something went wrong',
      });
    } finally {
      igtlState.off();
    }
  };

  const mContext = {
    isUserLoading,
    updateCurrentUser,
    getAllUsers,
    getAllSpecialties,
    specialties,
    users,
    isUserList,
    isSpecialtyList,
    updateUser,
    createUser,
    isLoading,
    getUser,
    userData,
    deleteUser,
    getTeam,
    currentTeam,

    isAuditUploadListLoading,
    getAuditUpload: getAuditUpload1,
    getAuditNewUploads: getAuditNewUploads1,
    getAuditArchived: getAuditArchived1,
    getAuditRebuttals: getAuditRebuttals1,

    auditUploadList,
    auditNewUploadsList,
    auditArchivedList,
    auditRebuttalsList,

    auditUploadListCount,
    auditNewUploadsListCount,
    auditArchivedListCount,
    auditRebuttalsListCount,
    handleApplyFilter,
    filterParams,

    chartFilters,
    setChartFilters,
    getChartAccuracy,
    getAverageAuditHours,
    accuracyData,
    avgHours,

    getTeams,
    teams,
    isGettingteamList,

    //
    isFetchingAccuracy,
    isFetchingAuditHours,
  };

  return (
    <UserManagementContext.Provider value={mContext}>
      {props.children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
