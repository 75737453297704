const STATUS_FILTER = {
  onHold: 'ON HOLD',
  awaitingAudit: 'AWAITING AUDIT',
  inProgress: 'IN PROGRESS',
  inReview: 'IN REVIEW',
  awaitingReview: 'AWAITING REVIEW',
  archived: 'ARCHIVED',
  awaitingAssignment: 'AWAITING ASSIGNMENT',
  qaRebuttal: 'QA REBUTTAL',
  clientRebuttal: 'CLIENT REBUTTAL',
};
export const ALL = [
  'AWAITING AUDIT',
  'IN PROGRESS',
  'AWAITING REVIEW',
  'AWAITING ASSIGNMENT',
  'ARCHIVED',
  'IN REVIEW',
  'ON HOLD'
]
export const NEW_UPLOAD = [
  'AWAITING ASSIGNMENT',
];
export const ARCHIVED = 'ARCHIVED'
export const REBUTTAL = ['QA REBUTTAL', 'CLIENT REBUTTAL']

export default STATUS_FILTER;
