import {
  Box,
  Button,
  Checkbox,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { useState } from 'react';

const DeleteModal = ({
  isDeleteLoading,
  isDeleteMoodleLoading,
  onDelete,
  onMoodleDelete,
  isDeleteModalOpen,
  closeDeleteModal,
  name,
  isChecked,
  icState,
  condition,
  moodleCondition,
  moodleAccountPresent,
}) => {
  const [isMoodleChecked, setIsMoodleChecked] = useState(false);
  return (
    <>
      <Modal
        isOpen={isDeleteModalOpen}
        size={'lg'}
        onClose={() => closeDeleteModal()}
        isCentered
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        <ModalContent p={10} style={{ alignItems: 'center', maxWidth: '700px', width: '90%' }}>
          <ModalHeader>
            <Text
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="medium"
              fontSize={'lg'}
              mb={8}
              color={AppColors.black}
            >
              Are you sure you want to delete
              <Text fontWeight={'bold'}>"{name}" ?</Text>
            </Text>
            <Stack spacing={4} mb={6} align="flex-start">
              {moodleAccountPresent && (
                <Checkbox
                  size="md"
                  colorScheme={'secondary'}
                  onChange={e => setIsMoodleChecked(e.target.checked)}
                  isChecked={isMoodleChecked}
                >
                  <Text
                    fontSize={'sm'}
                    fontWeight={'extrabold'}
                    textAlign={'center'}
                  >
                    {moodleCondition}
                  </Text>
                </Checkbox>
              )}
              <Checkbox
                size="md"
                colorScheme={'secondary'}
                onChange={e => icState.toggle()}
                checked={isChecked}
              >
                <Text
                  fontSize={'sm'}
                  fontWeight={'extrabold'}
                  textAlign={'center'}
                >
                  {condition}
                </Text>
              </Checkbox>
            </Stack>
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-14}
            right={-10}
          >
            <Circle>
              <ModalCloseButton
                size={'md'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={closeDeleteModal}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalBody mb={2}>
            <HStack>
              <Button
                mr={1}
                color={'black'}
                borderColor={AppColors.secondary}
                bg={'white'}
                borderWidth={1}
                fontSize="sm"
                borderRadius="full"
                height={'50px'}
                onClick={() => {
                  closeDeleteModal();
                }}
                _hover={{
                  bg: AppColors.white,
                }}
                px={14}
              >
                Cancel
              </Button>
              <Button
                borderRadius="full"
                color="white"
                fontSize="sm"
                height={'50px'}
                bg={AppColors.red}
                px={14}
                isLoading={isDeleteLoading || isDeleteMoodleLoading}
                disabled={!isChecked}
                _hover={{
                  bg: AppColors.red,
                }}
                onClick={async () => {
                  if (isMoodleChecked) {
                    await onMoodleDelete();
                  }
                  await onDelete();
                  icState.off();
                }}
              >
                Delete
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export { DeleteModal };
