const isPageEditable = (audit) => {
    if (audit.status === 'AWAITING ASSIGNMENT' || audit.status === 'AWAITING AUDIT') {
        return true
    }
    if (audit.status === 'ON HOLD' && !audit.updated_date) {
        return true
    }
    if (audit.file_obj.name.includes('.hl7')) {
        return true
    }
    return false
}
export { isPageEditable }