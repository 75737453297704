import { Box, Text, Center } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import { defaultReactTableProp, getCellProps } from '../../../../utils/error_parameter_table.util'
import { createColumnHelper } from '@tanstack/react-table';
import { ReportContext } from '../../../../providers/ReportProvider';
import { ERROR_PARAMETER } from '../../constants';
import { CQReactTable } from '@laxmimanogna/code-quick-components';

const columnHelper = createColumnHelper();

const ErrorParameterTable = ({ selectedErrors }) => {
    const reportContext = useContext(ReportContext)
    const { weekErrorTableData, isWeekErrorTableLoading, currentMonth } = reportContext

    const columns = useMemo(() => [
        columnHelper.accessor('error_parameter', {
            header: () => 'Error Parameter',
            id: 'errorParameter',
            enableSorting: false,
            cell: info => {
                const cellValue = info.getValue();

                return <Text>
                    {ERROR_PARAMETER[cellValue]}
                </Text>
            },
        }),
        columnHelper.accessor('current_month', {
            id: 'current_month',
            enableSorting: false,
            header: () => <span>{currentMonth} - MTD </span>,
            cell: info => {
                const cellValue = info.getValue();
                if (!cellValue) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('week1', {
            id: 'week1',
            enableSorting: false,
            header: () => <span>Week 1</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('week2', {
            id: 'week2',
            enableSorting: false,
            header: () => <span>Week 2</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('week3', {
            id: 'week3',
            enableSorting: false,
            header: () => <span>Week 3</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('week4', {
            id: 'week4',
            enableSorting: false,
            header: () => <span>Week 4</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text >
                    {cellValue}
                </Text>
            },
        }),
        columnHelper.accessor('week5', {
            id: 'week5',
            enableSorting: false,
            header: () => <span>Week 5</span>,
            cell: info => {
                const cellValue = info.getValue();
                if (cellValue === null) {
                    return 'N/A';
                }

                return <Text>
                    {cellValue}
                </Text>
            },
        }),
    ], [currentMonth]);


    if (selectedErrors && !selectedErrors.length) {
        return <></>
    }
    return <Box mt={7}>
        <CQReactTable
            data={weekErrorTableData}
            isLoading={isWeekErrorTableLoading}
            columns={columns}
            {...defaultReactTableProp}
            getCellProps={(cell, data) => getCellProps(cell, data, weekErrorTableData.length)}
            tableContainerProps={{
                borderRadius: '2xl'
            }}
        />
        {!isWeekErrorTableLoading && !weekErrorTableData.length ? <Center mt={10}> No data available</Center> : null}

    </Box>
}
export default ErrorParameterTable