/* eslint-disable react-hooks/exhaustive-deps */
import { CQSelect } from '@laxmimanogna/code-quick-components';
import React from 'react';
import { toTitleCase } from '../../../utils/common.utils';

const SplitAuditSpecialtyRow = ({ onSpecialtySelect, selectedValue, currentUpload }) => {
    const specialties = currentUpload.client_name.specialties.map(s => ({ label: s.name !== 'ent' ? toTitleCase(s.name) : 'ENT', value: s.id }))
    return (
        <CQSelect
            options={specialties}
            variant="primary"
            isSearchable={false}
            value={selectedValue}
            placeholder={'Select'}
            onChange={e => {
                onSpecialtySelect(e.value)
            }}
            styles={{
                control: (s) => ({
                    ...s,
                    borderRadius: 7,
                    height:50,
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 'sm'
                })
            }}
        />
    );
};

export default SplitAuditSpecialtyRow;
