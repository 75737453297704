/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { CQReactTable } from '@laxmimanogna/code-quick-components';
import { UrgentIcon } from '../../../../constants/IconData';

import { createColumnHelper } from '@tanstack/react-table';
import {
  getAuditStatus,
  MY_AUDITS_ORDERING,
} from '../../../../constants/constants';
import useCQMediaQuery from '../../../../hooks/useCQMediaQuery';
import { format } from 'date-fns';

import '../style.css';
import { MyAuditContext } from '../../../../providers/MyAuditProvider';
import { renderActionComponent } from '../MyAuditsComponents';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { useNavigate } from 'react-router-dom';
import { toTitleCase } from '../../../../utils/common.utils';

const columnHelper = createColumnHelper();

const MyNewUploadTable = () => {
  const myAuditContext = useContext(MyAuditContext);
  const navigate = useNavigate();
  const [ordering, setOrdering] = useState('');

  // media query
  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  const { isUpdating, getAuditNewUploads, filterParams } = myAuditContext;

  useEffect(() => {
    let searching = !!(filterParams.searchText && filterParams.searchText.trim());
    getAuditNewUploads.loadAPI("My Audit", "New Uploads", searching);
  }, [filterParams, getAuditNewUploads.showOrdering]);

  useEffect(() => {
    if (MY_AUDITS_ORDERING.includes(ordering)) {
      getAuditNewUploads.setParams({ ordering });
    }
  }, [ordering]);
  const columns = useMemo(
    () => [
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'chart_id',
        cell: info => {
          return (
            <HStack
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={10}
            >
              {info.row.original?.urgent ? <UrgentIcon /> : <Box ml={2} />}
              <Text>{info.renderValue()}</Text>
            </HStack>
          );
        },
      }),
      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => <span>Upload Date</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor(row => row.total_page, {
        id: 'total_pages',
        header: () => <span>Pages</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }

          return cellValue;
        },
      }),
      columnHelper.accessor(p => p.specialties?.name, {
        header: () => 'Specialties',
        id: 'specialty__name',
        cell: info => {
          const specialties = info.row.original.specialties?.name;

          if (!specialties) {
            return 'N/A';
          }
          return specialties === 'ent' ? 'ENT' : toTitleCase(specialties);
        },
      }),

      columnHelper.accessor('status', {
        id: 'status',
        header: () => 'Status',
        cell: info => <Text>{getAuditStatus(info.row.original?.status)}</Text>,
      }),

      columnHelper.accessor(row => row, {
        id: 'actions',
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const obj = info.getValue();
          return renderActionComponent(obj, navigate, myAuditContext);
        },
      }),
    ],
    [myAuditContext.isAuditUploadListLoading]
  );

  function handlePreviousPage() {
    myAuditContext.getAuditNewUploads.loadPrevious("My Audit", "New Uploads");
  }

  function handleNextPage() {
    myAuditContext.getAuditNewUploads.loadNext("My Audit", "New Uploads");
  }

  function handleSizeChange(size) {
    myAuditContext.getAuditNewUploads.loadSize("My Audit", "New Uploads", size );
  }

  function handlePageChangeByNumber(pageNumber) {
    myAuditContext.getAuditNewUploads.loadPageByNumber("My Audit", "New Uploads", pageNumber);
  }

  return (
    <Box
      pointerEvents={isUpdating ? 'none' : 'inherit'}
      overflowX={canScroll ? 'scroll' : 'inherit'}
    >
      <CQReactTable
        manualSortBy={true}
        isLoading={myAuditContext.isAuditUploadListLoading}
        data={myAuditContext.auditNewUploadsList}
        columns={columns}
        {...defaultReactTableProp}
        {...paginationProps(getAuditNewUploads)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onPageChangeByNumber={handlePageChangeByNumber}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
    </Box>
  );
};
export default MyNewUploadTable;
