import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';
import AppColors from '../../../constants/AppColors';
import theme from '../../../constants/Theme';

const DeleteHealthSystemAlert = ({
  isOpen,
  onClose,
  healthSystemName,
  onConfirm,
  isDeleteLoading,
  isUnderstood,
  iuState,
}) => {
  const [autoValidate, avState] = useBoolean(false);

  function handleConfirmation() {
    avState.on();

    if (!isUnderstood) {
      return;
    }

    if (onConfirm && typeof onConfirm === 'function') {
      onConfirm();
      iuState.off();
    }
  }

  const hasError = autoValidate && !isUnderstood;

  return (
    <Modal isOpen={isOpen} size={'lg'} onClose={onClose} isCentered>
      <ModalOverlay bg={AppColors.modalOverlayColor} />
      <ModalContent alignItems={'center'}>
        <ModalHeader fontSize={'xl'} fontFamily={theme.fonts.heading}>
          <Center textAlign={'center'} fontWeight="bold">
            Are you sure you want to delete
          </Center>

          <Center textAlign={'center'} fontWeight="bold">
            {healthSystemName} ?
          </Center>
        </ModalHeader>
        <Box
          style={{
            zIndex: '1',
          }}
          position={'absolute'}
          top={-10}
          right={-10}
        >
          <Circle>
            <ModalCloseButton
              size={'sm'}
              style={{ color: AppColors.white }}
              backgroundColor={'#8894A6'}
              p={3}
              onClick={onClose}
              borderRadius={50}
            />
          </Circle>
        </Box>

        <ModalBody mx={16}>
          <Checkbox
            isInvalid={hasError}
            checked={isUnderstood}
            onChange={iuState.toggle}
          >
            <Text fontWeight={'bold'} fontSize={'xs'}>
              I understand this will remove all hospitals, departments and
              physicians related to this Health System.
            </Text>
          </Checkbox>

          {hasError && (
            <Text color={'red.400'} as={'i'} fontSize={'xs'}>
              Please acknowledge this box to proceed
            </Text>
          )}
        </ModalBody>

        <ModalFooter my={4}>
          <ButtonGroup>
            <Button
              size={'sm'}
              px={12}
              onClick={onClose}
              height={'50px'}
              rounded={'full'}
              outline={`2px solid ${AppColors.secondary}`}
              color={AppColors.secondary}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.secondary,
                color: AppColors.white,
              }}
              variant={'outline'}
              fontWeight={'normal'}
            >
              Cancel
            </Button>
            <Button
              size={'sm'}
              px={12}
              onClick={handleConfirmation}
              height={'50px'}
              outline={`2px solid ${AppColors.red}`}
              color={AppColors.white}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.red,
              }}
              disabled={!isUnderstood}
              isLoading={isDeleteLoading}
              rounded={'full'}
              textColor={'#fff'}
              bgColor={AppColors.red}
              fontWeight={'normal'}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(DeleteHealthSystemAlert);
