import React from "react";
import { ReactOneDriveFilePicker } from "react-onedrive-filepicker";
import { MsOneDriveIcon } from "../../../constants/IconData";

const OneDriveUpload = ({children, uploadCharts}) => {

  const downloadFile = (files) => {
      const fetchPromises = files.map(file => 
        fetch(file.downloadUrl)
          .then(response => response.blob())
          .then(blob => {
            const driveFile = new File([blob], file.name, { type: blob.type });
            return driveFile; // Return the file to be collected
          })
          .catch(error => {
            console.error('Error fetching One Drive file:', error);
            return null; // Return null on error to filter out later
          })
      );
  
      Promise.all(fetchPromises)
        .then(uploadFiles => {
          uploadFilesInDropZone(uploadFiles.filter(file => file)); // Filter out any null values
        })
        .catch(error => {
          console.error('Error in fetching all files:', error);
        });
  };

  const uploadFilesInDropZone = async (uploadFiles) => {
    if (uploadFiles.length > 0) {
      await uploadCharts(uploadFiles);
    }
  }


  return (
    <ReactOneDriveFilePicker
        clientID={process.env.REACT_APP_MS_ONE_DRIVE}
        action="download"
        multiSelect={true}
        advanced= {{filter: ".pdf,.doc,.docx,.xlsx,.xls,.hl7"}}
        onSuccess={(result) => {
            console.log(result)
            const files = result.value.map(doc => ({
              name: doc.name,
              downloadUrl: doc['@microsoft.graph.downloadUrl'],
            }));
            downloadFile(files);
        }}
        onCancel={(result) => {
            console.log(result)
        }}
        >
        {children}
    </ReactOneDriveFilePicker>
  );
};

export default OneDriveUpload;