/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
function equalTo(ref, msg) {
	return this.test({
		name: 'equalTo',
		exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
      return value === this.resolve(ref) 
		}
	})
};

yup.addMethod(yup.string, 'equalTo', equalTo);
const FORGOT_PASSWORD_SCHEMA = yup.object({
    email: yup.string().required('Email is required').default('tdmanager@mailinator.com'),
})
const LOGIN_SCHEMA = yup.object({
    email: yup.string().required('Email is required').default('tdmanager@mailinator.com'),
    password: yup.string().required('Password is required').default('test@123')
})
const RESET_SCHEMA = yup.object({
    newPassword: yup.string().required('New Password is required'),
    confirmPassword: yup.string().required('Confirm Password is required').equalTo(yup.ref('newPassword'))
})
export { FORGOT_PASSWORD_SCHEMA, LOGIN_SCHEMA, RESET_SCHEMA }