/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import { Text, HStack, Spacer } from '@chakra-ui/react';
import { PrimaryButton } from '@laxmimanogna/code-quick-components';
import AppColors from '../../constants/AppColors';
import { BackArrowIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { useNavigate, useParams } from 'react-router-dom';
import UserProfile from './components/UserProfile';
import UserManagementProvider, { UserManagementContext } from '../../providers/UserManagementProvider';

const UserProfileContainer = props => {
    const { teamId, userId } = useParams();
    const navigate = useNavigate();
    const userContext = useContext(UserManagementContext);
    const { currentTeam, userData, getTeam, getUser, getChartAccuracy, getAverageAuditHours } = userContext

    useEffect(() => {
        if (teamId && userId) {
            getTeam(teamId)
            getUser(userId)
            // getChartAccuracy({ team: teamId })
            // getAverageAuditHours({ team: teamId })
            getChartAccuracy({ user: userId })
            getAverageAuditHours({ user: userId })
        }
    }, [])

    return (
        <>
            <CQContainer py={30} px={30}>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/teams`}
                        >
                            <Text
                                fontFamily={FONT_FAMILY.baiJamurjee}
                                fontWeight="600"
                                fontSize={'xl'}
                            >
                                Team
                            </Text>
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/teams/${currentTeam.id}`}> {currentTeam.name}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="#"> {userData.first_name} {userData.last_name}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <HStack my={4}>
                    <BackArrowIcon style={{ width: 40, height: 40, cursor: 'pointer' }} onClick={() => {
                        navigate(ROUTES.TEAM);
                    }} />
                    <PrimaryButton
                        fontSize={'md'}
                        fontWeight="bolder"
                        size="md"
                        borderRadius="3xl"
                        px={10}
                        variant="link"
                        fontFamily={FONT_FAMILY.ptSans}
                        color={AppColors.secondary}
                    >
                        Back
                    </PrimaryButton>
                    <Spacer />
                    <HStack>
                        <PrimaryButton
                            bg={AppColors.primary}
                            fontSize={'sm'}
                            fontWeight="normal"
                            size="md"
                            borderRadius="3xl"
                            px={10}
                            onClick={() => {
                                navigate(ROUTES.EDIT_USER.replace(':userId', userId));
                            }}
                        >
                            Edit User Details
                        </PrimaryButton>
                    </HStack>
                </HStack>
                <Box w="100%" h="150%" borderRadius="md" ml={0}>
                    <UserProfile teamId={teamId} userId={userId} />
                </Box>
            </CQContainer>
        </>
    );
};

export default withProvider(UserManagementProvider, UserProfileContainer);
