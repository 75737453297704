/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Breadcrumb, BreadcrumbItem, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { BackArrowIcon } from '../../constants/IconData';
import ROUTES from '../../constants/Routes';
import { FONT_FAMILY } from '../../constants/Theme';
import { replaceRoute, toTitleCase } from '../../utils/common.utils';
import AccountsChartSection from './sections/AccountsChartSection';
import AuditAccuracySection from './sections/AuditAccuracySection';
import MetricSection from './sections/MetricSection';
import StatisticsSection from './sections/StatisticsSection';
import ViewProfileBtn from './components/ViewProfileBtn';

const PhysicianViewContainer = (props) => {
  const params = useParams();
  const location = useLocation()
  const { accountId, hospitalId, departmentId, physicianId } = params;

  const queryParam = useMemo(() => ({ provider_id: physicianId }), []);

  // columns
  const statisticRows = [{
    label: 'Uploads',
    accessor: 'total_audits'
  }, {
    label: 'RI Industry',
    accessor: 'in_progress'
  }, {
    label: 'RI Link',
    accessor: 'completed'
  }, {
    label: 'Organization Rank',
    accessor: 'open_rebuttals'
  }];

  function renderBreadCrumb() {
    const accountViewRoute = replaceRoute(ROUTES.GET_ACCOUNT, { accountId: accountId });
    const hospitalViewRoute = replaceRoute(ROUTES.GET_ACCOUNT_HOSPITAL, { accountId, hospitalId });
    const departmentViewRoute = replaceRoute(ROUTES.GET_ACCOUNT_DEPARTMENT, { accountId, hospitalId, departmentId });

    return (
      <Breadcrumb mb={5}>
        <BreadcrumbItem>
          <NavLink to={ROUTES.ACCOUNTS}>
            <Text
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="bold"
              fontSize={'2xl'}
              _hover={{ textDecoration: 'underline' }}
            >
              Accounts
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <NavLink to={accountViewRoute} state={location.state}>
            <Text 
            fontFamily={FONT_FAMILY.baiJamurjee}
            _hover={{ textDecoration: 'underline' }}
            >
              {toTitleCase(location.state?.accountName)}
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <NavLink to={hospitalViewRoute} state={location.state}>
            <Text 
            textTransform={'capitalize'}
            _hover={{ textDecoration: 'underline' }}
            >
            {toTitleCase(location.state?.hospitalName)}
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <NavLink to={departmentViewRoute} state={location.state}>
            <Text fontFamily={FONT_FAMILY.baiJamurjee}>
              {toTitleCase(location.state?.departmentName)}
            </Text>
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Text textTransform={'capitalize'}>{location.state?.physicianName}</Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  function renderBackToAccounts() {
    return (
      // Without HStack, this NavLink is occupying entire width
      <HStack
        w={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <NavLink to={ROUTES.ACCOUNTS}>
          <Box>
            <HStack my={2} spacing={'md'}>
              <BackArrowIcon style={{ width: 40, height: 40, cursor: 'pointer' }} />
              <Text
                fontSize={'md'}
                fontWeight="bold"
                size={'md'}
                mx={2}
                fontFamily={FONT_FAMILY.openSans}
                color={AppColors.secondary}
              >
                Back to All Accounts
              </Text>
            </HStack>
          </Box>
        </NavLink>
        <ViewProfileBtn queryParam={queryParam} />
      </HStack>
    );
  }

  function renderStatistics() {
    return (
      <GridItem>
        <VStack align={'stretch'} bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
          <StatisticsSection
            rows={statisticRows}
            sectionTitle={'Provider Statistics'}
            queryParam={queryParam}
          />
        </VStack>
      </GridItem>
    );
  }

  function renderAuditAccuracy() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <AuditAccuracySection
          queryParam={queryParam}
        />
      </GridItem>
    );
  }

  function renderMetric() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <MetricSection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderTable() {
    return (
      <GridItem colSpan={2}>
        <AccountsChartSection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderMainContent() {
    return (
      <Box>
        <Grid templateColumns="repeat(3, 1fr)" mt={4} gap={4}>
          {renderStatistics()}
          <GridItem colSpan={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              {renderAuditAccuracy()}
              {renderMetric()}
              {renderTable()}
            </Grid>
          </GridItem>
        </Grid>
      </Box>
    );
  }


  return (
    <CQContainer>
      {renderBreadCrumb()}
      {renderBackToAccounts()}
      {renderMainContent()}
    </CQContainer>
  );
};

export default PhysicianViewContainer;
