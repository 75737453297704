/* eslint-disable react-hooks/exhaustive-deps */
import AuditSheet from './components/AuditSheet';
import AuditSheetFooter from './components/AuditSheetFooter';
import Topbar from './components/Topbar';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import AppColors from '../../constants/AppColors';
import { withProvider } from '../../hoc/withProvider';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuditSheetProvider, {
  AuditSheetContext,
} from '../../providers/AuditSheetProvider';
import { createEmptyObject } from './components/createEmptyObject';
import { AWAITING_AUDIT, MANAGER } from '../../constants/constants';
import { sanitize } from './components/sanitize';
import SubmitModal from './components/SubmitModal';
import ROUTES from '../../constants/Routes';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { successToast } from '../../components/successToast';
import { submitRule } from './components/submitRules';
import CommentBox from './components/CommentBox';
import html2canvas from 'html2canvas';
import {
  arrangeData,
  createEmptyColumn,
  prepareUpdatePayload,
} from '../../utils/auditsheet.utils';

const AuditSheetContainer = ({ view }) => {
  const auditSheetCount = Math.ceil(window.innerHeight / 24);

  // refs
  const domEl = useRef(null);
  const topbar = useRef(null);
  const footer = useRef(null);

  // Booleans
  const [isLoading, ilState] = useBoolean(false);
  const [isOpen, ioState] = useBoolean(false);
  const [isCommentOpen, icoState] = useBoolean(false);
  const [rebuttalChecked, rcState] = useBoolean(true);

  // other hooks
  const { uploadId } = useParams(); // chartId
  const navigate = useNavigate();
  const toast = useToastr();

  // states
  const [lastSaved, setLastSaved] = useState('');
  const [sheets, setSheets] = useState([
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: createEmptyObject(auditSheetCount),
    },
  ]);
  const [activeSheetIndex, setActiveSheetIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [metaData, setMetaData] = useState({});
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  //context
  const auditSheetContext = useContext(AuditSheetContext);
  const {
    getAuditUpload,
    getAuditSheet,
    currentUpload,
    auditSheetColumnsKeys,
  } = auditSheetContext;
  const { getIndustryCodes, getUsers, users } = auditSheetContext;
  const { getAllUsers, getCommentFlag, providerOptions } = auditSheetContext;

  const sheetNames = sheets.map(s => s?.sheet_name);

  // get auditsheet user data
  const getUpload = async () => {
    if (uploadId) {
      const res = await getAuditUpload(uploadId);
      setLastSaved(res.updated_date);
    }
  };

  const onCommentClick = (selection, dimension, comment, canRebuttal) => {
    setMetaData({ ...dimension, selection, canRebuttal });

    if (comment) {
      icoState.on();
    }
  };

  const onZoom = value => {
    let temp = 1;
    if (value >= 50) {
      temp = (2 * value) / 100;
    } else if (value === 0) {
      temp = 0.3;
    } else {
      temp = (value / 100) * 2;
    }
    setScale(temp);
  };

  // sheets Data || fetch data
  const getSheet = async () => {
    if (
      uploadId &&
      Object.keys(currentUpload)?.length
      // currentUpload.status !== AWAITING_AUDIT
      // && providerOptions.length
    ) {
      const response = await getAuditSheet(uploadId, currentUpload);
      
      if (response?.length) {
        const updated = response.map((res, i) => {
          const _reDestructuredData = createEmptyColumn(res.data);

          const reDestructuredData = _reDestructuredData.map(sd => {
            const tempObj = { ...sd, ...sd.additional_attributes };
            tempObj['audited_cpt'] = tempObj['audited_code'];
            delete tempObj.additional_attributes;
            delete tempObj?.audited_code;

            if (tempObj?.enc_dt) {
                const parts = tempObj.enc_dt.split('-');
                const year = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const day = parseInt(parts[2], 10);
                const parsedDate = new Date(year, month, day);
          
                if (!isNaN(parsedDate.getTime())) {
                  const formattedDate = `${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
                  tempObj.enc_dt = formattedDate;
                }
            }
            if (!tempObj.hasOwnProperty('icd_agree')){
              tempObj['icd_agree'] = null
            }
            if (!tempObj.hasOwnProperty('icd_disagree')){
              tempObj['icd_disagree'] = null
            }

            return tempObj;
          });

          const _arrangeData = arrangeData(reDestructuredData);

          const updatedData = [
            ..._arrangeData,
            ...createEmptyObject(auditSheetCount - res.data.length),
          ];
          return {
            ...res,
            index: i + 1,
            data: updatedData,
          };
        });
        setSheets(updated);
      }
    }
  };

  // lifeCycle hooks
  useEffect(() => {
    auditSheetContext.fetchProviderDropdown(uploadId);
    getUpload();
    getIndustryCodes();
    getUsers();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (uploadId) {
      getCommentFlag(uploadId);
    }
  }, [uploadId]);

  React.useEffect(() => {
    getSheet();
  }, [currentUpload, providerOptions]);

  useEffect(() => {
    /**
     * sanitize() will add old_rendering and asign as id to rendering and remove empty rows
     */

    const sanitizedSheet = sanitize(sheets, providerOptions);

    disableSubmitFunc(sanitizedSheet);
  }, [sheets]);

  // functions and handlers

  function disableSubmitFunc(sanitizedSheet) {
    const isEverySanitizedSheetEmpty = sanitizedSheet.every(
      sheet => sheet.data.length === 0
    );

    if (isEverySanitizedSheetEmpty) {
      setSubmitDisabled(true);
    } else {
      const isSubmitable = submitRule(sanitizedSheet);
      setSubmitDisabled(isSubmitable);
    }
  }

  // on left arrow click
  function onLeftNavigate() {
    if (activeSheetIndex > 0) {
      setActiveSheetIndex(activeSheetIndex - 1);
    }
  }

  //  on right arrow click
  function onRightNavigate() {
    if (activeSheetIndex < sheets.length - 1) {
      setActiveSheetIndex(activeSheetIndex + 1);
    }
  }

  function updateSheet(sheetData) {
    sheets[activeSheetIndex].data = sheetData;
    setSheets([...sheets]);
  }

  function removeRows(rowIndexes) {
    const data = sheets[activeSheetIndex].data.map((obj, i) =>
      rowIndexes.includes(i) ? { id: null } : obj
    );
    sheets[activeSheetIndex].data = data;
    setSheets([...sheets]);
  }

  function onRebuttalClick() {
    if (metaData.canRebuttal) {
      icoState.on();
    }
  }

  function onOpenNotes(obj) {
    if (obj && Object.keys(obj).length) {
      if (obj.all) {
        delete auditSheetContext.commentFilters.sheet_name;
        auditSheetContext.setCommentFilters({
          ...auditSheetContext.commentFilters,
          page: 1,
        });
      } else if (obj.current) {
        auditSheetContext.setCommentFilters({
          ...auditSheetContext.commentFilters,
          sheet_name: sheets[activeSheetIndex].sheet_name,
          page: 1,
        });
      }
    } else {
      auditSheetContext.setCommentFilters({
        ...auditSheetContext.commentFilters,
        id: currentUpload.id,
        sheet_name: sheets[activeSheetIndex].sheet_name,
        reverse: true,
        page: 1,
      });
    }
  }

  // async functions

  async function downloadImage() {
    // download image
    function dataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',');
      const byteString =
        splitDataURI[0].indexOf('base64') >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    }
    const canvas = await html2canvas(domEl.current);
    const dataUrl = canvas.toDataURL('image/png', 1.0);
    return dataURIToBlob(dataUrl);
  }

  async function onAuditSubmit() {
    await auditSheetContext.monitorAuditHour(uploadId);

    const sanitizedSheet = sanitize(sheets, providerOptions);

    const payload = prepareUpdatePayload(sanitizedSheet);

    //  const payload= payloadreStructure(sanitizedSheet)

    const response = await auditSheetContext.updateAuditSheet(
      payload, //sanitizedSheet,
      uploadId
    );

    //submit screenshot
    const file = await downloadImage();
    const fd = new FormData();
    fd.append('image', file);
    await auditSheetContext.recentAuditImageUpload(uploadId, fd);

    if (response) {
      ioState.off();
      navigate(ROUTES.MY_AUDITS);
      const toastProps = {
        header: 'Success!',
        description: `${'Document submitted for review.'}`,
      };
      successToast(toast, toastProps);
    }
  }

  /**
   *
   * @param {Array} sheetParams
   * @returns
   */

  async function updateAuditSheet(sheetParams) {
    let sanitizedSheet = [];
    if (sheetParams) {
      sheets[activeSheetIndex] = sheetParams;
      sanitizedSheet = sanitize(sheets, providerOptions);
    } else {
      sanitizedSheet = sanitize(sheets, providerOptions);
    }

    const payload = prepareUpdatePayload(sanitizedSheet);

    try {
      // const reStructurePayload = payloadreStructure(sanitizedSheet);

      await auditSheetContext.createAuditSheet(payload, uploadId);
      await getSheet();
      setLastSaved(new Date());
    } catch (err) {}
  }

  async function addNewSheet() {
    const updatedSheets = [
      ...sheets,
      {
        index: sheets.length + 1,
        data: createEmptyObject(auditSheetCount),
        sheet_name: `Sheet${sheets.length + 1}`,
      },
    ];
    setSheets([...updatedSheets]);
    setActiveSheetIndex(sheets.length);
  }

  async function changeSheetName(index, name) {
    sheets[index] = { ...sheets[index], sheet_name: name };

    setSheets([...sheets]);

    const sanitizedSheet = sanitize(sheets, providerOptions);
    await auditSheetContext.createAuditSheet(sanitizedSheet, uploadId);
  }

  async function onDeleteSheet(index) {
    if (sheets.length > 1) {
      let sheetIndex = 1;
      let newSheet = [];
      sheets.forEach((p, i) => {
        if (index !== i) {
          newSheet.push({ ...p, index: sheetIndex });
          sheetIndex = sheetIndex + 1;
        }
      });
      setSheets([...newSheet]);
      if (activeSheetIndex === index && index > 0) {
        const data = activeSheetIndex - 1;
        setActiveSheetIndex(data);
      }
      const sanitizedSheet = sanitize(newSheet, providerOptions);
      await auditSheetContext.createAuditSheet(sanitizedSheet, uploadId);
    }
  }


  const [coords, setCoords] = useState({
    x: 0,
    y: 0,
  });

  function handleSheetClick(e) {
    setCoords({
      x:e.clientX,
      y:e.clientY
    })
  }

  // renders
  const renderComment = () => {
    return (
      <CommentBox
        showMenu={isCommentOpen}
        role={MANAGER}
        metaData={metaData}
        currentUpload={currentUpload}
        closeComment={() => {
          icoState.off();
          rcState.on();
        }}
        users={users}
        sheet={sheets[activeSheetIndex]}
        isSelected={rebuttalChecked}
        onRebuttalCheck={flag => {
          if (flag) {
            rcState.on();
          } else {
            rcState.off();
          }
        }}
        coords={coords}
      />
    );
  };

  const renederToolBar = () => {
    return (
      <Box w={'100%'} ref={topbar} top={0}>
        <Topbar
          onAuditSubmit={() => ioState.on()}
          updateAuditSheet={async params => {
            updateAuditSheet(params);
            //submit screenshot
            const file = await downloadImage();
            const fd = new FormData();
            fd.append('image', file);
            await auditSheetContext.recentAuditImageUpload(uploadId, fd);
          }}
          view={view}
          disableSubmit={isSubmitDisabled}
          lastSaved={lastSaved}
          onChange={on => {
            if (on) {
              ilState.on();
            } else {
              ilState.off();
            }
          }}
          onRebuttalClick={onRebuttalClick}
          onOpenNotes={onOpenNotes}
        />
      </Box>
    );
  };





  const renderAuditSheet = () => (
    <Box id="domEl" ref={domEl} onClick={handleSheetClick}>
      <AuditSheet
        height={
          window.innerHeight -
          topbar?.current?.clientHeight -
          footer?.current?.clientHeight
        }
        scale={scale}
        sheet={sheets[activeSheetIndex]}
        uploadId={uploadId}
        view={view}
        updateAuditSheet={updateAuditSheet} // create audit sheet(POST) and getSheets and update sheet
        updateSheet={updateSheet} // update the data in setState(setSheet)
        onCommentClick={onCommentClick}
        removeRows={removeRows}
        closeComment={() => {
          icoState.off();
          rcState.on();
        }}
      />
    </Box>
  );

  const renderFooter = () => {
    return (
      <Box w={'100%'} ref={footer} position={'fixed'} bottom={0}>
        <AuditSheetFooter
          onSheetChange={setActiveSheetIndex}
          sheets={sheetNames}
          activeSheetIndex={activeSheetIndex}
          addNewSheet={addNewSheet}
          changeSheetName={changeSheetName}
          onLeftNavigate={onLeftNavigate}
          onRightNavigate={onRightNavigate}
          onZoom={onZoom}
          onDeleteSheet={onDeleteSheet}
        />
      </Box>
    );
  };

  const renderSubmitModal = () => {
    return (
      <SubmitModal
        isOpen={isOpen}
        onClose={() => ioState.off()}
        onAuditSubmit={onAuditSubmit}
      />
    );
  };

  const renderLoader = () => {
    return (
      <Modal size={'sm'} isOpen={isLoading} isCentered bgColor={'transparent'}>
        <ModalOverlay style={{ backgroundColor: AppColors.lightSkyBlue }} />
        <ModalContent bgColor={'transparent'} shadow={'none'}>
          <ModalBody style={{ alignSelf: 'center' }}>
            <Spinner />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const renderMainContent = () => {
    return (
      <Box bgColor={AppColors.bgColor}>
        {renederToolBar()}

        {renderAuditSheet()}

        {renderFooter()}

        {renderSubmitModal()}

        {renderLoader()}

        {renderComment()}
      </Box>
    );
  };

  return renderMainContent();
};
export default withProvider(AuditSheetProvider, AuditSheetContainer);
