import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import AppColors from '../../constants/AppColors';

export default function CQFooter() {
  const presentYear = new Date().getFullYear();

  return (
    <Box
      w={'100%'}
      py={5}
      alignItems={'center'}
      textAlign={'center'}
      bg={useColorModeValue('#EBEBEE', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <HStack
        w={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text>Copyright © {presentYear} </Text>
        <Text fontWeight={'bold'} color={AppColors.secondary}>
          RevIntegrity
        </Text>
      </HStack>
    </Box>
  );
}
