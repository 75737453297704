import { Box, Checkbox, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar"
import AppColors from "../../../../constants/AppColors";
import { FONT_FAMILY } from "../../../../constants/Theme";

const SelectMenu = (props) => {

    const { selected, setSelected } = props
    const [selectedOption, setSelectedOption] = useState(selected)

    return <PerfectScrollbar
        style={{
            maxHeight: '130px',
            maxWidth: '100%',
            marginTop: 5,
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.05)',
            zIndex: 10,
            backgroundColor: 'white'
        }}
    >
        {props.options.length ? props.options.map((option, i) => {
            return <Box p={3} m={1} key={`${i}-menu list`} zIndex={10}
            >
                <Checkbox size='md' colorScheme={'brand'} onChange={(e) => {
                    if (e.target.checked) {
                        if (!selectedOption.includes(option.value)) {
                            setSelected([...selectedOption, option.value])
                            setSelectedOption([...selectedOption, option.value])
                        }
                    } else {
                        if (selectedOption.includes(option.value)) {
                            const temp = selectedOption.filter(d => d !== option.value)
                            setSelected(temp)
                            setSelectedOption(temp)
                        }
                    }

                }} defaultChecked={selectedOption.includes(option.value)}>
                    <Text ml={2} fontFamily={FONT_FAMILY.ptSans} fontSize={14} color={AppColors.secondary} fontWeight={'bold'}>
                        {option.label}
                    </Text>
                </Checkbox>
            </Box>
        }) : <Box p={3} m={1} bg={AppColors.loginFormBg}>
            <Text fontFamily={FONT_FAMILY.ptSans} fontSize={14} color={AppColors.black} textAlign='center'>No Options</Text>
        </Box>}
    </PerfectScrollbar>
}
export default SelectMenu