export const SPECIFICS = {
    audited: 'Audited',
    error: 'Error',
    cq_score_percentage: 'RI Accuracy %',
    error_percentage: 'Error %'
}
export const ERROR_PARAMETER = {
    "CPT": "CPT",
    "E&M": "E&M",
    "ICD": "ICD",
    "Client_Specification": "Client Specification",
    "Provider": "Provider",
    "Modifier": "Modifier",
    "Procedure": "Procedure",
    "Vaccine": "Vaccine",
    "MIPS": "MIPS",
    "Total": "Total"

}
export const SELECTED_SPECIFICS = ["cq_score_percentage", "error_percentage"]