import {
  useBoolean,
  useToastr,
  usePagination,
  paginationReturnType,
} from '@laxmimanogna/code-quick-components';
import React, { createContext, useContext, useState } from 'react';
import auditRepository from '../repositories/AuditRepository';
import { ALL, ARCHIVED, NEW_UPLOAD, REBUTTAL } from '../constants/Filter';
import { toTitleCase } from '../utils/common.utils';
import { AuthContext } from '../providers/AuthProvider';
import { successToast } from '../components/successToast';
import { AUDITED } from '../constants/constants';

export const AuditContext = createContext({
  isAuditUploadListLoading: false,
  isUpdating: false,
  isUserList: false,
  isSpecialtyList: false,

  auditUploadList: [],
  specialties: [],
  users: [],
  auditArchivedList: [],
  auditNewUploadsList: [],
  auditRebuttalsList: [],
  auditedList: [],

  getAuditUpload: paginationReturnType,
  getAuditNewUploads: paginationReturnType,
  getAuditArchived: paginationReturnType,
  getAuditRebuttals: paginationReturnType,
  getAudited: paginationReturnType,

  getAllSpecialties: async () => { },
  getAllUsers: async () => { },
  requestMissingInfo: async () => { },
  markAsUrgent: async () => { },
  getAuditorList: async () => { },
  loadSpecialtyAuditors: async () => { },
  deleteUploadedFile: async () => { },
  assignSelf: async auditRowID => { },

  isDeleteUpload: false,
  isReqMissing: false,
  isMarkedAsUrgent: false,
  isUpdatingAuditorList: false,
  isAssigningChartToSelf: false,

  isSplitAudit: false,
  createSplitAudit: async () => { },

  isFetchingNewUploads: false,
  isfettchingAuditArchived: false,
  isFetchingRebuttals: false,
  isFetchingAudit: false,
});
const initialFilterParams = {
  newUploadStatus: '',
  searchText: '',
  ordering: '',
};

const AuditProvider = props => {
  const toast = useToastr();

  const authContext = useContext(AuthContext);

  const [isAuditUploadListLoading, iaState] = useBoolean(false);
  const [isUpdating, iu1State] = useBoolean(false);
  const [isUserList, iuState] = useBoolean(false);
  const [isSpecialtyList, isState] = useBoolean(false);
  const [isUpdatingAuditorList, ialState] = useBoolean(false);
  const [isSplitAudit] = useBoolean(false);
  const [isDeleteUpload, idState] = useBoolean(false);
  const [isAssigningChartToSelf, iactsState] = useBoolean(false);

  const [isReqMissing, rmState] = useBoolean(false);
  const [isMarkedAsUrgent, imState] = useBoolean(false);

  const [isFetchingNewUploads, ifnuState] = useBoolean(false);

  const [auditUploadList, setAuditUploadList] = useState([]);
  const [auditNewUploadsList, setAuditNewUploadsList] = useState([]);
  const [auditArchivedList, setAuditArchivedList] = useState([]);
  const [auditRebuttalsList, setAuditRebuttalsList] = useState([]);
  const [isfettchingAuditArchived, ifaaState] = useBoolean(false);
  const [auditedList, setAuditedList] = useState([]);
  const [isFetchingRebuttals, ifrState] = useBoolean(false);

  const [specialties, setSpecialties] = useState([]);
  const [users, setUsers] = useState([]);

  const [auditUploadListCount, setAuditUploadListCount] = useState(0);
  const [auditNewUploadsListCount, setAuditNewUploadsListCount] = useState(0);
  const [auditArchivedListCount, setAuditArchivedListCount] = useState(0);
  const [auditRebuttalsListCount, setAuditRebuttalsListCount] = useState(0);
  const [auditedListCount, setAuditedListCount] = useState(0);

  const getAuditUpload1 = usePagination(getAuditUpload);
  const getAuditNewUploads1 = usePagination(getAuditNewUploads);
  const getAuditArchived1 = usePagination(getAuditArchived);
  const getAuditRebuttals1 = usePagination(getAuditRebuttals);
  const getAudited1 = usePagination(getAudited);
  const [isFetchingAudit, ifaState] = useBoolean(false);

  const [filterParams, setFilterParams] = useState(
    Object.assign({}, initialFilterParams)
  );

  const getFilterParams = () => {
    const queryParams = {};
    const { searchText, ordering } = filterParams;
    if (searchText) queryParams['search'] = searchText;
    if (ordering) queryParams['ordering'] = ordering;
    return queryParams;
  };

  async function getAuditUpload(paginationParams) {
    const filterParams = getFilterParams();
    if (ALL) {
      filterParams.status = ALL;
    }
    try {
      iaState.on();
      const response = await auditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditUploadListCount(response.count);
      setAuditUploadList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iaState.off();
    }
  }

  async function getAuditNewUploads(paginationParams) {
    const filterParams = getFilterParams();
    if (NEW_UPLOAD) {
      filterParams.status = NEW_UPLOAD;
    }
    try {
      ifnuState.on();
      const response = await auditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditNewUploadsListCount(response.count);
      setAuditNewUploadsList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      ifnuState.off();
    }
  }

  async function getAuditArchived(paginationParams) {
    const filterParams = getFilterParams();
    if (ARCHIVED) {
      filterParams.status = ARCHIVED;
    }
    try {
      ifaaState.on();
      const response = await auditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditArchivedListCount(response.count);
      setAuditArchivedList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      ifaaState.off();
    }
  }

  async function getAuditRebuttals(paginationParams) {
    const filterParams = getFilterParams();
    if (REBUTTAL) {
      filterParams.status = REBUTTAL;
    }
    try {
      ifrState.on();
      const response = await auditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditRebuttalsListCount(response.count);
      setAuditRebuttalsList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      ifrState.off();
    }
  }

  async function getAudited(paginationParams) {
    const filterParams = getFilterParams();
    if (AUDITED) {
      filterParams.status = AUDITED;
    }
    try {
      ifaState.on();
      const response = await auditRepository.getAuditUploads({
        ...paginationParams,
        ...filterParams,
      });
      setAuditedListCount(response.count);
      setAuditedList(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      ifaState.off();
    }
  }
  async function getAllSpecialties() {
    try {
      isState.on();
      const response = await auditRepository.getSpecialties();
      setSpecialties(response);
      if (response.length) {
        const specialtiesOptions = response.map(data => {
          const options = {
            label: data.name === 'ent' ? 'ENT' : toTitleCase(data.name),
            value: data.id,
          };
          return options;
        });
        setSpecialties(specialtiesOptions);
      }
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      isState.off();
    }
  }

  async function getAllUsers() {
    try {
      iuState.on();
      const response = await auditRepository.getUsers();
      if (response.length) {
        const userOptions = response.map(data => {
          const options = {
            ...data,
            label: toTitleCase(`${data.first_name} ${data.last_name}`),
            value: data.id,
          };
          return options;
        });
        setUsers(userOptions);
      }
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });
    } finally {
      iuState.off();
    }
  }
  // update specialties
  async function updateSubmittedChart(chartId, params) {
    try {
      iu1State.on();
      const response = await auditRepository.updateSubmittedChart(
        chartId,
        params
      );
      if (!response.result.assigned_auditor) {
        response.result.assigned_auditor = null;
      }
      updateAuditList(chartId, response.result);
    } catch (e) {
      toast.showError({
        description: `${e}`,
      });

      throw e;
    } finally {
      iu1State.off();
    }
  }
  // update list

  const updateAuditList = (auditListId, updatedParams) => {
    // check in auditUplodList
    let allAuditListIndex = auditUploadList.findIndex(auditlist => {
      return auditlist.id === auditListId;
    });

    if (allAuditListIndex > -1) {
      auditUploadList[allAuditListIndex] = {
        ...auditUploadList[allAuditListIndex],
        ...updatedParams,
      };
      setAuditUploadList([...auditUploadList]);
    }
    // check in new uploads
    let newUploadsListIndex = auditNewUploadsList.findIndex(auditlist => {
      return auditlist.id === auditListId;
    });
    if (newUploadsListIndex > -1) {
      if (updatedParams.status !== 'AWAITING ASSIGNMENT') {
        const newUploads = auditNewUploadsList.filter((_, index) => {
          return index !== newUploadsListIndex;
        });
        setAuditNewUploadsList([...newUploads]);
      } else {
        auditNewUploadsList[newUploadsListIndex] = {
          ...auditNewUploadsList[newUploadsListIndex],
          ...updatedParams,
        };
        setAuditNewUploadsList([...auditNewUploadsList]);
      }
    }
    // check in archived
    let filterArchivedListIndex = auditArchivedList.findIndex(auditlist => {
      return auditlist.id === auditListId;
    });
    if (filterArchivedListIndex > -1) {
      auditArchivedList[filterArchivedListIndex] = {
        ...auditArchivedList[filterArchivedListIndex],
        ...updatedParams,
      };
      setAuditArchivedList([...auditArchivedList]);
    }
    // check in rebuttals
    let filterRebuttalsListIndex = auditRebuttalsList.findIndex(auditlist => {
      return auditlist.id === auditListId;
    });
    if (filterRebuttalsListIndex > -1) {
      auditRebuttalsList[filterRebuttalsListIndex] = {
        ...auditRebuttalsList[filterRebuttalsListIndex],
        ...updatedParams,
      };
      setAuditRebuttalsList([...auditRebuttalsList]);
    }
    // check in rebuttals
    let filterAuditedListIndex = auditedList.findIndex(auditlist => {
      return auditlist.id === auditListId;
    });
    if (filterAuditedListIndex > -1) {
      auditedList[filterAuditedListIndex] = {
        ...auditedList[filterAuditedListIndex],
        ...updatedParams,
      };
      setAuditedList([...auditedList]);
    }
  }

  // ----------------------------------------------------------------
  const handleApplyFilter = selectedFilters => {
    setFilterParams(selectedFilters);
  };

  async function deleteUploadedFile(auditListId) {
    try {
      idState.on();
      await auditRepository.deleteUploadedFile(auditListId);
      filterAuditList(auditListId);
      const toastProps = {
        description: `${'Deleted Successfully'}`,
        header: 'Success!',
      };
      successToast(toast, toastProps);
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      idState.off();
    }
  }

  // filtering the audit list when delete uploads for instant response;
  function filterAuditList(auditListId) {
    // check in new uploads
    let filterNewUploadsList = auditNewUploadsList.filter(auditlist => {
      return auditlist.id !== auditListId;
    });
    setAuditNewUploadsList(filterNewUploadsList);
    // check in archived
    let filterArchivedList = auditArchivedList.filter(auditlist => {
      return auditlist.id !== auditListId;
    });
    setAuditArchivedList(filterArchivedList);
    // check in rebuttals
    let filterRebuttalsList = auditRebuttalsList.filter(auditlist => {
      return auditlist.id !== auditListId;
    });
    setAuditRebuttalsList(filterRebuttalsList);
    //check in Audited
    let filterAuditedList = auditedList.filter(auditlist => {
      return auditlist.id !== auditListId;
    });
    setAuditedList(filterAuditedList);
  }

  async function requestMissingInfo(auditListId, formdata) {
    try {
      rmState.on();
      const res = await auditRepository.updateChart(auditListId, {
        formData: { request_missing_info: formdata },
      });
      const toastProps = {
        description: `${'Submitted '}`,
        header: 'Success!',
      };
      successToast(toast, toastProps);
      updateAuditList(auditListId, res.result);
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      rmState.off();
    }
  }

  async function markAsUrgent(auditListId, isUrgent) {
    try {
      imState.on();
      const response = await auditRepository.updateChart(auditListId, {
        formData: { urgent: isUrgent },
      });
      updateAuditList(auditListId, response.result);
      const toastProps = {
        description: `${'Submitted Success'}`,
        header: 'Success!',
      };
      successToast(toast, toastProps);
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      imState.off();
    }
  }

  async function assignSelf(chartId) {
    try {
      iactsState.on();
      const response = await auditRepository.updateChart(chartId, {
        formData: { assigned_auditor: authContext.currentUser.id },
      });
      updateAuditList(chartId, response.result);
      const toastProps = {
        description: `${'Assigned to Self'}`,
        header: 'Success!',
      };
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      iactsState.off();
    }
  }

  async function loadSpecialtyAuditors() {
    try {
      await auditRepository.getAuditorList();
    } catch (e) { }
  }

  async function getAuditorList(specialtyId) {
    try {
      ialState.on();
      const auditors = await auditRepository.getAuditors(specialtyId);
      return auditors;
    } catch (error) {
      toast.showError({
        description: `${error}`,
      });
    } finally {
      ialState.off();
    }
  }

  // -------------------------------------------------------

  async function createSplitAudit(uploadId, data) {
    const response = await auditRepository.createSplitAudit(uploadId, data);
    return response;
  }

  const mContext = {
    isAuditUploadListLoading,
    isUpdating,

    getAuditUpload: getAuditUpload1,
    getAuditNewUploads: getAuditNewUploads1,
    getAuditArchived: getAuditArchived1,
    getAuditRebuttals: getAuditRebuttals1,
    getAudited: getAudited1,
    loadSpecialtyAuditors,

    getAllSpecialties,
    specialties,
    getAllUsers,

    auditUploadList,
    isUserList,
    isSpecialtyList,
    auditNewUploadsList,
    auditArchivedList,
    auditRebuttalsList,
    auditedList,
    users,

    auditUploadListCount,
    auditNewUploadsListCount,
    auditArchivedListCount,
    auditRebuttalsListCount,
    auditedListCount,
    filterParams,
    handleApplyFilter,
    updateSubmittedChart,
    deleteUploadedFile,
    isDeleteUpload,

    requestMissingInfo,
    isReqMissing,
    markAsUrgent,
    isMarkedAsUrgent,
    isUpdatingAuditorList,
    assignSelf,

    getAuditorList,
    isSplitAudit,
    createSplitAudit,

    isAssigningChartToSelf,

    isFetchingNewUploads,
    isfettchingAuditArchived,
    isFetchingRebuttals,
    isFetchingAudit,
  };

  return (
    <AuditContext.Provider value={mContext}>
      {props.children}
    </AuditContext.Provider>
  );
};

export default AuditProvider;
