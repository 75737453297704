/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from '@chakra-ui/icons';
import { Avatar, Divider, HStack, Text } from '@chakra-ui/react';
import {
  CQSelect,
  TextInput,
  useToastr,
} from '@laxmimanogna/code-quick-components';
import React, { useEffect, useRef, useState } from 'react';
import AppColors from '../../constants/AppColors';
import { PersonAvatarIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';
import { AuditContext } from '../../providers/AuditProvider';
import { AuthContext } from '../../providers/AuthProvider';
import { toTitleCase } from '../../utils/common.utils';
import { components } from 'react-select';

const CustomMenuList = ({ selectProps, ...menuListProps }) => {
  const { MenuList } = components;

  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

  // Copied from source
  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': selectProps['aria-label'],
    'aria-labelledby': selectProps['aria-labelledby'],
  };

  return (
    <div>
      <Text
        cursor={'pointer'}
        color={'black'}
        p={3}
        fontWeight={'normal'}
        onClick={() => {
          selectProps.onAssignAuditClick();
        }}
      >
        Assign to self
      </Text>
      <Divider />
      <TextInput
        m="1"
        rightIcon={<SearchIcon mt={2} />}
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        type="text"
        value={inputValue}
        onChange={e =>
          onInputChange(e.currentTarget.value, {
            action: 'input-change',
          })
        }
        onMouseDown={e => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={e => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder="Search..."
        {...ariaAttributes}
      />
      <MenuList {...menuListProps} selectProps={selectProps} />
    </div>
  );
};

const AssignAuditorCell = props => {
  const { ValueContainer, SingleValue, Placeholder } = components;
  const toast = useToastr();

  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const isOptionsLoaded = useRef(false);
  const auditContext = React.useContext(AuditContext);
  const authContext = React.useContext(AuthContext);

  const [options, setOptions] = React.useState(
    props.selectedValue ? [props.selectedValue] : []
  );
  const [selectedAuditor, setSelectedAuditor] = React.useState(
    props.selectedValue?.value
  );

  const controlBackground = 'transparent';
  const optionHoverColor = AppColors.selectHoverColor + '1A';

  const onDomClick = e => {
    let menu = containerRef.current.querySelector('.select__menu');
    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick);

    return () => {
      document.removeEventListener('mousedown', onDomClick);
    };
  }, []);

  const getAuditorList = async () => {
    try {
      const response = await auditContext.getAuditorList(
        props.selectedSpecialtyId
      );

      const auditorOptions = response.map(auditor => {
        let option = {
          label: toTitleCase(`${auditor.first_name} ${auditor.last_name}`),
          value: auditor.id,
          shouldHide: auditor.id === authContext.currentUser?.id,
        };

        return option;
      });

      if (props.selectedValue?.value) {
        const optionIndex = auditorOptions.findIndex(
          a => a.value === props.selectedValue?.value
        );

        if (optionIndex < 0) {
          auditorOptions.push(props.selectedValue);
        }
      }
      setOptions(auditorOptions);
    } catch (error) {
    } finally {
      isOptionsLoaded.current = true;
    }
  };

  useEffect(() => {
    if (!props.selectedSpecialtyId) {
      setOptions([]);
    } else {
      getAuditorList();
    }
    if (isOptionsLoaded.current) {
      setSelectedAuditor(null);
    }
  }, [props.selectedSpecialtyId]);

  React.useEffect(() => {
    if (!props.selectedValue) {
      setSelectedAuditor(null);
      return;
    }
    // options.push({
    //     label: toTitleCase(
    //         `${authContext.currentUser?.first_name} ${authContext.currentUser?.last_name}`
    //     ),
    //     value: authContext.currentUser?.id,
    //     shouldHide: true,
    // });

    const nextSelectedValue = options.find(
      s => s.value === props.selectedValue?.value
    );

    setSelectedAuditor(nextSelectedValue);
  }, [props.selectedValue]);

  const assignToSelf = async auditRowId => {
    await auditContext.assignSelf(auditRowId);
  };

  const handleAuditorChange = event => {
    const nextSelectedValue = options.find(s => s.value === event.value);
    setSelectedAuditor(nextSelectedValue);
    if (props.onChange && nextSelectedValue?.value) {
      props.onChange(nextSelectedValue?.value);
      setIsFocused(false);
    }
  };

  // useEffect(() => {
  //   if (props.onChange && selectedAuditor?.value && onChange) {
  //     props.onChange(selectedAuditor?.value);
  //     setIsFocused(false);
  //   }
  // }, [selectedSpecialty])

  const AdvancedControlComponent = props => {
    const currentValue = props.getValue();

    return (
      <HStack>
        {props.selectProps.avatar ? (
          <Avatar
            name={currentValue?.length && currentValue[0].label}
            bg={
              props.selectProps.color
                ? props.selectProps.color
                : AppColors.secondary
            }
            {...props.selectProps.avatarProps}
          />
        ) : (
          props.selectProps.customIcon
        )}
        <components.Control {...props} />
      </HStack>
    );
  };

  // Set custom `SingleValue` and `Placeholder` to keep them when searching
  const CustomValueContainer = ({ children, selectProps, ...valueProps }) => {
    const commonProps = {
      cx: valueProps.cx,
      clearValue: valueProps.clearValue,
      getStyles: valueProps.getStyles,
      getValue: valueProps.getValue,
      hasValue: valueProps.hasValue,
      isMulti: valueProps.isMulti,
      isRtl: valueProps.isRtl,
      options: valueProps.options,
      selectOption: valueProps.selectOption,
      setValue: valueProps.setValue,
      selectProps,
      theme: valueProps.theme,
    };

    return (
      <ValueContainer {...valueProps} selectProps={selectProps}>
        {React.Children.map(children, child => {
          return child ? (
            child
          ) : valueProps.hasValue ? (
            <SingleValue
              {...commonProps}
              isFocused={selectProps.isFocused}
              isDisabled={selectProps.isDisabled}
            >
              {selectProps.getOptionLabel(valueProps.getValue()[0])}
            </SingleValue>
          ) : (
            <Placeholder
              {...commonProps}
              key="placeholder"
              isDisabled={selectProps.isDisabled}
              data={valueProps.getValue()}
            >
              {selectProps.placeholder}
            </Placeholder>
          );
        })}
      </ValueContainer>
    );
  };

  const selectRef = useRef();

  return (
    <div ref={containerRef}>
      <CQSelect
        ref={selectRef}
        className="basic-single"
        classNamePrefix="select"
        styles={{
          control: (styles, state) => ({
            ...styles,
            borderWidth: state.isFocused ? 0 : 0,
            boxShadow: 'none',
            height: 50,
            background: controlBackground,
          }),

          option: provided => ({
            ...provided,
            fontWeight: 'normal',
            fontFamily: FONT_FAMILY.ptSans,
            color: AppColors.secondary,
            padding: 16,
            '&:hover': {
              background: optionHoverColor,
              color: AppColors.secondary,
            },
          }),
          singleValue: (provided, state) => {
            return {
              ...provided,

              color: selectedAuditor?.value
                ? AppColors.secondary
                : AppColors.primary,
            };
          },
          clearIndicator: provided => ({
            ...provided,
            color: 'midnightblue',
          }),
          placeholder: provided => ({
            ...provided,
            color: selectedAuditor?.value
              ? AppColors.secondary
              : AppColors.primary,
          }),
        }}
        name="color"
        options={options}
        components={{
          MenuList: CustomMenuList,
          ValueContainer: CustomValueContainer,
          Control: AdvancedControlComponent,
          IndicatorSeparator: null,
        }}
        inputValue={inputValue}
        onAssignAuditClick={() => {
          if (props.selectedSpecialtyId) {
            if (props.assignToSelf) {
              setIsFocused(true);
              props.assignToSelf();
            } else {
              assignToSelf(props.auditUpload.id);
            }
            setIsFocused(false);
            selectRef.current.blur();
          } else {
            toast.showError({
              description: `Select any specialty`,
            });
          }
        }}
        isSearchable={false}
        onMenuInputFocus={() => setIsFocused(true)}
        onInputChange={val => setInputValue(val)}
        color={selectedAuditor?.value ? AppColors.secondary : AppColors.primary}
        avatar={!!selectedAuditor?.value}
        key={props.key}
        avatarProps={{ size: 'sm', color: 'white' }}
        width={'350px'}
        placeholder={'Assign...'}
        customIcon={<PersonAvatarIcon color={AppColors.primary} size="md" />}
        size={'sm'}
        variant={'advanced'}
        onChange={handleAuditorChange}
        value={selectedAuditor}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'transparent',
          },
        })}
      />
    </div>
  );
};

export default React.memo(AssignAuditorCell);
