/* eslint-disable eqeqeq */
import { Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const TotalPageCell = props => {
  const [totalPage, setTotalPage] = useState(props.value ?? 0);

  useEffect(() => {
    setTotalPage(props.value);
  }, [props.value])

  function handleChange(e) {

    const stringPattern = /^[0-9]-*[0-9]*$/
    if (!e.target.value || stringPattern.test(e.target.value)) {
      try {
        if (!e.target.value) {
          setTotalPage(null);
        } else {
          const value = e.target.value;
          setTotalPage(value);
        }
      } catch (e) {
        setTotalPage(0);
      }
    }
  }

  function handleBlur() {
    if (props.onSave && totalPage != 0) {
      props.onSave(totalPage).catch(e => {
        setTotalPage(props.value)
      });
    } else {
      setTotalPage(props.value)
    }
  }


  return (
    <Input
      onBlur={handleBlur}
      onChange={handleChange}
      // type={'number'}
      w={75}
      textAlign='center'
      bgColor={'white'}
      value={totalPage}
    />
  );

};

export default TotalPageCell;
