/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import AuditAccuracyDateFilter from '../../../components/AuditAccuracyDateFilter';
import AppColors from '../../../constants/AppColors';
import { TriangleIcon, TriangleRedIcon } from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import userManagementRepository from '../../../repositories/UserManagementRepository';
import { format } from 'date-fns';
import { subDays } from 'date-fns';

// teams
const AuditAccuracySection = props => {
  const { queryParam = {} } = props;
   // constants
   const current = new Date();

   const endDate = subDays(current, 30);
 
   const previousStartDate = subDays(endDate, 30);
 
   const previousEndDate = subDays(previousStartDate, 30);



  // booleans
  const [isFetching, ifState] = useBoolean(false);

  // state
  const [accuracy, setAccuracy] = useState(null);
  const [filterParams, setFilterParams] = useState({
    start_date: format(current,'yyyy-MM-dd'), 
    end_date: format(endDate,'yyyy-MM-dd'), 
    previous_start_date: format(previousStartDate,'yyyy-MM-dd'), 
    previous_end_date:format(previousEndDate,'yyyy-MM-dd'), 
  });


  useEffect(() => {
    fetchAuditAccuracy();
  }, [queryParam, filterParams]);

  function getFilterParams() {
    return {
      ...filterParams,
      ...queryParam,
    };
  }

  async function fetchAuditAccuracy() {
    try {
      ifState.on();
      const _filterParams = getFilterParams();
      const accuracyResponse = await userManagementRepository.getChartAccuracy(
        _filterParams
      );
      setAccuracy(accuracyResponse);
    } catch (e) {
      // unable to load audit accuracy
    } finally {
      ifState.off();
    }
  }
  function renderBroken() {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  }

  function renderLoader() {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }

  function renderMainContent() {
    return (
      <React.Fragment>
        <ScaleFade initialScale={0.1} in={true}>
          <VStack alignItems="start">
            <Flex w={'100%'} justifyContent={'space-between'}>
              <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
                Audit Accuracy
              </Text>
              <AuditAccuracyDateFilter
                onFilterChange={setFilterParams}
                isLoading={isFetching}
                filterParams={filterParams}
              />
            </Flex>
          </VStack>
          <VStack position="relative">
            <CircularProgress
              value={accuracy.cq_score}
              size="230px"
              thickness="2px"
              color={AppColors.primary}
            >
              <CircularProgressLabel>
                <HStack position={'relative'} top={0} left={'45%'}>
                  {accuracy.cq_score_diff >= 0 ? (
                    <TriangleIcon />
                  ) : (
                    <TriangleRedIcon />
                  )}
                  <Text fontSize={'md'}>
                    {accuracy.cq_score_diff >= 0 && '+'}
                    {accuracy.cq_score_diff}
                  </Text>
                </HStack>

                <HStack
                  position={'relative'}
                  top={-2}
                  left={'23px'}
                  w={'200px'}
                  justifyContent={'center'}
                >
                  <Text fontFamily={FONT_FAMILY.ptSans} fontWeight="md">
                    {accuracy.cq_score}
                  </Text>
                  <Text fontSize={'lg'} color={AppColors.lightGrey}>
                    %
                  </Text>
                </HStack>
              </CircularProgressLabel>
              <CircularProgressLabel mt="12">
                <Text fontSize={'md'} color={AppColors.lightGrey}>
                  Overall Accuracy
                </Text>
              </CircularProgressLabel>
            </CircularProgress>
          </VStack>
          <VStack alignItems="start">
            <Text
              fontSize="36px"
              fontFamily={FONT_FAMILY.ptSans}
              fontWeight="bolder"
            >
              {accuracy.grade}
            </Text>
            <Text
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.black}
              fontSize={'sm'}
            >
              RI Score
            </Text>
          </VStack>
        </ScaleFade>
      </React.Fragment>
    );
  }

  function renderContent() {
    if (isFetching) {
      return renderLoader();
    } else if (!accuracy) {
      return renderBroken();
    } else {
      return renderMainContent();
    }
  }

  return renderContent();
};

export default React.memo(AuditAccuracySection);
