import { useBoolean } from '@chakra-ui/react';
import React from 'react';
import FileViewer from '../../components/pdf_viewer/FileViewer';
import { EyeAvatarIcon } from '../../constants/IconData';

const AccountsFileViewer = ({ fileLink }) => {
  const [isFileOpen, ifoState] = useBoolean(false);
  return (
    <React.Fragment>
      <EyeAvatarIcon style={{ cursor: 'pointer' }} onClick={ifoState.on} />
      <FileViewer
        isOpen={isFileOpen}
        currentFile={fileLink}
        onClose={ifoState.off}
      />
    </React.Fragment>
  );
};

export default AccountsFileViewer;
