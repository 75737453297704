import { useEffect, useState } from 'react';
import { Button, HStack, Center } from '@chakra-ui/react';
import accountsRepository from '../../../repositories/AccountsRepository';
import auditRepository from '../../../repositories/AuditRepository';
import { CQSelect } from '@laxmimanogna/code-quick-components';
import AppColors from '../../../constants/AppColors';


const ViewProfileBtn = props => {
    const MANAGER_VIEW_CLIENT_URL = process.env.REACT_APP_MANAGER_VIEW_CLIENT_URL;
    const { queryParam = {} } = props;

    // state
    const [statistics, setStatistics] = useState(null);
    const [errorMessage, setErrorMessage] = useState('Error');
    const [selectedValue, setSelectedValue] = useState('');
    const [isViewProfileBtnDisable, setIsViewProfileBtnDisable] = useState(true);

    const commonSelectStyles = {
        control: props => ({
            ...props,
            borderRadius: 7,
            paddingRight: 10,
            paddingLeft: 10,
            fontSize: '12px',
            color: AppColors.secondary,
            borderColor: AppColors.secondary,
            '&:hover': {
                borderColor: AppColors.primary,
            },
        }),
        placeholder: props => ({
            ...props,
            color: AppColors.secondary,
            fontWeight: 'bold'
        }),
        dropdownIndicator: props => ({
            ...props,
            color: AppColors.secondary
        }),
        singleValue: props => ({
            ...props,
            fontWeight: 'bold',
            color: AppColors.secondary,
        }),
        option: props => ({
            ...props,
            fontSize: '12px',
            zIndex: 100
        }),
        menu: props => ({
            ...props,
            zIndex: 100
        }),
    }

    useEffect(() => {
        fetchStatistics();
    }, []);

    useEffect(() => {
        if(selectedValue !== ''){
            setIsViewProfileBtnDisable(false);
        }
    }, [selectedValue])

    function getFilterParams() {
    return {
        ...queryParam,
    };
    }

    async function fetchStatistics() {
        try {
          const _filterParams = getFilterParams();
          const statisticsResponse = await accountsRepository.getTeamStatistics(
            _filterParams
          );
          setStatistics(statisticsResponse[0]);
        } catch (e) {
          // TODO: log exception
        }
    }

    //Auth manager and get client token for view profile.
    async function auth_manager_client_token(client_id) {
        setIsViewProfileBtnDisable(true);
        try {
            const response = await auditRepository.auth_manager_client_token(client_id);
            if (response.access_token) {
                
                // Construct the URL
                const clientToken = response.access_token;
                const url = MANAGER_VIEW_CLIENT_URL+`?clientToken=${clientToken}`;

                // Open the URL in a new tab
                window.open(url, '_blank', 'noopener,noreferrer');
            } else {
                setErrorMessage(response.message);
            }
        } catch (e) {
            // Handle error
            console.error(e);
        } finally{
            setIsViewProfileBtnDisable(false);
        }
    }

    const handleViewProfile = () =>{
        if(selectedValue){
            auth_manager_client_token(selectedValue);
        }
    }

    const handleOnChange = (option) => {
        console.log(option)
        setSelectedValue(option);
    }
    
    return (
        <>
          
          {statistics ? (
            
            <HStack>
            <Button 
            onClick={handleViewProfile}
            color={AppColors.white}
            bg= {AppColors.secondary} 
            disabled={isViewProfileBtnDisable}
            _hover={false}>
                View Profile
            </Button>
            {statistics.users?.length ?<CQSelect
                options={statistics.users.map(({user_id, user_name}) => ({'value':user_id, 'label':user_name}))}
                placeholder='Select User Name'
                variant='primary'
                isSearchable={false}
                styles={commonSelectStyles}
                value={statistics.users.find(option => option.value === selectedValue)}
                onChange={(data) => {
                    handleOnChange(data.value)
                }}
            />: <Center>User Not Available</Center> }
            </HStack>
            
      ) : (
        'Loading...'
      )}
         
        </>
    );
      
}

export default ViewProfileBtn;