/* eslint-disable array-callback-return */
import CQBarchart from '../../../../components/CQBarchart';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { Box, HStack, ScaleFade, Skeleton } from '@chakra-ui/react';
import { ReportContext } from '../../../../providers/ReportProvider';
import { useContext } from 'react';
import CQError from '../../../../components/CQError';

const AccuracyChart = () => {
  const reportContext = useContext(ReportContext);
  const { auditorTableData, isAuditorTableLoading } = reportContext;

  const accuracyObject = auditorTableData.length
    ? auditorTableData.find(data => data.Quality === 'Accuracy%')
    : {};

  const dataValues1 = [];
  const dataValues2 = [];
  if (Object.keys(accuracyObject).length) {
    Object.keys(accuracyObject).map((key, i) => {
      if (key === 'overall') {
        dataValues2.push(accuracyObject[key] || 0);
      }
      if (i > 1) {
        dataValues1.push(accuracyObject[key] || 0);
      }
    });
  }
  const accuracyOption = ({ title }) => ({
    responsive: true,
    hover: { mode: null },
    responsiveAnimationDuration: 0,
    animation: {
      duration: 0,
      onComplete: function ({ chart }) {
        const ctx = chart.ctx;

        chart.config.data.datasets.forEach(function (dataset, i) {
          const meta = chart.getDatasetMeta(i);

          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];
            const length = data.toString().length + 1;
            ctx.fillText(
              `${data}%`,
              bar.x - (length === 1 ? 6 : length * 3),
              bar.y - 5
            );
          });
        });
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,
          // This more specific font property overrides the global property
          color: 'black',
          font: {
            size: 10,
            family: FONT_FAMILY.ptSans,
          },
        },
      },
      title: {
        display: true,
        text: title,
        padding: 20,
        color: 'black',
        // This more specific font property overrides the global property
        font: {
          size: 18,
          family: FONT_FAMILY.baiJamurjee,
          weight: 'normal',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return value + '%';
          },
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  });
  const accuracyData = ({ labels, dataValues, dataSetProps = {} }) => ({
    labels,
    datasets: [
      {
        label: 'Accuracy %',
        data: dataValues,
        borderColor: AppColors.secondary,
        backgroundColor: AppColors.secondary,
        ...dataSetProps, // <<<<<<<<<<<<   bar / column size
      },
    ],
  });

  const renderLoader = () => {
    return (
      <Box>
        <Skeleton w={'100%'} h={200} borderRadius="2xl" />
      </Box>
    );
  };

  return (
    <HStack m={5} justifyContent="center" spacing={4}>
      <Box width={'45%'}>
        {isAuditorTableLoading ? (
          renderLoader()
        ) : !auditorTableData.length ? (
          <CQError />
        ) : (
          <ScaleFade initialScale={0.1} in={true}>
            <CQBarchart
              option={accuracyOption({ title: 'Parameter - Accuracy' })}
              data={accuracyData({
                labels: ['CPT', 'E&M', 'MOD'],
                dataValues: dataValues1,
                dataSetProps: {
                  maxBarThickness: 70,
                },
              })}
            />
          </ScaleFade>
        )}
      </Box>
      <Box width={'45%'}>
        {isAuditorTableLoading ? (
          renderLoader()
        ) : !auditorTableData.length ? (
          <CQError />
        ) : (
          <ScaleFade initialScale={0.1} in={true}>
            <CQBarchart
              option={accuracyOption({ title: 'Parameter - Accuracy' })}
              data={accuracyData({
                labels: ['Overall'],
                dataValues: dataValues2,
                dataSetProps: {
                  maxBarThickness: 100,
                },
              })}
            />
          </ScaleFade>
        )}
      </Box>
    </HStack>
  );
};
export default AccuracyChart;
