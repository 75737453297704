import BlankLayout from '../components/layout/BlankLayout';
import ROUTES from '../constants/Routes';
import AccountsContainer from '../pages/accounts/AccountsContainer';
import AccountsViewContainer from '../pages/accounts/AccountsViewContainer';
import DepartmentViewContainer from '../pages/accounts/DepartmentViewContainer';
import EditAccountContainer from '../pages/accounts/EditAccountContainer';
import EditPhysicianForm from '../pages/accounts/forms/EditPhysicianForm';
import HospitalViewContainer from '../pages/accounts/HospitalViewContainer';
import PhysicianViewContainer from '../pages/accounts/PhysicianViewContainer';
import DepartmentProfileViewContainer from '../pages/accounts/profile_container/DepartmentProfileViewContainer';
import HospitalProfileViewContainer from '../pages/accounts/profile_container/HospitalProfileViewContainer';
import AuditUploadContainer from '../pages/assigned_audits/AuditUploadContainer';
import AuditSheetContainer from '../pages/audit_sheet/AuditSheetContainer';
import ChartUploadContainer from '../pages/chart_upload/ChartUploadContainer';
import HelpContainer from '../pages/faq/HelpContainer';
import MyAuditsContainer from '../pages/my_audits/MyAuditsContainer';
import Notifications from '../pages/notifications/Notifications';
import AccountContainer from '../pages/profile/AccountContainer';
import ReportContainer from '../pages/reports/ReportContainer';
import EditUserDetails from '../pages/teams/components/EditUserDetails';
import TeamContainer from '../pages/teams/TeamContainer';
import TeamEditFormContainer from '../pages/teams/TeamEditFormContainer';
import TeamFormContainer from '../pages/teams/TeamFormContainer';
import TeamProfileContainer from '../pages/teams/TeamProfileContainer';
import UserManagementFormContainer from '../pages/user_management/UserManagementFormContainer';
import UserManagmentEditFormContainer from '../pages/user_management/UserManagmentEditFormContainer';
import UserProfileContainer from '../pages/user_management/UserProfileContainer';

const AppRouter = [
  {
    route: ROUTES.ASSIGNED_AUDITS,
    element: <AuditUploadContainer />,
  },
  {
    route: ROUTES.MY_AUDITS,
    element: <MyAuditsContainer />,
  },
  {
    route: ROUTES.ACCOUNT,
    element: <AccountContainer />,
  },
  {
    route: ROUTES.OFFLINE_UPLOAD_CHART,
    element: <ChartUploadContainer />,
  },
  {
    route: ROUTES.TEAM,
    element: <TeamContainer />,
  },
  {
    route: ROUTES.ADD_TEAM,
    element: <TeamFormContainer />,
  },
  {
    route: ROUTES.EDIT_TEAM,
    element: <TeamEditFormContainer />,
  },
  {
    route: ROUTES.TEAM_PROFILE,
    element: <TeamProfileContainer />,
  },
  {
    route: ROUTES.AUDIT_SHEET,  // audit sheet layout / container
    element: <AuditSheetContainer />,
    layout: BlankLayout,
  },
  {
    route: ROUTES.VIEW_AUDIT_SHEET,
    element: <AuditSheetContainer view={true} />,
    layout: BlankLayout,
  },
  {
    route: ROUTES.CREATE_USER,
    element: <UserManagementFormContainer />,
  },
  {
    route: ROUTES.EDIT_TEAMS_USER,
    element: <EditUserDetails />,
  },
  {
    route: ROUTES.USER_PROFILE,
    element: <UserProfileContainer />,
  },
  {
    route: ROUTES.EDIT_USER,
    element: <UserManagmentEditFormContainer />,
  },
  {
    route: ROUTES.ACCOUNTS,
    element: <AccountsContainer />,
  },
  {
    route: ROUTES.REPORT,
    element: <ReportContainer />,
  },
  {
    route: ROUTES.GET_ACCOUNT,
    element: <AccountsViewContainer />,
  },
  {
    route: ROUTES.EDIT_ACCOUNT,
    element: <EditAccountContainer />,
  },
  {
    route: ROUTES.GET_ACCOUNT_HOSPITAL,
    element: <HospitalViewContainer />,
  },
  {
    route: ROUTES.GET_ACCOUNT_DEPARTMENT,
    element: <DepartmentViewContainer />,
  },
  {
    route: ROUTES.GET_ACCOUNT_PHYSICIAN,
    element: <PhysicianViewContainer />,
  },
  {
    route: ROUTES.CREATE_TEAM_USER,
    element: <UserManagementFormContainer />,
  },
  {
    route: ROUTES.GET_DEPARTMENT,
    element: <DepartmentProfileViewContainer />,
  },
  {
    route: ROUTES.GET_HOSPITAL,
    element: <HospitalProfileViewContainer />,
  },
  {
    route: ROUTES.EDIT_PHYSICIAN,
    element: <EditPhysicianForm />,
  },
  {
    route: ROUTES.NOTIFICATIONS,
    element: <Notifications />,
  },
//   {
//     route: ROUTES.HELP,
//     element: <HelpContainer />,
//   },
  {
    route: ROUTES.CREATE_NEW_USER_BY_TEAM,
    element: <TeamContainer />,
  },
];

export default AppRouter;
