import { HeaderComponent } from "../../../components/table_components/HeaderComponent"

const getTableColumn = () => {
    return [
        {
            Header: <HeaderComponent header='Upload #' />,
            accessor: 'chart_id',
            width: '15%',
            minWidth: 80,
            headerStyle: {
                textTransform: 'none',
                textAlign: 'center',
                color: 'black',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },
        {
            Header: <HeaderComponent header='From Page' />,
            accessor: 'from_page',
            width: '15%',
            minWidth: 60,
            headerStyle: {
                textTransform: 'none',
                textAlign: 'center',
                color: 'black',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },
        {
            Header: <HeaderComponent header='To Page' />,
            accessor: 'to_page',
            width: '14%',
            minWidth: 60,
            headerStyle: {
                textTransform: 'none',
                textAlign: 'center',
                color: 'black',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },
        {
            Header: <HeaderComponent header='Specialty' />,
            accessor: 'specialty',
            width: '30%',
            minWidth: 120,
            headerStyle: {
                textTransform: 'none',
                textAlign: 'center',
                color: 'black',
            },
            cellStyle: {
                textAlign: 'center'
            }
        },
        {
            Header: <HeaderComponent header='Assign to' />,
            accessor: 'assigned_to',
            width: '30%',
            minWidth: 150,
            headerStyle: {
                textTransform: 'none',
                textAlign: 'center',
                color: 'black',
            },
            cellStyle: {
                display: 'flex', justifyContent: 'center',
            }
        },
        {
            id: 'actions',
            accessor: 'actions',
            width: '10%',
            minWidth: 50,
            headerStyle: {
                textTransform: 'none',
                textAlign: 'center',
                color: 'black',
            },
            cellStyle: {
                textAlign: 'center',
            }
        },
    ]
}
export { getTableColumn }