/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Text,
  HStack,
  VStack,
  Grid,
  GridItem,
  Heading,
  StackDivider,
  Spacer,
  Flex,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { useContext } from 'react';
import AppColors from '../../../constants/AppColors';
import {
  ProfileAvatarIcon,
  SearchIcon,
  UrgentIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import { TeamContext } from '../../../providers/TeamProvider';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PrimaryButton,
  TextInput,
} from '@laxmimanogna/code-quick-components';
import ROUTES from '../../../constants/Routes';
import AuditAccuracySection from './sections/AuditAccuracySection';
import MetricSection from './sections/MetricSection';
import TeamsChartSection from './sections/TeamsChartSection';
import { replaceRoute, toTitleCase } from '../../../utils/common.utils';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 4px lightGrey',
    width: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '4px',
  },
};

const TeamProfile = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const teamContext = useContext(TeamContext);
  const { currentTeam, getTeam } = teamContext;
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
    }
    teamContext.getSpecialties();
  }, [teamId]);

  const queryParam = useMemo(() => ({ team_id: teamId }), []);

  function renderAuditAccuracy() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <AuditAccuracySection queryParam={queryParam} />
      </GridItem>
    );
  }
  function renderMetric() {
    return (
      <GridItem bgColor={'#fff'} p={8} borderRadius={'lg'} shadow={'md'}>
        <MetricSection queryParam={queryParam} />
      </GridItem>
    );
  }

  function renderTable() {
    return (
      <GridItem colSpan={2}>
        <TeamsChartSection queryParam={queryParam} />
      </GridItem>
    );
  }

  const filterSpecialties = teamContext.specialties?.filter(specialty => {
    return currentTeam?.specialties?.some(specialtyId => {
      return specialty.id === specialtyId;
    });
  });
  const teamSpecialities = filterSpecialties?.slice(0, 3)?.map(s => {
    if (s.name === 'ent') {
      return 'ENT';
    }
    return toTitleCase(s.name);
  });

  const showSpecialities = filterSpecialties
    ?.slice(3, filterSpecialties.length)
    .map(s => {
      if (s.name === 'ent') {
        return 'ENT';
      }
      return toTitleCase(s.name);
    });

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={'30px'}>
        <GridItem
          shadow={'md'}
          w="100%"
          background={AppColors.white}
          borderRadius="md"
          p="5"
        >
          <HStack mb="2">
            <ProfileAvatarIcon style={{ width: 80, height: 80 }} />
            <VStack alignItems={'start'}>
              <Heading
                fontSize="xl"
                color={AppColors.primary}
                fontFamily={FONT_FAMILY.baiJamurjee}
              >
                {toTitleCase(currentTeam.name)}
              </Heading>
              <HStack
                fontSize="lg"
                fontWeight="500"
                fontFamily={FONT_FAMILY.baiJamurjee}
                alignItems={'center'}
              >
                <Text>{teamSpecialities.join(' | ')}</Text>
                {filterSpecialties?.length > 3 ? (
                  <>
                    <Text>+</Text>
                    <Tooltip
                      hasArrow
                      label={showSpecialities.join(' | ')}
                      bg={AppColors.secondary}
                      border={'none'}
                    >
                      <Text
                        fontSize={'sm'}
                        bg={AppColors.secondary}
                        color={'#fff'}
                        px={2}
                        py={0.7}
                        borderRadius={'full'}
                        cursor={'pointer'}
                        fontWeight={'md'}
                      >
                        {filterSpecialties.length - 3}
                      </Text>
                    </Tooltip>
                  </>
                ) : (
                  ''
                )}
              </HStack>
            </VStack>
          </HStack>
          <VStack
            mt="25px"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
          >
            <span
              style={{
                color: AppColors.secondary,
                fontWeight: '600',
                fontFamily: FONT_FAMILY.baiJamurjee,
                fontSize: '18px',
              }}
            >
              TEAM STATISTICS
            </span>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <span
                style={{
                  fontWeight: '500',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                Total Audits
              </span>
              <Spacer />
              <span
                style={{
                  fontWeight: 'bolder',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                {currentTeam.total_audits}
              </span>
            </HStack>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <span
                style={{
                  fontWeight: '500',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                In Progress
              </span>
              <Spacer />
              <span
                style={{
                  fontWeight: 'bolder',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                {currentTeam.active_audits}
              </span>
            </HStack>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <span
                style={{
                  fontWeight: '500',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                Completed
              </span>
              <Spacer />
              <span
                style={{
                  fontWeight: 'bolder',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                {currentTeam.completed}
              </span>
            </HStack>
            <HStack>
              <UrgentIcon style={{ width: '8px' }} />
              <span
                style={{
                  fontWeight: '500',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                Open Rebuttals
              </span>
              <Spacer />
              <span
                style={{
                  fontWeight: 'bolder',
                  fontFamily: FONT_FAMILY.ptSans,
                  marginLeft: '15px',
                }}
              >
                {currentTeam.open_rebutals}
              </span>
            </HStack>
          </VStack>
        </GridItem>
        {renderAuditAccuracy()}
        {renderMetric()}
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" gap={'30px'} mt="30px">
        <GridItem
          shadow={'md'}
          colSpan="1"
          background={AppColors.white}
          borderRadius="md"
          p="6"
        >
          <Box mb="2">
            <Flex>
              <Box w="70px" style={{ display: 'flex' }}>
                {' '}
                <Text
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize="sm"
                  fontWeight="600"
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  Members
                </Text>
              </Box>
              <Spacer />
              <Box w="130px" mr={4}>
                <PrimaryButton
                  bg={AppColors.primary}
                  fontSize={'sm'}
                  outline={`2px`}
                  _hover={{
                    transition: 'all .3s ease',
                    bgColor: AppColors.white,
                    color: AppColors.primary,
                    outline: `2px solid ${AppColors.primary}`,
                  }}
                  style={{
                    height: '50px',
                    width: '150px',
                    marginRight: '15px',
                  }}
                  fontWeight="normal"
                  size="md"
                  borderRadius="3xl"
                  onClick={() => {
                    const createNewUser = replaceRoute(
                      ROUTES.CREATE_TEAM_USER,
                      { teamId }
                    );
                    navigate(createNewUser);
                  }}
                >
                  <AddIcon fontSize={'16px'} w={2} h={2} mr={1} />
                  Add New User
                </PrimaryButton>
              </Box>
            </Flex>
          </Box>
          <hr />
          <VStack mt="2">
            <TextInput
              wrapperProps={{ bg: 'transparent' }}
              bg={'white'}
              rightIconProps={{ zIndex: 0 }}
              style={{ height: '50px' }}
              placeholder="Search"
              onChange={e => {
                setSearch(e.target.value);
              }}
              rightIcon={<SearchIcon style={{ marginTop: '10px' }} />}
            />
          </VStack>
          <VStack
            mt="25px"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
            maxH={585}
            overflow="scroll"
            overflowX={'hidden'}
            sx={scrollStyle}
            overflowY={'scroll'}
            style={{ alignItems: 'start' }}
          >
            {currentTeam.members &&
              currentTeam.members.length &&
              currentTeam.members
                .filter(member =>
                  `${member.first_name} ${member.last_name}`
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map(member => {
                  return (
                    <VStack style={{ alignItems: 'start' }}>
                      <Text
                        fontFamily={FONT_FAMILY.ptSans}
                        fontSize="sm"
                        fontWeight="600"
                        style={{ alignItems: 'center', display: 'flex' }}
                      >
                        {member.name}
                      </Text>
                      <Text
                        fontFamily={FONT_FAMILY.ptSans}
                        fontSize="md"
                        fontWeight="600"
                        color={AppColors.secondary}
                      >
                        {`${member.first_name} ${member.last_name}`}
                      </Text>
                      <Text
                        fontFamily={FONT_FAMILY.ptSans}
                        fontSize="sm"
                        fontWeight="600"
                        color={AppColors.primary}
                        style={{ marginTop: '0px' }}
                      >
                        {member.email}
                      </Text>
                      <HStack gap={5}>
                        <VStack style={{ alignItems: 'start' }}>
                          <Text
                            fontFamily={FONT_FAMILY.ptSans}
                            fontSize="sm"
                            style={{ marginTop: '0px' }}
                          >
                            Active Audits
                          </Text>
                          <Text
                            fontFamily={FONT_FAMILY.ptSans}
                            fontWeight="bold"
                            fontSize="sm"
                            color={AppColors.secondary}
                            style={{ marginTop: '0px' }}
                          >
                            {member.active_audits}
                          </Text>
                        </VStack>
                        <VStack style={{ alignItems: 'start' }}>
                          <Text
                            fontFamily={FONT_FAMILY.ptSans}
                            fontSize="sm"
                            style={{ marginTop: '0px' }}
                          >
                            Total Audits
                          </Text>
                          <Text
                            fontFamily={FONT_FAMILY.ptSans}
                            fontWeight="bold"
                            fontSize="sm"
                            color={AppColors.secondary}
                            style={{ marginTop: '0px' }}
                          >
                            {member.total_audits}
                          </Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  );
                })}
          </VStack>
        </GridItem>
        {renderTable()}
      </Grid>
    </>
  );
};

export default TeamProfile;
