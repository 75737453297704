const ROUTES = {
  // AUTH
  ROOT_SCREEN: '/',
  LOGIN_SCREEN: '/auth/login',
  FORGOT_PASSWORD_SCREEN: '/auth/forgot-password',
  CONFIRMATION_SCREEN: '/auth/forgot-password-message',
  RESET_PASSWORD_SCREEN: '/auth/reset-password',
  RESET_CONFIRMATION_SCREEN: '/auth/reset-password-message',

  // PRIVATE ROUTES
  ASSIGNED_AUDITS: '/audits/assigned',
  MY_AUDITS: '/audits/me',

  ACCOUNT: '/my-account',
  OFFLINE_UPLOAD_CHART: '/offline-upload-chart',
  TEAM: '/teams',
  CREATE_NEW_USER_BY_TEAM: '/users/create',
  ADD_TEAM: '/teams/new',
  EDIT_TEAM: '/teams/:teamId/edit',
  TEAM_PROFILE: '/teams/:teamId',

  EDIT_TEAMS_USER: '/teams/:teamId/user/:userId/edit',

  // accounts / health systems
  ACCOUNTS: '/accounts',
  GET_ACCOUNT: '/accounts/:accountId',
  EDIT_ACCOUNT: '/accounts/:accountId/edit',
  GET_ACCOUNT_HOSPITAL: '/accounts/:accountId/hospitals/:hospitalId',
  GET_ACCOUNT_DEPARTMENT:
    '/accounts/:accountId/hospitals/:hospitalId/departments/:departmentId',
  GET_ACCOUNT_PHYSICIAN:
    '/accounts/:accountId/hospitals/:hospitalId/departments/:departmentId/physicians/:physicianId',

  GET_DEPARTMENT:
    '/accounts-profile/:accountId/hospitals/:hospitalId/departments/:departmentId',
  GET_HOSPITAL: '/accounts-profile/:accountId/hospitals/:hospitalId',

  //USER MANAGEMENT
  CREATE_USER: '/users/create',
  EDIT_USER: '/users/:userId/edit',
  USER_PROFILE: '/teams/:teamId/users/:userId',

  AUDIT_SHEET: '/audit/sheet/:uploadId',
  VIEW_AUDIT_SHEET: '/audit/sheet/:uploadId/view',
  CREATE_TEAM_USER: '/teams/:teamId/users/create',

  //report
  REPORT: '/reports',

  EDIT_PHYSICIAN:
    '/accounts-profile/:accountId/hospitals/:hospitalId/departments/:departmentId/editPhysician/:physicianId',

  NOTIFICATIONS: '/client/notification',
  HELP: '/client/help',
};
export const PUBLIC_PATH = [
  ROUTES.LOGIN_SCREEN,
  ROUTES.FORGOT_PASSWORD_SCREEN,
  ROUTES.CONFIRMATION_SCREEN,
  ROUTES.RESET_PASSWORD_SCREEN,
  ROUTES.RESET_CONFIRMATION_SCREEN,
];
export default ROUTES;
