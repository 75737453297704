import { Box, HStack, Text, Tooltip } from "@chakra-ui/react"
import { formatDistanceStrict } from "date-fns"
import { useContext, useState } from "react"
import AppColors from "../../../../constants/AppColors"
import { DotMenuIcon, RebuttalIcon, YellowTickIcon } from "../../../../constants/IconData"
import { FONT_FAMILY } from "../../../../constants/Theme"
import { useComponentVisible } from "../../../../hooks"
import { AuditSheetContext } from "../../../../providers/AuditSheetProvider"
import CommentInput from "./CommentInput"
import CommentSaveButton from "./CommentSaveButton"
import CustomMenu from "./CustomMenu"
import { getTaggedUser } from '../../getTaggedUsers';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import RenderMessage from "../RenderMessage"
import { successToast } from "../../../../components/successToast"
import { RESOLVED_STATUS } from "../../constants"

const CommentBox = ({ onEditModalOpen, onDeleteModalOpen, comment, index, selectedComment, setSelectedComment, setSelectedParentComment }) => {
    const [hiddenBoxKey, setHiddenBoxKey] = useState('')
    const auditSheetContext = useContext(AuditSheetContext);
    const toast = useToastr();

    const { setSelectedCell, postComment, isPostCommentLoading, allComments, setAllComments } =
        auditSheetContext;
    const HEADER_BG_COLOR = {
        OPEN: AppColors.secondary,
        'UPDATE': AppColors.secondary,
        REBUTTAL: AppColors.danger,
        RESOLVED: AppColors.darkGray,
        'REBUTTAL-RESOLVED': AppColors.darkGray

    }
    const BODY_BG_COLOR = {
        OPEN: 'white',
        'UPDATE': 'white',
        REBUTTAL: AppColors.lightDanger,
        RESOLVED: AppColors.loginFormBg,
        'REBUTTAL-RESOLVED': AppColors.loginFormBg

    }
    const [replyMessage, setReplyMessage] = useState('')
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const onEditClick = (currentComment) => {
        setSelectedComment(currentComment)
        if (comment.id !== currentComment.id) {
            setSelectedParentComment(comment)
        } else {
            setSelectedParentComment({})
        }
        onEditModalOpen()
        setIsComponentVisible(false)
    }

    const onDeleteClick = (currentComment) => {
        setSelectedComment(currentComment)
        if (comment.id !== currentComment.id) {
            setSelectedParentComment(comment)
        } else {
            setSelectedParentComment({})

        }
        onDeleteModalOpen()
        setIsComponentVisible(false)
    }

    const onReplyClick = async (currentComment) => {
        setSelectedComment(currentComment)
        const input = document.getElementById(`${index}-mention-input`);
        input.focus(); // adding the focus
        setIsComponentVisible(false)

    }

    const onResolveClick = async (currentComment) => {
        setSelectedComment(currentComment)
        const {
            audit_sheet_columns,
            audit_sheet_rows } = comment
        const updateBody = {
            audit_sheet_columns,
            audit_sheet_rows,
            chart: comment.chart,
            action: comment.action === 'REBUTTAL' ? 'REBUTTAL-RESOLVED' : 'RESOLVED',
            parent: comment.id,
        }
        setIsComponentVisible(false)
        const response = await postComment(updateBody)

        if (response.id) {
            const index = allComments.findIndex(c => c.id === comment.id)
            // allComments[index].action = response.action
            if (allComments[index].reply_comments) {
                allComments[index].reply_comments = [...allComments[index].reply_comments, response]
            } else {
                allComments[index].reply_comments = [response]
            }
            setAllComments(allComments)
        }
    }

    const onReopenClick = async (currentComment) => {
        setSelectedComment(currentComment)
        setIsComponentVisible(false)
        const {
            audit_sheet_columns,
            audit_sheet_rows } = comment
        const updateBody = {
            audit_sheet_columns,
            audit_sheet_rows,
            chart: comment.chart,
            action: 'RE-OPENED',
            parent: comment.id,
        }
        const response = await postComment(updateBody)
        if (response.id) {
            const index = allComments.findIndex(c => c.id === comment.id)
            // allComments[index].action = response.action
            if (allComments[index].reply_comments) {
                allComments[index].reply_comments = [...allComments[index].reply_comments, response]
            } else {
                allComments[index].reply_comments = [response]
            }
            setAllComments(allComments)
        }
    }

    const replyAction = async () => {
        //call api to reply
        const taggedUsers = getTaggedUser(replyMessage)
        const {
            audit_sheet_columns,
            audit_sheet_rows } = comment
        const updateBody = {
            audit_sheet_columns,
            audit_sheet_rows,
            chart: comment.chart,
            tagged_user: taggedUsers,
            comment: replyMessage,
            action: 'OPEN',
            parent: comment.id
        }

        const response = await postComment(updateBody)
        if (response.id) {
            setReplyMessage('')
            const toastProps = {
                header: 'Success!',
                description: 'Reply sent',
            }
            successToast(toast, toastProps)
            const index = allComments.findIndex(c => c.id === comment.id)
            if (allComments[index].reply_comments) {
                allComments[index].reply_comments = [...allComments[index].reply_comments, {
                    ...response
                }]
            }
            else {
                allComments[index].reply_comments = [{
                    ...response
                }]
            }
            setAllComments(allComments)
        }


    }

    const showResolveIcon = (commentData) => {
        // (commentData.isParent && commentData.reply_comments.length ? [] !== 'RESOLVED' && commentData.audit_sheet_columns.length)
        if (!commentData.audit_sheet_columns.length) {
            //for notes
            return false
        }
        if (commentData.isParent) {
            const replyLength = commentData.reply_comments.length
            if (replyLength >= 1 && RESOLVED_STATUS.includes(commentData.reply_comments[replyLength - 1].action)) {
                return false
            } else {
                return true
            }
        }
        return false
    }

    const bodyBgColor = () => {
        const replyLength = comment.reply_comments.length
        if (replyLength >= 1 && RESOLVED_STATUS.includes(comment.reply_comments[replyLength - 1].action)) {
            return AppColors.loginFormBg
        } else {
            return BODY_BG_COLOR[comment.action] ? BODY_BG_COLOR[comment.action] : 'white'
        }
    }

    const headerBgColor = () => {
        const replyLength = comment.reply_comments.length
        if (replyLength >= 1 && RESOLVED_STATUS.includes(comment.reply_comments[replyLength - 1].action)) {
            return AppColors.darkGray
        } else {
            return HEADER_BG_COLOR[comment.action] ? HEADER_BG_COLOR[comment.action] : AppColors.secondary
        }
    }
    const renderDotMenu = (comment) => {
        return <Box
            position={'relative'}
            p={3}
            borderBottomRadius={'xl'}
            // ref={ref}
            onClick={() => {
                setSelectedComment(comment)
            }}
        >
            <DotMenuIcon style={{ height: 15, width: 15, cursor: 'pointer' }} onClick={() => {
                setIsComponentVisible(true)
            }}
            />
            {isComponentVisible && selectedComment.key === `${comment.index}-${comment.i}-inline-comment` ? <CustomMenu
                onEditClick={() => onEditClick(comment)}
                onDeleteClick={() => onDeleteClick(comment)}
                onReplyClick={() => onReplyClick(comment)}
                onResolveClick={() => onResolveClick(comment)}
                onReopenClick={() => onReopenClick(comment)}
                comment={comment}
                ref={ref}
            /> : null}
        </Box>
    }

    const renderCommentHeader = (commentData) => {
        return <HStack justifyContent={'space-between'}>
            <HStack alignItems={'baseline'}>
                <Text color={AppColors.secondary} fontWeight={'bold'} fontSize='sm'>
                    {commentData.user.first_name} {commentData.user.last_name}
                </Text>
                {commentData.status === 'REBUTTAL' ? <Text color={AppColors.danger} fontWeight={'bold'} fontSize='sm'>
                    added a rebuttal
                </Text> : null}
                {commentData.status === 'REBUTTAL' ? <RebuttalIcon style={{ height: 15, width: 15 }} /> : null}
                <Text
                    fontSize='xs'
                    color={AppColors.darkGray}
                    fontWeight='normal'
                >
                    {formatDistanceStrict(new Date(commentData.commented_date), new Date(), {
                        addSuffix: true,
                    })}
                </Text>
            </HStack>
            {
                !commentData.justStatus ? <HStack>
                    {showResolveIcon(commentData) ? <Tooltip hasArrow
                        color='white'
                        label={'Mark as resolved'}
                        bg={AppColors.black}
                        fontFamily={FONT_FAMILY.ptSans}
                        fontSize='xs'
                    >
                        <Box cursor={'pointer'} onClick={() => onResolveClick(commentData)}
                        >
                            <YellowTickIcon />
                        </Box>
                    </Tooltip> : null}
                    {renderDotMenu(commentData)}
                </HStack> : null
            }
        </HStack >
    }

    const renderComment = (obj) => {
        const STATUS = {
            'RE-OPENED': 'Re-opened',
            'RESOLVED': 'Marked as resolved',
            'REBUTTAL-RESOLVED': 'Marked as resolved'
        }

        return <Box p={2} pt={0} bg={bodyBgColor()} key={obj.key}>
            {renderCommentHeader(obj)}
            {obj.justStatus ? <Text as='i' fontSize={'sm'} color={AppColors.darkGray}>
                {STATUS[obj.status]}
            </Text> : <RenderMessage comment={obj} key={`${obj.key}-render-message`}/>
            }
        </Box>
    }

    return <Box mb={5} key={`${index}-comment-box`}>
        <Box borderRadius={'lg'} shadow={'md'} >
            <HStack p={2} bg={headerBgColor()} justifyContent='space-between' cursor={'pointer'} onClick={() => {
                if (comment?.audit_sheet_rows && comment?.audit_sheet_columns) {
                    setSelectedCell({ row: comment.audit_sheet_rows[0], col: comment.audit_sheet_columns[0] })
                }
            }}  >
                <HStack>
                    {(!comment?.audit_sheet_rows.length && !comment?.audit_sheet_columns.length) ? <Text fontSize={'sm'} color={AppColors.white}>
                        Notes
                    </Text> : <Text fontSize={'sm'} color={AppColors.white}>
                        Sheet name
                    </Text>}

                </HStack>
                {hiddenBoxKey === `${index}-comment-box` ? <ChevronDownIcon cursor={'pointer'} color={'white'} onClick={() => {
                    setHiddenBoxKey('')
                }} /> : <ChevronUpIcon cursor={'pointer'} color={'white'} onClick={() => {
                    setHiddenBoxKey(`${index}-comment-box`)
                }} />}
            </HStack>
            {hiddenBoxKey !== `${index}-comment-box` ? <Box>
                {renderComment({
                    ...comment,
                    isParent: true,
                    justStatus: false,
                    status: comment.action,
                    key: `${index}-${-1}-inline-comment`,
                    index,
                    i: -1
                })}
                {comment.reply_comments?.length ? comment.reply_comments.map((reply, i) => {
                    return renderComment({
                        ...reply,
                        isParent: false,
                        justStatus: !reply.comment,
                        status: reply.action,
                        index,
                        i,
                        key: `${index}-${i}-inline-comment`,
                    })
                }) : null}
                {comment.audit_sheet_rows.length ? <Box p={2}>
                    <CommentInput message={replyMessage} setMessage={setReplyMessage} id={`${index}-mention-input`} />
                    {replyMessage ? <CommentSaveButton onSave={() => replyAction()} onCancel={() => setReplyMessage('')} isLoading={isPostCommentLoading} /> : null}
                </Box> : null}
            </Box> : null}
        </Box>

    </Box>
}
export default CommentBox