/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ButtonGroup,
  Circle,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { FormCQSelect, TextInput } from '@laxmimanogna/code-quick-components';
import { useContext, useEffect, useState } from 'react';
import AppColors from '../../../../constants/AppColors';
import { EditIcon, LinkIcon } from '../../../../constants/IconData';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import SelectFormModal from '../../forms/SelectFormModal';
import { AccountContext } from '../../../../providers/AccountsProvider';
import { useForm } from 'react-hook-form';
import FormMultiSelectModal from '../../../../components/form/FormMultiSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { sendMail } from '../../../../utils/send_mail.util';
import { EditAccountContact } from '../../components/EditAccountContactModal';
import { separateIdNames, separateSpecalityIdNames } from '../../../../utils';
import accountsRepository from '../../../../repositories/AccountsRepository';
import SpecialtySelect from '../../components/SpecialtySelect';
import { STATES } from '../../../../constants/constants';
import SearchDropDown from '../../components/SearchDropDown';

const HospitalProfileSection = ({ profileData, accountId }) => {
  const [editingKey, setEditingKey] = useState(null);

  const [hospitalData, setHospitalData] = useState({});
  const [updatedHospitalData, setUpdatedHospitalData] = useState({});
  const [dataValue, setDataValue] = useState({});

  const accountsContext = useContext(AccountContext);
  const schema = yup.object({
    insurance: yup.array(),
    ehr: yup.array(),
    specialty: yup.array(),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      insurance: [],
      ehr: [],
      specialty: [],
    },
  });
  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    if (Object.keys(profileData).length) {
      const ehr = profileData.ehr.length ? profileData.ehr.map(e => e.id) : [];
      const insurance = profileData.insurance.length
        ? profileData.insurance.map(e => e.id)
        : [];
      const specialty = profileData.specialty.length
        ? profileData.specialty.map(e => e.id)
        : [];

      setHospitalData({
        ...profileData,
        ehr,
        insurance,
        specialty,
        account_contact: profileData.account_contacts,
      });
      setUpdatedHospitalData({
        ...profileData,
        ehr,
        insurance,
        specialty,
        account_contact: profileData.account_contacts,
      });
      reset({
        ehr,
        insurance,
        specialty,
      });
    }
  }, [profileData]);

  useEffect(() => {
    if (dataValue && Object.keys(dataValue).length) {
      if (
        accountsContext.specialtyOptions.findIndex(
          d => d.id === dataValue.id && d.label === dataValue.label
        ) === -1
      ) {
        accountsContext.setSpecialtyOptions(prev => [...prev, dataValue]);
      }
    }
  }, [dataValue]);

  const update = async (key, formData) => {

    const hospitalBody = {
      name: hospitalData.name,
      account_contact: hospitalData.account_contact,
      ehr: separateIdNames(hospitalData.ehr, 'new_ehr_name', profileData.ehr),
      patients_per_month: '' + hospitalData.patients_per_month,
      insurance: separateIdNames(
        hospitalData.insurance,
        'new_ins_name',
        profileData.insurance
      ),
      specialties: separateSpecalityIdNames(
        hospitalData.specialty,
        'new_spec_name',
        profileData.specialty
      ),
      health_system: accountId,
      [key]: updatedHospitalData[key],
    };

    if (key === 'address') {
      const hospitalAddress = updatedHospitalData[key]
        .split(',')
        .at(-1)
        ?.trim();
      hospitalBody['address'] = hospitalAddress;
    }

    if (key === 'ehr') {
      hospitalBody[key] = separateIdNames(
        formData[key],
        'new_ehr_name',
        profileData.ehr
      );
    } else if (key === 'insurance') {
      hospitalBody[key] = separateIdNames(
        formData[key],
        'new_ins_name',
        profileData.insurance
      );
    } else if (key === 'specialty') {
      hospitalBody['specialties'] = separateSpecalityIdNames(
        formData[key],
        'new_spec_name',
        profileData.specialty
      );
    } else if (key === 'account_contact') {
      hospitalBody[key] = formData[key];
    }
    delete hospitalBody?.specialty;
    await accountsContext.updateHospital(hospitalData.id, hospitalBody);
  };
  //functions
  const onUpdateData = key => {
    update(key);
    setHospitalData(prev => ({ ...prev, [key]: updatedHospitalData[key] }));
    setEditingKey(null);
  };

  const onUndoUpdateData = key => {
    setUpdatedHospitalData(prev => ({ ...prev, [key]: hospitalData[key] }));
    setEditingKey(null);
  };

  const onMultiTextSubmit = (formData, key) => {
    update(key, formData);
    setHospitalData(prev => ({ ...prev, [key]: formData[key] }));
    setEditingKey(null);
  };

  const onEdit = key => {
    if (editingKey === key) {
      setEditingKey(null);
    } else {
      setEditingKey(key);
    }
  };

  //render functions
  const renderLeftData = (key, form) => {
    return (
      <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CheckIcon color={'black'} boxSize={3} />}
          onClick={() => {
            if (form) {
              handleSubmit(onMultiTextSubmit)(key);
              setEditingKey(null);
            } else {
              onUpdateData(key);
            }
          }}
        />
        <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CloseIcon boxSize={3} color={'black'} />}
          onClick={() => {
            onUndoUpdateData(key);
          }}
        />
      </ButtonGroup>
    );
  };

  const renderFormComponent = (key, inputType) => {
    switch (inputType) {
      case 'text':
        return (
          <TextInput
            mt={2}
            width={'xs'}
            name={key}
            id={{ key }}
            type={inputType}
            value={updatedHospitalData[key]}
            onChange={e => {
              setUpdatedHospitalData(prev => ({
                ...prev,
                [key]: e.target.value,
              }));
            }}
          />
        );

      case 'dropDown':
        return (
          <FormCQSelect
            control={control}
            options={STATES}
            value={STATES.find(
              state =>
                state.value ===
                updatedHospitalData['address'].split(',')[1].trim()
            )}
            onChange={e => {
              setUpdatedHospitalData(prev => ({
                ...prev,
                [key]: e.label,
              }));
            }}
            styles={{
              control: styles => ({
                ...styles,
                height: '50px',
                width: '20rem',
                borderRadius: '5px',
                border: `1px solid rgba(0,0,0,0.1)`,
                zIndex: 99,
              }),
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999999,
              }),
            }}
            id="state"
            name="state"
            placeholder="Select State"
            variant="primary"
            isSearchable={true}
          />
        );

      case 'number':
        return (
          <TextInput
            mt={2}
            width={'xs'}
            name={key}
            id={{ key }}
            type={inputType}
            value={updatedHospitalData[key]}
            onChange={e => {
              setUpdatedHospitalData(prev => ({
                ...prev,
                [key]: e.target.value,
              }));
            }}
          />
        );
    }
  };

  const renderTextData = (key, inputType) => {
    return editingKey === key ? (
      <HStack>
        {renderFormComponent(key, inputType)}
        {renderLeftData(key)}
      </HStack>
    ) : (
      <Text mt={2} color={AppColors.black} fontSize={'md'}>
        {hospitalData[key]}
      </Text>
    );
  };

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={accountsContext.isFetchingEHR}
        isCreating={accountsContext.isCreatingEHR}
        onCreateNewOption={accountsContext.createEHROptions}
      />
    );
  }

  function renderInsuranceForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Insurance'}
        addNewText={'Other Insurance Name'}
        subTitle={'Select Insurance'}
        isFetching={accountsContext.isFetchingInsurance}
        isCreating={accountsContext.isCreatingInsurance}
        onCreateNewOption={accountsContext.createInsuranceOption}
      />
    );
  }

  const promiseOptions = async inputValue => {
    return accountsRepository.getSpecialtyOptions({ search: inputValue });
  };
  const renderSpecialtySelect = () => {
    return (
      <SearchDropDown
        dataValue={dataValue}
        setDataValue={setDataValue}
        promiseOptions={promiseOptions}
      />
    );
  };

  function renderSpecialtyForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Specialties'}
        addNewText={'Other Specialty Name'}
        subTitle={'Select Specialty'}
        isFetching={accountsContext.isFetchingSpecialtyOption}
        isCreating={accountsContext.isCreatingSpecialtyOption}
        onCreateNewOption={accountsContext.createSpecialtyOption}
        renderSpecialtySelect={renderSpecialtySelect}
        dataValue={dataValue}
      />
    );
  }

  const renderMultiForm = key => {
    const title = {
      ehr: { option: 'ehrOptions', form: renderEHRFormModal },
      insurance: { option: 'insuranceOptions', form: renderInsuranceForModal },
      specialty: { option: 'specialtyOptions', form: renderSpecialtyForModal },
    };
    const options = accountsContext[title[key]['option']];
    return editingKey === key ? (
      <HStack>
        <Box width={'xs'}>
          <FormMultiSelectModal
            control={control}
            name={key}
            placeholder={'None'}
            options={options}
            renderFormModal={title[key]['form']}
          />
        </Box>
        {renderLeftData(key, true)}
      </HStack>
    ) : (
      <Text mt={2} color={AppColors.black} fontSize={'md'}>
        {hospitalData[key]?.length
          ? hospitalData[key].map((id, index) => {
              return `${options.find(d => d.value === id)?.label}${
                index < hospitalData[key].length - 1 ? ', ' : ''
              } `;
            })
          : 'N/A'}
      </Text>
    );
  };

  const renderContactForm = () => {
    return hospitalData['account_contact']?.length ? (
      hospitalData['account_contact'].map(account => {
        return (
          <HStack>
            {account.is_primary ? (
              <Circle borderColor={AppColors.gray} borderWidth={1} boxSize={5}>
                <CheckIcon color={AppColors.primary} boxSize={3} />
              </Circle>
            ) : (
              <Circle boxSize={5} />
            )}
            <Text color={AppColors.black}>
              {account.first_name} {account.last_name} |
            </Text>
            <Text color={AppColors.primary}>{account.email}</Text>
            <LinkIcon
              onClick={() => {
                sendMail(account.email);
              }}
              style={{ height: 15, width: 15, cursor: 'pointer' }}
            />
          </HStack>
        );
      })
    ) : (
      <Text>N/A</Text>
    );
  };

  return (
    <Box bg={'white'} borderRadius={'lg'} px={7} py={5}>
      <HStack justifyContent={'space-between'} mt={5}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Hospital Name
          </Text>
          {renderTextData('name', 'text')}
        </Box>
        {editingKey !== 'name' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('name')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            State
          </Text>
          {renderTextData('address', 'dropDown')}
        </Box>
        {editingKey !== 'address' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('address')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Patients per month
          </Text>
          {renderTextData('patients_per_month', 'number')}
        </Box>
        {editingKey !== 'patients_per_month' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('patients_per_month')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Insurance
          </Text>
          {renderMultiForm('insurance')}
        </Box>
        {editingKey !== 'insurance' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('insurance')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            EHR
          </Text>
          {renderMultiForm('ehr')}
        </Box>
        {editingKey !== 'ehr' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('ehr')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Specialties
          </Text>
          {renderMultiForm('specialty')}
        </Box>
        {editingKey !== 'specialty' ? (
          <Circle
            size="40px"
            bg={AppColors.gray}
            onClick={() => onEdit('specialty')}
            cursor={'pointer'}
          >
            <EditIcon />
          </Circle>
        ) : null}
      </HStack>
      <HStack justifyContent={'space-between'} alignItems="baseline" mt={7}>
        <Box>
          <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
            Account Contact
          </Text>
          {renderContactForm('account_contact')}
        </Box>
        <Circle
          size="40px"
          bg={AppColors.gray}
          onClick={() => onEdit('account_contact')}
          cursor={'pointer'}
        >
          <EditIcon />
        </Circle>
      </HStack>
      <EditAccountContact
        isOpen={editingKey === 'account_contact'}
        onClose={() => setEditingKey(null)}
        onContactChange={formData => {
          onMultiTextSubmit(formData, 'account_contact');
        //   update('account_contact', formData);
        }}
        accountData={hospitalData ? hospitalData['account_contact'] : []}
      />
    </Box>
  );
};
export default HospitalProfileSection;
