/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HStack, useBoolean } from '@chakra-ui/react';
import {
  CQReactTable,
  usePagination,
  useToastr,
} from '@laxmimanogna/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ACCOUNTS_ORDERING,
  AWAITING_ASSIGNMENT,
  getAuditStatus,
} from '../../../constants/constants';
import AccountsFileViewer from '../AccountsFileViewer';
import auditRepository from '../../../repositories/AuditRepository';
import {
  getCellProps,
  getHeaderCellProps,
  paginationProps,
  defaultReactTableProp,
} from '../../../utils/account.utils';
import ROUTES from '../../../constants/Routes';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper();
const AccountNewUpload = props => {
  const { queryParam, searchTerm } = props;
  const navigate = useNavigate();
  const toast = useToastr();
  const [isFetching, ifState] = useBoolean(false);

  const [uploads, setUploads] = useState([]);

  const [ordering, setOrdering] = useState('');
  const uploadPagination = usePagination(fetchUploads);

  useEffect(() => {
    uploadPagination.loadAPI();
  }, []);

  useEffect(() => {
    if (ACCOUNTS_ORDERING.includes(ordering)) {
      uploadPagination.loadAPI();
    }
  }, [ordering]);

  async function fetchUploads(paginationParams) {
    try {
      ifState.on();

      const filterParams = {};
      if (ordering) filterParams.ordering = ordering;
      if (searchTerm) {
        filterParams.search = searchTerm;
      }
      const response = await auditRepository.getNewUploads({
        ...paginationParams,
        ...filterParams,
        ...queryParam,
      });
      setUploads(response.results);
      return response;
    } catch (e) {
      toast.showError({
        description: e.toString(),
      });
    } finally {
      ifState.off();
    }
  }

  function getTableCellProps(row) {
    const isAwaitingAssignment = row.status === AWAITING_ASSIGNMENT;
    return {
      ...getCellProps(row),
      style: {
        ...getCellProps(row).style,
        fontWeight: isAwaitingAssignment
          ? 'bold!important'
          : 'normal!important',
      },
    };
  }

  const renderAction = (isAwaitingAssignment, pdfLink, id) => {
    return (
      <HStack justifyContent={'center'}>
        <Button
          isDisabled={isAwaitingAssignment}
          _disabled={{
            pointerEvents: 'none',
            bgColor: '#e6e6e6',
            color: '#6d747e',
          }}
          px={16}
          borderRadius={'full'}
          colorScheme={'secondary'}
          onClick={() => {
            navigate(ROUTES.VIEW_AUDIT_SHEET.replace(':uploadId', id));
          }}
        >
          View
        </Button>
        <AccountsFileViewer fileLink={pdfLink} />
      </HStack>
    );
  };
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('upload_id', {
        id: 'chart_id',
        header: () => 'Upload #',
      }),
      columnHelper.accessor('uploaded_date', {
        id: 'upload_date',
        header: () => 'Chart Upload',
        cell: info => {
          const uploadedDate = info.row.original.uploaded_date;
          if (!uploadedDate) {
            return 'N/A';
          }

          return format(new Date(uploadedDate), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor('audited_date', {
        header: () => 'Audit Upload',
        cell: info => {
          const auditedDate = info.row.original.audited_date;
          if (!auditedDate) {
            return 'N/A';
          }

          return format(new Date(auditedDate), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: info => {
          return getAuditStatus(info.row.original?.status);
        },
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          const isAwaitingAssignment =
            info.row.original?.status === AWAITING_ASSIGNMENT;
          return renderAction(
            isAwaitingAssignment,
            info.row.original?.file_obj,
            info.row.original?.id
          );
        },
      }),
    ];
  }, []);

  return (
    <CQReactTable
      isLoading={isFetching}
      {...defaultReactTableProp}
      {...paginationProps(uploadPagination)}
      getRowProps={() => {}}
      getHeaderCellProps={header => {
        return {
          ...getHeaderCellProps(header),
          justifyContent: 'center',
        };
      }}
      getCellProps={getTableCellProps}
      columns={columns}
      data={uploads}
      onPreviousPage={uploadPagination.loadPrevious}
      onNextPage={uploadPagination.loadNext}
      onAsc={header => {
        setOrdering(header);
      }}
      onDesc={header => {
        setOrdering(`-${header}`);
      }}
    />
  );
};

export default React.memo(AccountNewUpload);
