import { Box } from '@chakra-ui/react';
import React from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { Heading } from '@laxmimanogna/code-quick-components';
import UserManagementForm from './components/UserManagementForm';
import UserManagementProvider from '../../providers/UserManagementProvider';
import { withProvider } from '../../hoc/withProvider';
import { FONT_FAMILY } from '../../constants/Theme';
import { EDIT_MODE } from '../../constants/constants';
import { useParams } from 'react-router-dom';

const UserManagementContainer = props => {
  const { userId } = useParams();
  return (
    <>
      <CQContainer py={30} px={30} pb={20} mx="10%">
        <Heading
          variant="h4"
          style={{ fontFamily: FONT_FAMILY.baiJamurjee }}
          mb={4}
        >
          Edit User
        </Heading>
        <Box bg="white" p={4} w="100%" h="150%" borderRadius="md" ml={0}>
          <UserManagementForm mode={EDIT_MODE} userId={userId} />
        </Box>
      </CQContainer>
    </>
  );
};

export default withProvider(UserManagementProvider, UserManagementContainer);
