import {
  useBoolean,
  useToastr,
} from '@laxmimanogna/code-quick-components';
import { createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MANAGER } from '../constants/constants';
import { ACCESS_TOKEN } from '../constants/PreferenceKeys';
import ROUTES, { PUBLIC_PATH } from '../constants/Routes';
import authRepository from '../repositories/AuthRepository';

export const AUTH_FORMS = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
};

export const AuthContext = createContext({
  isAuthenticated: false,
  isDetermined: false,
  currentUser: null,

  userLookup: async () => { },
  onAuthSuccess: async () => { },
  updateCurrentUser: () => { },

  onLogout: () => { },
  onLogin: async ({ email, password }) => { },
  isLoggingIn: false,
  forms: {
    [AUTH_FORMS.LOGIN]: { error: null },
    [AUTH_FORMS.FORGOT_PASSWORD]: { error: null },
  },
  clearFormErrors: formType => { },
});

const AuthProvider = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, iaState] = useBoolean(false);
  const [isDetermined, idState] = useBoolean(false);
  const [isLoggingIn, iliState] = useBoolean(false);
  const [isSubmitting, isState] = useBoolean(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

  const [currentUser, setCurrentUser] = useState(null);
  const [forms, setForms] = useState({
    [AUTH_FORMS.LOGIN]: { error: null },
    [AUTH_FORMS.FORGOT_PASSWORD]: { error: null },
  });
  const toast = useToastr();

  function updateCurrentUser(nextUser) {
    if (!currentUser) {
      setCurrentUser(nextUser);
    } else {
      setCurrentUser({
        ...currentUser,
        ...nextUser,
      });
    }
    // nextUser.user_email
    //   ? setCurrentUser({ ...nextUser, email: nextUser.user_email })
    //   : setCurrentUser(nextUser);
  }

  function onAuthSuccess(nextUser) {
    iaState.on();
    updateCurrentUser(nextUser);

    if (
      PUBLIC_PATH.includes(location.pathname) ||
      ROUTES.ROOT_SCREEN === location.pathname
    ) {
      navigate(ROUTES.ASSIGNED_AUDITS);
    }
  }

  async function onLogout(nextUser) {
    iaState.off();
    setCurrentUser(null);
    await authRepository.logout();
    localStorage.removeItem(ACCESS_TOKEN);
    navigate(ROUTES.LOGIN_SCREEN);
  }

  async function userLookup(data) {
    try {
      idState.off();
      const response = await authRepository.userLookup();
      onAuthSuccess(response);
    } catch (e) {
      iaState.off();
      setCurrentUser(null);
      if (localStorage.getItem(ACCESS_TOKEN)) {
        // await authRepository.logout();
        localStorage.removeItem(ACCESS_TOKEN);
      }
      if (!PUBLIC_PATH.includes(location.pathname)) {
        navigate(ROUTES.LOGIN_SCREEN);
      }
    } finally {
      idState.on();
    }
  }

  function clearFormErrors(formType) {
    setForms({
      ...forms,
      [formType]: { error: null },
    });
  }

  async function onLogin({ email, password }) {
    try {
      iliState.on();
      clearFormErrors(AUTH_FORMS.LOGIN);
      const response = await authRepository.login({
        email,
        password,
        role: MANAGER,
      });

      localStorage.setItem(ACCESS_TOKEN, `Bearer ${response.access_token}`);

      await userLookup();
      iliState.off();
    } catch (e) {
      setForms({
        ...forms,
        [AUTH_FORMS.LOGIN]: { error: e.toString() },
      });
      iliState.off();
    }
  }

  async function onForgotPassword(email) {
    try {
      isState.on();
      await authRepository.forgotPassword({ email, role: MANAGER });
      setForgotPasswordEmail(email);
    } catch (e) {

      const response = e.cause;
      if (
        response &&
        response?.message
      ) {
        setForms({
          ...forms,
          [AUTH_FORMS.FORGOT_PASSWORD]: { error: response.message },
        });
      }
    } finally {
      isState.off();
      navigate(ROUTES.CONFIRMATION_SCREEN);
    }
  }

  async function onResetPassword({ password, token, email }) {
    try {
      isState.on();
      await authRepository.resetPassword({
        email,
        role: MANAGER,
        reset_password_token: token,
        password,
      });
      navigate(ROUTES.RESET_CONFIRMATION_SCREEN);
    } catch (e) {
      setForms({
        ...forms,
        [AUTH_FORMS.RESET_PASSWORD]: { error: e.toString() },
      });
      toast.showError({
        description: `${e.message}`,
      });
    } finally {
      isState.off();
    }
  }
  const mContext = {
    isAuthenticated,
    isDetermined,
    currentUser,

    userLookup,
    onAuthSuccess,

    onLogout,
    onLogin,
    isLoggingIn,
    isSubmitting,
    forgotPasswordEmail,
    onForgotPassword,
    onResetPassword,

    forms,
    clearFormErrors,
    updateCurrentUser,
  };

  return (
    <AuthContext.Provider value={mContext}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
