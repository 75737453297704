import { Box, Heading, Text } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CQContainer from '../../../components/layout/CQContainer';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import TeamProvider, { TeamContext } from '../../../providers/TeamProvider';
import EditTeamUser from './EditTeamUser';

function EditUserDetails() {
  const params = useParams();

  const teamContext = useContext(TeamContext);

  const getUser = async () => {
    await teamContext.getUser(params.userId);
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CQContainer w={'70%'} py={30} px={30} pb={20} mx="15%">
        <Heading
          variant={'h6'}
          fontSize={'x-large'}
          fontFamily={FONT_FAMILY.ptSans}
          fontWeight={'semibold'}
          mb={4}
        >
          Edit User Details
        </Heading>
        <Box
          bg="white"
          p={4}
          pb={20}
          w="100%"
          h="150%"
          borderRadius="md"
          ml={0}
        >
          {!teamContext.isGettingUser ? (
            <EditTeamUser teamsUser={teamContext.teamsUser} />
          ) : (
            <Text>loadinfg</Text>
          )}
        </Box>
      </CQContainer>
    </>
  );
}

export default withProvider(TeamProvider, EditUserDetails);
