import { Box, HStack, Text, useCheckbox } from '@chakra-ui/react';
import React from 'react';
import AppColors from '../../../constants/AppColors';
import { JustTickIcon } from '../../../constants/IconData';

/**
 *
 * @param {import('@chakra-ui/react').UseCheckboxProps} props
 * @returns
 */
const CustomCheckbox = props => {
  const { getInputProps, getCheckboxProps, state } = useCheckbox(props);

  const input = getInputProps({});
  const checkbox = getCheckboxProps();

  const { isChecked } = state;

  return (
    <Box as="label">
      <input {...input} />

      <HStack alignItems={'flex-start'}>
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth={isChecked ? 0 : 1}
          rounded="full"
          alignItems={'center'}
          minWidth={'22px'}
          maxWidth={'22px'}
          minHeight={'22px'}
          maxHeight={'22px'}
          _checked={{
            bg: AppColors.primary,
            color: 'white',
          }}
        >
          {isChecked ? (
            <JustTickIcon
              style={{
                position: 'relative',
                width: '12px',
                height: '12px',
                top: '5px',
                left: '5px',
              }}
            />
          ) : (
            <Box />
          )}
        </Box>
        <Text fontSize={'sm'}>{props.children}</Text>
      </HStack>
    </Box>
  );
};

export default React.memo(CustomCheckbox);
